<div class="club-socio-panel">
  <form>
    <textarea
      class="uk-width-1-1"
      autoresize
      rows="2"
      style="padding: 5px;"
      placeholder="¿Que estas pensando?"
      [(ngModel)]="postData.content"
      name="content"
    ></textarea>
    <div class="uk-clearfix">
      <div *ngIf="postData.image" class="postImage">
        <a class="uk-close" (click)="postData.image = undefined"></a>
        <img [src]="postData.image" style="width:80px;" />
      </div>

      <label
        class="uk-float-left uk-button club-button-blue uk-button-medium"
        [hidden]="postData.image"
      >
        <i class="uk-icon uk-icon-picture-o"></i>
        <input
          type="file"
          id="post-img"
          style="display:none;"
          (change)="uploadImage($event)"
        />
      </label>

      <button
        class="uk-float-right uk-button uk-button-medium"
        type="submit"
        (click)="makePost()"
        [disabled]="loadingPost || !postData.content"
      >
        <span *ngIf="loadingPost">Publicando...</span>
        <span *ngIf="!loadingPost">Publicar</span>
      </button>
    </div>
  </form>
</div>

<div id="crop-img" class="uk-modal">
  <div class="uk-modal-dialog">
    <a class="uk-modal-close uk-close"></a>
    <div id="upload-demo" style="width:100%; height: 305px;"></div>
    <div style="padding-top: 35px;" class="uk-clearfix">
      <hr />
      <button class="uk-button uk-float-right" (click)="selectImage()">
        Seleccionar
      </button>
    </div>
  </div>
</div>
