import { Member } from '../user/member';
import { Academy } from '../inscription/academy';
import { Group } from '../inscription/group';
import { Schedule } from '../inscription/schedule';
import { Period } from '@app/classes/inscription/period';
import { isNil } from 'ramda';

export class InscriptionRecord {
  public id: number;

  public period: Period = new Period();
  public member: Member;
  public academy: Academy;
  public academyId: number;
  public group: Group;
  public schedule: Schedule;
  public errors: any = [];
  public confirmed = false;
  private state = 3; // 1:success 0:error 3:pending
  private processed = false;

  public description = '';
  public sDescription = '';
  public name = '';
  public price = 0;

  public groupKey: string = null;

  constructor(attributes?: any) {
    if (!isNil(attributes)) {
      this.state = attributes.state != undefined ? attributes.state : 3;
      this.errors = attributes.errors || [];
      this.academyId = attributes.id_academy || null;
      this.id = attributes.id_form != undefined ? attributes.id_form : 0;
      this.academy = attributes.academy;
      this.group = attributes.group;
      this.schedule = attributes.schedule;
      this.period = attributes.period;
      this.member = attributes.member;
      this.confirmed = attributes.confirmed == '1' ? true : false;
      this.price = attributes.price !== undefined ? attributes.price : 0;

      if (
        this.academy != undefined ||
        this.schedule != undefined ||
        this.group != undefined
      ) {
        this.description =
          this.academy.name +
          ' ' +
          this.group.description +
          ' ' +
          this.schedule.day +
          ' ' +
          this.schedule.time;
        this.sDescription =
          this.period.abbreviation +
          ' ' +
          this.academy.name +
          ' ' +
          this.group.sAge +
          ' ' +
          this.schedule.sDay +
          ' ' +
          this.schedule.time;
      } else {
        this.description =
          attributes.description != undefined ? attributes.description : '';
        this.sDescription =
          attributes.s_description != undefined ? attributes.s_description : '';
        this.name = attributes.name != undefined ? attributes.name : '';
      }
    }
  }

  getMember() {
    return this.member;
  }

  getPrice() {
    return this.group != undefined ? this.group.price : this.price;
  }

  setGroupKey(userKey) {
    this.groupKey = userKey;
  }
}
