import { isNil } from 'ramda';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class DeepLinkService {
    constructor(_router) {
        this._router = _router;
    }
    deepLinking() {
        const urlParams = new URLSearchParams(window.location.search);
        const gotoParam = urlParams.get('goto');
        if (!isNil(gotoParam)) {
            sessionStorage.setItem('gotoDeepLink', gotoParam);
        }
    }
    deepLinkingGoto() {
        const goto = sessionStorage.getItem('gotoDeepLink');
        if (!isNil(goto)) {
            sessionStorage.removeItem('gotoDeepLink');
            this._router.navigate(['/' + goto]);
        }
    }
}
DeepLinkService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DeepLinkService_Factory() { return new DeepLinkService(i0.ɵɵinject(i1.Router)); }, token: DeepLinkService, providedIn: "root" });
