import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
})
export class PaymentMethodsComponent implements OnInit {
  constructor() {}

  @Input() onAcceptTerms: (boolean) => void;
  @Input() totalPayment = 0.0;

  selectedMethod: any = {};
  accept = false;

  methodList: any = [
    {
      id: 'visa',
      icon: 'assets/img/inter/icon/visa.png',
      message: 'Tarjetas asociadas a VISANET',
    },
  ];

  ngOnInit() {
    this.selectedMethod = this.methodList[0];
  }

  updateAccept() {
    if (this.onAcceptTerms) {
      this.onAcceptTerms(!this.accept);
    }
  }
}
