import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from '@app/services/auth/auth.service';
import { ModuleService } from '@app/services/system/module.service';
import { LoadingMainService } from '@app/services/ui/loading-main.service';
import { Module } from '@app/classes/module/module';
import { DeepLinkService } from '@app/modules/application/services/deep-link.service';
import { AnalyticsService } from '@app/data-services/analytics.service';
import { AppSettings } from '@app/helpers/app.settings';
import { MemberService } from '@app/data-services/member.service';
import { OnDestroyService } from '@modules/shared/services/on-destroy.service';
import { filter, takeUntil } from 'rxjs/operators';

declare var jQuery: any;
declare var UIkit: any;

@Component({
  selector: 'app-main',
  providers: [
    AuthService,
    ModuleService,
    LoadingMainService,
    Module,
    DeepLinkService,
  ],
  templateUrl: './main.component.html',
})
export class MainComponent implements OnInit {
  constructor(
    private _authService: AuthService,
    private _deepLinkService: DeepLinkService,
    private analyticsService: AnalyticsService,
    private memberService: MemberService,
    private destroy$: OnDestroyService
  ) {}

  isResponsiveMenuShown = false;
  user: AppUser;

  ngOnInit() {
    this.memberService.appUser$
      .pipe(takeUntil(this.destroy$.onDestroy$), filter(Boolean))
      .subscribe((userInfo: AppUser) => {
        this.user = userInfo;
        this.analyticsService.analytics.track('User', {
          user: this.user.username,
          version: AppSettings.APP_INFO.version,
        });
      });

    this.memberService.getUserInfo().subscribe();

    setTimeout(() => {
      this.addJQueryEvents();
    }, 500);
    this.headerHeight();
    window.addEventListener('resize', () => {
      this.headerHeight();
    });
    this._deepLinkService.deepLinkingGoto();
  }

  @HostListener('window:click', ['$event'])
  onWindowClick($event) {
    this.handleLeftNavigationOutsideClick($event);
  }

  addJQueryEvents() {
    jQuery('.club-header-dropdown .club-header-button').on(
      'click',
      function (event) {
        jQuery('.club-header-dropdown-content')
          .not(jQuery(this).siblings('.club-header-dropdown-content'))
          .removeClass('active');
        const content = jQuery(this).siblings('.club-header-dropdown-content');
        content.toggleClass('active');
        event.preventDefault();
      }
    );

    jQuery(document).on('click', (event) => {
      if (
        !jQuery(event.target).is('.club-header-dropdown') &&
        !(jQuery(event.target).parents('.club-header-dropdown').length > 0)
      ) {
        jQuery('.club-header-dropdown-content.active').removeClass('active');
      }
    });
  }

  toggleMenuVisibility() {
    this.isResponsiveMenuShown = !this.isResponsiveMenuShown;
    const bodyElement = jQuery('body');
    this.isResponsiveMenuShown
      ? bodyElement.addClass('active-nav')
      : bodyElement.removeClass('active-nav');
  }

  handleLeftNavigationOutsideClick($event: any) {
    if (this.isResponsiveMenuShown) {
      const navigation = document.getElementById('intersocio-left-navigation');
      const navigationButton = document.getElementById(
        'intersocio-toggle-navigation-button'
      );
      const isNavigationOutsideClick =
        !navigation.contains($event.target) &&
        !navigationButton.contains($event.target);
      if (isNavigationOutsideClick) {
        this.isResponsiveMenuShown = false;
        $event.stopPropagation();
        $event.preventDefault();
      }
    }
  }

  closeSession() {
    this._authService.closeSession();
  }

  headerHeight() {
    return jQuery('#application-header').height();
  }
  get contentWrapperStyles() {
    return {
      paddingTop: `${this.headerHeight()}px`,
    };
  }
  get contentViewStyles() {
    return {
      height: `calc(100vh - ${this.headerHeight()}px)`,
    };
  }
}
