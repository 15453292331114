export class AppHelper {

  // ToDo move this a file
  static readonly translateBase: any = {
    already_registered:
      'Ya existe una inscripción con los datos ingresados. ( Verifique el historial )',
    not_allowed_in_group:
      'No puede inscribirse en el grupo, este es requiere verificación',
  };
  static serializeObj(obj) {
    const result = [];

    for (const property in obj) {
      result.push(
        encodeURIComponent(property) + '=' + encodeURIComponent(obj[property])
      );
    }

    return result.join('&');
  }

  static serializeObjApi(obj) {
    const result = [];

    for (const property in obj) {
      result.push(
        encodeURIComponent(property) + '/' + encodeURIComponent(obj[property])
      );
    }
    return result.join('/');
  }

  static parseErrorResponse(errors, options: any = {}) {
    if (options.json) { errors = JSON.parse(errors); }
    options.listClass = options.listClass || '';
    options.itemClass = options.itemClass || '';

    let errorHtml = '<ul class="' + options.listClass + '">';
    for (const property in errors) {
      if (errors.hasOwnProperty(property)) {
        for (let i = 0; i < errors[property].length; i++) {
          errorHtml +=
            '<li class="' +
            options.itemClass +
            '">&#x25cf;&nbsp;&nbsp; ' +
            AppHelper.parseTranslate(errors[property][i]) +
            '</li>';
        }
      }
    }
    errorHtml += '</ul>';
    return errorHtml;
  }

  static parseTranslate(key) {
    return AppHelper.translateBase[key] ? AppHelper.translateBase[key] : key;
  }

  static isValidEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
