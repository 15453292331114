import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: [ './spinner.component.scss' ]
})
export class SpinnerComponent implements OnInit {
  constructor() {}

  @Input() message = '';
  @Input() margin = '4';
  @Input() contrast = false;

  ngOnInit() {}
}
