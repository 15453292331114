import { Injectable } from '@angular/core';
import {Service} from '@app/services/Service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RecurringPaymentService extends Service {
  constructor(private _http: HttpClient) {
    super();
  }

  getUserRecurringPaymentInfo() {
    return this._http.get(`${ this.API_ENDPOINT_API_INTERSOCIO }recurrent_payment_request_form`,  this.APP_REQUEST_OPTIONS_AUTH);
  }

  saveUserRecurringPaymentInfo(data) {
    return this._http.post(`${ this.API_ENDPOINT_API_INTERSOCIO }recurrent_payment_request_form`, data,  this.APP_REQUEST_OPTIONS_AUTH);
  }

  updateUserRecurringPaymentInfo(data) {
    return this._http.put(`${ this.API_ENDPOINT_API_INTERSOCIO }recurrent_payment_request_form`, data,  this.APP_REQUEST_OPTIONS_AUTH);
  }

  deleteUserRecurringPaymentInfo() {
    return this._http.delete(`${ this.API_ENDPOINT_API_INTERSOCIO }recurrent_payment_request_form`, this.APP_REQUEST_OPTIONS_AUTH);
  }
}
