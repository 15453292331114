import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-flat-card',
  template: `<ng-content></ng-content>`
})
export class FlatCardComponent {
  @Input() class: string = '';
  constructor() {}

  @HostBinding('class')
  get classes() {
    return (
      'tw-block tw-bg-white tw-shadow-sm tw-border-solid tw-border tw-border-gray-200 tw-border-opacity-50 tw-rounded-md tw-p-4 md:tw-p-7 ' +
      this.class
    );
  }
}
