<app-flat-card>
  <div class="tw-flex tw-flex-wrap">
    <div class="tw-w-full lg:tw-w-9/12 tw-pr-1 tw-mb-4 lg:tw-mb-0">
      <div class="tw-w-full tw-mb-4 tw-flex tw-items-center">
        <app-recurring-payment-status-indicator
          [userRecurringPayment]="userRecurringPayments"
          class="tw-block tw-mr-4"
        ></app-recurring-payment-status-indicator>
        <p class="tw-text-sm tw-text-gray-700">{{ userRecurringPayments.localState.message }}</p>
      </div>
      <div>
        <div class="tw-my-6 tw-flex tw-flex-wrap tw--mx-2 md:tw-w-2/3">
          <p class="tw-m-0 tw-mb-2 tw-px-2 tw-w-full md:tw-w-1/2">
            <label class="tw-font-semibold">Fecha de ingreso</label><br>
            {{ userRecurringPayments.createdAt | amFromUtc | amLocale: 'es' | amDateFormat:'LL' }}
            <span class="tw-text-sm">({{ userRecurringPayments.createdAt | amFromUtc | amLocale: 'es' | amTimeAgo }})</span>
          </p>
          <p class="tw-m-0 tw-mb-2 tw-px-2 tw-w-full md:tw-w-1/2">
            <label class="tw-font-semibold">Fecha de modificación</label><br>
            {{ userRecurringPayments.updatedAt | amFromUtc | amLocale: 'es' | amDateFormat:'LL' }}
            <span class="tw-text-sm">({{ userRecurringPayments.updatedAt | amFromUtc | amLocale: 'es' | amTimeAgo }})</span>
          </p>
          <p class="tw-m-0 tw-mb-2 tw-px-2 tw-w-full md:tw-w-1/2"><label class="tw-font-semibold">Fecha de aprox. de débito</label><br> 05 de cada mes</p>
          <p class="tw-m-0 tw-mb-2 tw-px-2 tw-w-full md:tw-w-1/2"><label class="tw-font-semibold">Tipo de tarjeta</label><br> {{ userCardBrand.name }}</p>
          <p class="tw-m-0 tw-mb-2 tw-px-2 tw-w-full md:tw-w-1/2"><label class="tw-font-semibold">Número de tarjeta</label><br> {{ userRecurringPayments.maskedCardNumber }}</p>
          <p class="tw-m-0 tw-mb-2 tw-px-2 tw-w-full md:tw-w-1/2"><label class="tw-font-semibold">Correo de notificación</label><br> {{ userRecurringPayments.email }}</p>
        </div>
        <div *ngIf="userRecurringPayments.isAffiliated">
          <button
            class="uk-button uk-button-primary !tw-mr-2 tw-inline-block"
            routerLink="/recurring-payments/affiliation">Editar Datos</button>
          <button
            class="uk-button uk-button-danger tw-inline-block"
            routerLink="/recurring-payments/disaffiliation">Desafiliar</button>
        </div>
      </div>
    </div>
    <div class="tw-w-full lg:tw-w-3/12 tw-text-right tw-flex tw-items-center">
      <img src="assets/modules/recurring-payments/images/calendar-1.svg">
    </div>
  </div>
</app-flat-card>
