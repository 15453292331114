import { Component, OnInit } from '@angular/core';
import {AppSettings} from '@app/helpers/app.settings';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss']
})
export class LoginLayoutComponent implements OnInit {
  appInfo = AppSettings.APP_INFO;
  constructor() { }
  ngOnInit() {
  }

}
