<!-- loading -->
<div class="uk-text-center mt-4 mb-4" *ngIf="loadingVisa">
  <div>
    <span class="ro-spinner"></span>
    <p class="m-1">Preparando la plataforma de pagos ...</p>
  </div>
</div>

<div class="uk-text-center mt-3" [hidden]="loadingVisa">
  <p>
    Recuerde verificar sus datos al momento de pagar, la cuenta saldra a nombre
    de la sesión. Ahora puedes pagar con cualquier tarjeta.
  </p>
  <div class="tw-h-24 tw-flex tw-items-center tw-justify-center">
    <form id="visa-form" method="post"></form>
  </div>
</div>
