/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./module-wrapper.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../message/message-module-disabled/message-module-disabled.component.ngfactory";
import * as i3 from "../message/message-module-disabled/message-module-disabled.component";
import * as i4 from "@angular/common";
import * as i5 from "./module-wrapper.component";
import * as i6 from "@angular/router";
var styles_ModuleWrapperComponent = [i0.styles];
var RenderType_ModuleWrapperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModuleWrapperComponent, data: {} });
export { RenderType_ModuleWrapperComponent as RenderType_ModuleWrapperComponent };
function View_ModuleWrapperComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "club-socio-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-message-module-disabled", [], null, null, null, i2.View_MessageModuleDisabledComponent_0, i2.RenderType_MessageModuleDisabledComponent)), i1.ɵdid(2, 114688, null, 0, i3.MessageModuleDisabledComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ModuleWrapperComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_ModuleWrapperComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleWrapperComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleWrapperComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.available; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.available; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ModuleWrapperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-module-wrapper", [], null, null, null, View_ModuleWrapperComponent_0, RenderType_ModuleWrapperComponent)), i1.ɵdid(1, 114688, null, 0, i5.ModuleWrapperComponent, [i6.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModuleWrapperComponentNgFactory = i1.ɵccf("app-module-wrapper", i5.ModuleWrapperComponent, View_ModuleWrapperComponent_Host_0, {}, {}, ["*"]);
export { ModuleWrapperComponentNgFactory as ModuleWrapperComponentNgFactory };
