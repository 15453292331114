import { Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { SessionGuard } from './guards/session.guard';
import { AppAvailabilityGuard } from '@app/guards/app-availability.guard';
import { AppNotAvailableComponent } from '@app/modules/application/components/app-not-available/app-not-available.component';
import { AppNotAvailableGuard } from '@app/guards/app-not-available.guard';
import { LoginGuard } from '@app/guards/login.guard';
import { ApplicationRoutes } from '@app/modules/application/application-routing.module';
const ɵ0 = () => import("./modules/auth-recover/auth-recover.module.ngfactory").then(m => m.AuthRecoverModuleNgFactory), ɵ1 = () => import("./modules/verification/verification.module.ngfactory").then(m => m.VerificationModuleNgFactory);
const routes = [
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [LoginGuard],
    },
    {
        path: 'app-not-available',
        component: AppNotAvailableComponent,
        canActivate: [AppNotAvailableGuard],
    },
    {
        path: 'recover',
        loadChildren: ɵ0,
        canActivate: [LoginGuard],
    },
    {
        path: 'verification/email',
        loadChildren: ɵ1,
        canActivate: [LoginGuard],
    },
    {
        path: '',
        children: ApplicationRoutes,
        canActivate: [AppAvailabilityGuard, SessionGuard],
        canActivateChild: [SessionGuard],
    },
    { path: '**', redirectTo: '' },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1 };
