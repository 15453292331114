import { Injectable } from '@angular/core';
import { Service } from '../services/Service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

const resolveUserInfo = (raw: any): AppUser => ({
  ...raw,
  backgroundImg: raw.background_img,
  createdAt: raw.created_at,
  updatedAt: raw.updated_at,
  memberNumber: raw.member_number,
  visaUserToken_id: raw.visa_user_token_id,
  emailConfirmed: raw.email_confirmed,
  profileImg: raw.profile_img,
  clubMember: raw.club_member,
});

@Injectable({
  providedIn: 'root',
})
export class MemberService extends Service {
  private _appUser$: BehaviorSubject<AppUser | null> =
    new BehaviorSubject<AppUser>(null);

  public appUser$: Observable<AppUser> = this._appUser$.asObservable();

  constructor(private _http: HttpClient) {
    super();
  }

  getMembershipInfo() {
    return this._http.get(
      this.API_ENDPOINT_API_INTERSOCIO + 'membership/index',
      this.APP_REQUEST_OPTIONS_AUTH
    );
  }

  getUserInfo() {
    return this._http
      .get(
        this.API_ENDPOINT_API_INTERSOCIO + 'user',
        this.APP_REQUEST_OPTIONS_AUTH
      )
      .pipe(
        tap((appUser) => {
          this._appUser$.next(resolveUserInfo(appUser));
        })
      );
  }

  updateUserInfo(data) {
    return this._http.put(
      this.API_ENDPOINT_API_INTERSOCIO + 'users',
      { user: data },
      this.APP_REQUEST_OPTIONS_AUTH
    );
  }

  updateUserPassword(data) {
    return this._http.put(
      this.API_ENDPOINT_API_INTERSOCIO + 'user/password_verified',
      { user: data },
      this.APP_REQUEST_OPTIONS_AUTH
    );
  }
}
