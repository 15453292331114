import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitlePageComponent } from '@app/modules/shared/components/title-page/title-page.component';
import { MessageComponent } from '@app/modules/shared/components/message/message.component';
import { MessageModuleDisabledComponent } from '@app/modules/shared/components/message/message-module-disabled/message-module-disabled.component';
import { SpinnerComponent } from '@app/modules/shared/components/spinner/spinner.component';
import { RecommendationComponent } from '@app/modules/shared/components/recommendation/recommendation.component';
import { RatingComponent } from '@app/modules/shared/components/rating/rating.component';
import { OwlCarouselComponent } from '@app/modules/shared/components/owl-carousel/owl-carousel/owl-carousel.component';
import { RecommendationWidgetComponent } from '@app/modules/shared/components/recommendation/recommendation-widget/recommendation-widget.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { RoPaginationComponent } from './components/pagination/ro-pagination/ro-pagination.component';
import { LoadingBtnComponent } from '@app/modules/shared/components/loading/loading-btn/loading-btn.component';

import { MomentModule } from 'angular2-moment';

import { LocalDatePipe } from '@app/modules/shared/pipes/local-date.pipe';
import { MemberRelationshipPipe } from '@app/modules/shared/pipes/member-relationship.pipe';
import { MemberStatePipe } from '@app/modules/shared/pipes/member-state.pipe';
import { MemberTypePipe } from '@app/modules/shared/pipes/member-type.pipe';
import { DebtTypePipe } from '@app/modules/shared/pipes/debt-type.pipe';
import { CurrencyPipe } from '@app/modules/shared/pipes/currency.pipe';
import { WayPayPipe } from '@app/modules/shared/pipes/way-pay.pipe';
import { ExcerptPipe } from '@app/modules/shared/pipes/excerpt.pipe';
import { Url2aPipe } from '@app/modules/shared/pipes/url2a.pipe';
import { Nl2brPipe } from '@app/modules/shared/pipes/nl2br.pipe';
import { MoneyPipe } from '@app/modules/shared/pipes/money.pipe';


import { ModuleWrapperComponent } from './components/module-wrapper/module-wrapper.component';

import {RouterModule} from '@angular/router';
import { FormControlErrorsComponent } from '@app/modules/shared/components/ui-pack/form-control-errors/form-control-errors.component';
import { UiKitModalComponent } from '@app/modules/shared/components/ui-pack/ui-kit-modal/ui-kit-modal.component';
import { FlatCardComponent } from '@app/modules/shared/components/ui-pack/flat-card/flat-card.component';
import { LeftNavigationComponent } from '@app/modules/shared/components/ui-pack/left-navigation/left-navigation.component';
import {
  ModuleLayoutNavigationComponent
} from '@app/modules/shared/components/ui-pack/layout/module-layout-navigation/module-layout-navigation.component';

import { PrintDirective } from '@app/modules/shared/directives/print.directive';
import { NgxSimplePrintModule } from 'ngx-simple-print';
import { NgxCurrencyModule } from 'ngx-currency';

import {
  LowercaseDirective,
  NumbersOnlyDirective,
  UppercaseDirective,
} from '@app/modules/shared/directives/input.directive';
import { UpdateAppVersionComponent } from './components/update-app-version/update-app-version.component';

import { TootipDirective } from '@app/directives/tooltip.directive';
import { AutoresizeDirective } from '@app//directives/autoresize.directive';
import { QrCodeReaderComponent } from './components/qr-code-reader/qr-code-reader.component';
import {NzTagModule} from 'ng-zorro-antd';

const components = [
  FormControlErrorsComponent,
  UiKitModalComponent,
  FlatCardComponent,
  LeftNavigationComponent,
  ModuleLayoutNavigationComponent,
];

const pipes = [
  Nl2brPipe,
  Url2aPipe,
  WayPayPipe,
  CurrencyPipe,
  DebtTypePipe,
  MemberTypePipe,
  MemberStatePipe,
  MemberRelationshipPipe,
  LocalDatePipe,
  ExcerptPipe,
  MoneyPipe,
];

const directives = [
  PrintDirective,
  UppercaseDirective,
  LowercaseDirective,
  NumbersOnlyDirective,
  TootipDirective,
  AutoresizeDirective,
];

@NgModule({
  declarations: [
    TitlePageComponent,
    MessageComponent,
    MessageModuleDisabledComponent,
    SpinnerComponent,
    RecommendationComponent,
    RecommendationWidgetComponent,
    RatingComponent,
    OwlCarouselComponent,
    RoPaginationComponent,
    LoadingBtnComponent,
    pipes,
    directives,
    components,
    ModuleWrapperComponent,
    UpdateAppVersionComponent,
    QrCodeReaderComponent,
  ],
    imports: [
        CommonModule,
        NgxPaginationModule,
        MomentModule,
        NgxSimplePrintModule,
        NgxCurrencyModule,
        RouterModule,
        NzTagModule,
    ],
    exports: [
        TitlePageComponent,
        MessageComponent,
        MessageModuleDisabledComponent,
        SpinnerComponent,
        RecommendationComponent,
        RecommendationWidgetComponent,
        RatingComponent,
        OwlCarouselComponent,
        NgxPaginationModule,
        RoPaginationComponent,
        LoadingBtnComponent,
        MomentModule,
        pipes,
        directives,
        ModuleWrapperComponent,
        NgxSimplePrintModule,
        UpdateAppVersionComponent,
        NgxCurrencyModule,
        components,
        QrCodeReaderComponent,
    ],
})
export class SharedModule {}
