<div
  class="club-profile-image-gallery club-profile-image-gallery-overlay"
  style="margin-bottom: 10px;"
>
  <div class="image-wrapper">
    <div class="image-wrapper-img">
      <img [src]="post.post_info.image" />
    </div>
  </div>
  <div class="overlay-data" (click)="clickImage()">
    <div class="overlay-data-footer uk-clearfix">
      <span class="uk-float-left">{{
        post.created_at | amLocale: 'es' | amTimeAgo
      }}</span>
      <span class="uk-float-right">
        <i class="uk-icon uk-icon-heart"></i>
        {{ post.likes_count }}
      </span>
    </div>
  </div>
</div>
