import { Component, OnInit, Input } from '@angular/core';
import { PostService } from '@app/services/social/post.service';
import { AuthService } from '@app/services/auth/auth.service';
import { User } from '@app/classes/user/user';
import { Session } from '@app/classes/user/session';

declare var jQuery: any;
declare var UIkit: any;

@Component({
  selector: 'app-post-form',
  providers: [PostService],
  styles: [
    `
      .postImage {
        position: relative;
        display: inline-block;
      }
      .postImage .uk-close {
        position: absolute;
        top: 0;
        top: 0;
        right: 0;
        background: #000;
        color: #fff;
      }
    `,
  ],
  templateUrl: './post-form.component.html',
})
export class PostFormComponent implements OnInit {
  constructor(
    private _postService: PostService,
    private _authService: AuthService
  ) {}

  @Input() onSuccess: Function = undefined;

  sessionUser: User;

  postData: any = {};

  $uploadCrop: any = {};
  modal: any = {};

  loadingPost = false;

  ngOnInit() {
    this.sessionUser = Session.getInstance().getUser();
    this.modal = UIkit.modal('#crop-img');

    const __this = this;
    this.modal.on({
      'show.uk.modal'() {
        console.log('Modal is visible.');
      },
      'hide.uk.modal'() {
        jQuery('#post-img').val('');
        __this.$uploadCrop.croppie('destroy');
        __this.$uploadCrop = jQuery('#upload-demo').croppie({
          viewport: { width: 300, height: 300, type: 'square' },
          enableExif: true,
          zoom: 0,
        });
      },
    });

    this.$uploadCrop = jQuery('#upload-demo').croppie({
      viewport: { width: 300, height: 300, type: 'square' },
      enableExif: true,
      zoom: 0,
    });
  }

  selectImage() {
    const __this = this;
    this.$uploadCrop
      .croppie('result', {
        type: 'base64',
        size: { width: 700, height: 700 },
      })
      .then(function(resp) {
        __this.postData.image = resp;
        __this.modal.hide();
      });
  }

  uploadImage = input => {
    const __this = this;
    this.modal.show();
    if (input.srcElement.files && input.srcElement.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        __this.$uploadCrop
          .croppie('bind', {
            url: e.target.result,
            zoom: 0,
          })
          .then(function() {});
      };
      reader.readAsDataURL(input.srcElement.files[0]);
    } else {
      console.error('No soporta el readfile api');
    }
  }

  makePost = () => {
    if (
      this.postData == undefined ||
      this.postData == '' ||
      this.postData.content == undefined ||
      jQuery.trim(this.postData.content) == ''
    ) {
      return;
    }

    this.postData.user = this.sessionUser.user;
    this.postData.id = this.sessionUser.id;
    this.postData.key = this.sessionUser.key;

    this.loadingPost = true;
    this._postService.savePost(this.postData).then(
      (response: any) => {
        if (response.success) {
          if (this.onSuccess != undefined) { this.onSuccess(response.data); }
          this.postData = {};
        } else { console.error('No saved'); }

        this.loadingPost = false;
      },
      error => {
        console.error(error);
        console.error('No se pudo realizar la publicación.');
        this.loadingPost = false;
      }
    );
  }
}
