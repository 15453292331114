import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import * as moment from 'moment';
import { capitalizeFirst } from 'ramda-extension';
import RecurringPaymentConstants from '@app/modules/recurring-payments/recurring-payment-constants';
import {CreditCardValidators} from 'angular-cc-library';
import {RecurringPaymentsStoreService} from '@app/modules/recurring-payments/data/recurring-payments-store.service';
import {UserRecurringPayment} from '@app/modules/recurring-payments/classes/user-recurring-payment';
import {Session} from '@app/classes/user/session';
import {RecurringPaymentService} from '@app/modules/recurring-payments/services/recurring-payment.service';
import {RoNotificationService} from '@app/modules/shared/services/ro-notification.service';
import {Router} from '@angular/router';
import {LoadingMainService} from '@app/services/ui/loading-main.service';
import { all, test, length, pipe, lte, __, and, toString, prop, dissoc, propOr, ifElse, always, isEmpty, reject, isNil } from 'ramda';
import {TokenService} from '@app/modules/recurring-payments/services/token.service';
import { Crypt } from 'hybrid-crypto-js';
import { pki, util } from 'node-forge';
import {AppSettings} from '@app/helpers/app.settings';

declare var jQuery: any;
declare var JSEncrypt: any;
declare var UIkit: any;

const linkedAssociateNumberValidator = (): ValidatorFn => {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const isValidNumber = test(/^[0-9]*$/);
    const hasValidLength = pipe(length, lte(__, 5));
    const allValuesAreValid = all(value => and(isValidNumber(value), hasValidLength(value)));
    if (allValuesAreValid(control.value)) {
      return null;
    }
    return {linkedAssociateNumbers: true};
  };
}

const MAX_DEBIT_AMOUNT = 500;
const DEFAULT_DEBIT_AMOUNT = 200;

@Component({
  selector: 'app-recurring-payment-affiliation-form',
  templateUrl: './recurring-payment-affiliation-form.component.html',
  styleUrls: ['./recurring-payment-affiliation-form.component.scss']
})
export class RecurringPaymentAffiliationFormComponent implements OnInit {

  currentDate: string;
  currentDateFriendly: string;
  form: FormGroup;
  cardTypes = RecurringPaymentConstants.paymentTypes;
  submitted = false;
  currentUserRecurringPayment: UserRecurringPayment;
  userEmail = '';
  pKeyCard: any = '';
  editCardNumberActive = false;
  inputCurrencyOptions = {
    prefix: 'S/ ',
    thousands: '.',
    decimal: ',',
    align: 'left',
    allowNegative: false,
    min: 0,
    max: MAX_DEBIT_AMOUNT
  };

  constructor(
    private _fb: FormBuilder,
    private _recurringPaymentStore: RecurringPaymentsStoreService,
    private _recurringPaymentService: RecurringPaymentService,
    private _roNotificationService: RoNotificationService,
    private _routerService: Router,
    private _loadingMainService: LoadingMainService,
    private _tokenService: TokenService
  ) { }

  ngOnInit() {
    const { userAffiliation } = this._recurringPaymentStore.recurrentPaymentState;
    this.currentUserRecurringPayment = userAffiliation;

    this.getPublicKey();

    this.userEmail = Session.getInstance().getUser().email;
    const getValue = (defaultValue, field) =>
      ifElse(prop('isAffiliated'), propOr(defaultValue, field), always(defaultValue))(this.currentUserRecurringPayment);

    this.form = this._fb.group({
      notify_by_email: [getValue(false, 'notifyByEmail'), Validators.required],
      email: [getValue(null, 'email') || this.userEmail, [Validators.required, Validators.email]],
      linked_associate_numbers: [getValue([], 'linkedAssociateNumbers'), [Validators.required, linkedAssociateNumberValidator()]],
      ahead_fee: [getValue(false, 'aheadFee'), Validators.required],
      card_brand: [getValue(null, 'cardBrand'), Validators.required],
      max_debit_amount: [getValue(DEFAULT_DEBIT_AMOUNT, 'maxDebitAmount'), Validators.required],
      acceptTerms: [false, Validators.required],
      enc_card_number: [getValue('', 'cardNumber'), this.isUpdateAffiliation ? [] : [CreditCardValidators.validateCCNumber]],
      expiration_date: [getValue('', 'expirationDate'), [CreditCardValidators.validateExpDate]],
      card_holder: [getValue('', 'cardHolder'), [Validators.required, Validators.maxLength(50)]],
      dni_card_holder: [
        getValue('', 'dniCardHolder'),
        [Validators.required, Validators.maxLength(15), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]
      ],
    });
    this.currentDate = moment().format('YYYY-MM-DD hh:mm:ss');
    this.currentDateFriendly = capitalizeFirst(moment().locale('es').format('LLLL').toString());
  }

  get isUpdateAffiliation() {
    return this.currentUserRecurringPayment.isAffiliated;
  }

  async getPublicKey() {
    const { public_key: publicKey }: any = await this._tokenService.generateKey().toPromise();
    this.pKeyCard = publicKey;
  }

  encryptValue(value) {
    // const publicKey = pki.publicKeyFromPem(this.pKeyCard);
    // const simple_rsa = util.encode64(publicKey.encrypt(value));
    const crypt = new Crypt();
    return JSON.parse(crypt.encrypt(this.pKeyCard, value));
  }

  validateForm() {
    this.submitted = true;
    if (this.form.invalid) {
      jQuery('.club-socio-view').animate({
        scrollTop: 0
      }, 500);
      return;
    }
    this.currentUserRecurringPayment.isAffiliated ? this.updateAffiliation(this.form.value) : this.saveAffiliation(this.form.value);
  }

  saveAffiliation(dataAffiliation) {
    const newAffiliation = this.processAffiliationForm(dataAffiliation);
    this._loadingMainService.show();
    this._recurringPaymentService.saveUserRecurringPaymentInfo({ recurrent_payment: newAffiliation })
      .subscribe(
        response => this.handleSuccess(response),
        error => this.handleError()
      );
  }

  updateAffiliation(dataAffiliation) {
    const newAffiliation = this.processAffiliationForm(dataAffiliation);
    this._loadingMainService.show();
    this._recurringPaymentService.updateUserRecurringPaymentInfo({ recurrent_payment: newAffiliation })
      .subscribe(
        response => this.handleSuccess(response),
        error => this.handleError()
      );
  }

  processAffiliationForm(dataForm) {
    const affiliationData = {
      ...dissoc('acceptTerms', dataForm),
      linked_associate_numbers: pipe(prop('linked_associate_numbers'), toString)(dataForm),
      enc_card_number: pipe(prop('enc_card_number'), ifElse(isEmpty, always(null), val => this.encryptValue(val)))(dataForm),
      update_card_number: this.isUpdateAffiliation
    };
    return reject(isNil, affiliationData);
  }

  handleError() {
    this._loadingMainService.hide();
    this._roNotificationService.danger('Hubo un error procesando el formulario.');
  }

  handleSuccess(response) {
    this._loadingMainService.hide();
    this._recurringPaymentStore.setCurrentUserRecurringPayment(new UserRecurringPayment(response));
    this._roNotificationService.success('Solicitud enviada satisfactoriamente.');
    this._routerService.navigate(['/recurring-payments']);
  }

  changeUpdateCard() {
    this.editCardNumberActive = !this.editCardNumberActive;
    if (this.editCardNumberActive) {
      this.form.get('enc_card_number').setValidators([CreditCardValidators.validateCCNumber]);
    } else {
      this.form.get('enc_card_number').setValidators([]);
    }
    this.form.get('enc_card_number').updateValueAndValidity({emitEvent: false});
  }

}
