<div class="tw-shadow-md tw-border-solid tw-border tw-border-gray-200 tw-rounded fadeIn animated tw-overflow-hidden">
  <div id="printed-section">
    <div class="tw-text-center tw-py-4 tw-bg-green-500 tw-rounded-t">
      <i class="uk-icon uk-icon-check tw-text-6xl !tw-text-white"></i>
      <h3 class="!tw-text-white tw-mt-0"> Transacción Exitosa </h3>
    </div>
    <hr class="!tw-m-0">
    <div class="tw-flex tw-flex-wrap tw--mx-2 tw-p-6">
      <div class="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-2">
        <label class="tw-font-bold tw-block">Número de transacción</label>
        {{ dataResponse.transactionId }}
      </div>
      <div class="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-2">
        <label class="tw-font-bold tw-block">Número de Tarjeta</label>
        {{ dataResponse.cardPan }}
      </div>
      <div class="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-2">
        <label class="tw-font-bold tw-block">Tipo de Pago</label>
        <span class="tw-uppercase">{{ dataResponse.cardBrand }}</span>
      </div>
      <div class="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-2">
        <label class="tw-font-bold tw-block">Ticket de Pago</label>
        {{ dataResponse.clubTicker }}
      </div>
      <div class="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-2">
        <label class="tw-font-bold tw-block">Monto Pagado</label>
        S/ {{ dataResponse.visaTransactionResponse.amount }}
      </div>
      <div class="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-2">
        <label class="tw-font-bold tw-block">Usuario</label>
        {{ dataResponse.user }}
      </div>
      <div class="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-2">
        <label class="tw-font-bold tw-block">Fecha de transacción</label>
        {{ dataResponse.transactionDateFormatted }}
      </div>
      <app-payment-terms-and-conditions class="tw-w-full"></app-payment-terms-and-conditions>
    </div>
  </div>
</div>
