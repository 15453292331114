<div *ngIf="!success">
  <h2 class="uk-text-bold uk-text-center">¿Cómo te sientes?</h2>
  <p class="uk-text-center">
    Nosotros realmente apreciamos tu opinión, asi que ¿con cuantas estrellas
    calificas tu experencia con InterSocio?
  </p>
  <div class="uk-text-center mt-1">
    <app-rating
      [max]="5"
      [size]="'large'"
      [selectable]="true"
      [onSelectedStar]="getRatingValue"
    ></app-rating>
  </div>

  <p class="uk-text-center">
    Si tuvieramos que mejorar algo, ¿Qué sería? <br /><small>(Opcional)</small>
  </p>
  <textarea
    class="uk-width-1-1"
    rows="2"
    [(ngModel)]="comment"
    name="comment"
  ></textarea>

  <div class="uk-text-center mt-1">
    <button class="uk-button" (click)="sendRating()" [disabled]="laodingRating">
      <span *ngIf="!laodingRating">Enviar mi opinión</span>
      <span *ngIf="laodingRating">Enviando ...</span>
    </button>
  </div>
</div>

<div *ngIf="success" class="fadeIn animated">
  <app-message
    [message]="'¡Gracias por enviar tu opinión!'"
    [icon]="'smile'"
  ></app-message>
</div>
