/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inscription-information.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../node_modules/ng-zorro-antd/alert/ng-zorro-antd-alert.ngfactory";
import * as i3 from "ng-zorro-antd/alert";
import * as i4 from "ng-zorro-antd/core";
import * as i5 from "./inscription-information.component";
var styles_InscriptionInformationComponent = [i0.styles];
var RenderType_InscriptionInformationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InscriptionInformationComponent, data: {} });
export { RenderType_InscriptionInformationComponent as RenderType_InscriptionInformationComponent };
export function View_InscriptionInformationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Al usar el sistema de inscripciones web usted esta de acuerdo con los "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["href", "http://www.clubinter.org.pe/tyc/content/pre-inscripcion-academias"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["T\u00E9rminos Y Condiciones "])), (_l()(), i1.ɵted(-1, null, [" . Se le recomienda leer dicha informaci\u00F3n. "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Recuerde NO HAY CAMBIOS NI DEVOLUCIONES NI RECUPERACI\u00D3N DE CLASES."])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "nz-alert", [["nzDescription", "Recuerda que la\n      academia debe ser pagada antes de las 11:00 pm del mismo d\u00EDa o el cupo\n      ser\u00E1 liberado."], ["nzShowIcon", ""], ["nzType", "warning"]], null, null, null, i2.View_NzAlertComponent_0, i2.RenderType_NzAlertComponent)), i1.ɵdid(8, 573440, null, 0, i3.NzAlertComponent, [i4.NzConfigService], { nzDescription: [0, "nzDescription"], nzType: [1, "nzType"], nzShowIcon: [2, "nzShowIcon"] }, null)], function (_ck, _v) { var currVal_0 = "Recuerda que la\n      academia debe ser pagada antes de las 11:00 pm del mismo d\u00EDa o el cupo\n      ser\u00E1 liberado."; var currVal_1 = "warning"; var currVal_2 = ""; _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_InscriptionInformationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-inscription-information", [], null, null, null, View_InscriptionInformationComponent_0, RenderType_InscriptionInformationComponent)), i1.ɵdid(1, 114688, null, 0, i5.InscriptionInformationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InscriptionInformationComponentNgFactory = i1.ɵccf("app-inscription-information", i5.InscriptionInformationComponent, View_InscriptionInformationComponent_Host_0, {}, {}, []);
export { InscriptionInformationComponentNgFactory as InscriptionInformationComponentNgFactory };
