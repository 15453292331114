import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
})
export class FeedbackComponent implements OnInit {
  constructor() {}

  ratingValue: number;
  comment: string;
  success = false;
  laodingRating = false;

  ngOnInit() {}

  getRatingValue = value => {
    this.ratingValue = value;
  }

  sendRating() {
    console.log(this.ratingValue);
    console.log(this.comment);
  }
}
