import { HttpHeaders } from '@angular/common/http';
import { Session } from '../classes/user/session';
import { environment } from '@env/environment';

export class AppSettings {
  systemSession: Session;

  constructor() {}

  public static get API_ENDPOINT(): string {
    return environment.baseUrlCodeIgniter;
  }
  public static get API_ENDPOINT_API_INTERSOCIO(): string {
    return environment.baseUrlRails;
  }
  public static get API_ENDPOINT_API_INTER(): string {
    return environment.baseUrlApiInter;
  }

  public static get API_ENDPOINT_VISA(): any {
    return {
      redirectionUrl: `${environment.baseUrlRails}payments/redirect`,
      timeoutUrl: `${environment.baseUrlApp}timeout-error`,
      confirmUrl: `${environment.baseUrlApp}payment/3961223659c486190`,
    };
  }

  public static get APP_SESSION_NAME(): string {
    return 'intersocio_session';
  }
  public static get APP_INFO(): any {
    return {
      name: 'InterSocio',
      version: environment.version,
      buildTimeStamp: environment.buildTimeStamp,
      copyright: 'Club Internacional Arequipa © 2021',
    };
  }

  public static get APP_NOTIFY_DANGER(): any {
    return {
      pos: 'bottom-left',
      status: 'danger',
    };
  }

  public static get APP_NOTIFY_SUCCESS(): any {
    return {
      pos: 'bottom-left',
      status: 'success',
    };
  }

  public static get APP_ALERT_TEMPLATE_ACADEMY_SUCCESS(): any {
    return `<div class="uk-text-center">
                <i class="uk-icon uk-icon-check-circle green" style="font-size: 4rem;"></i>
                <h4 style="margin: 10px 0 0;"><b>Todo Salió Bien!</b></h4><span>Hemos guardo los datos con éxito.</span>
            </div>`;
  }

  public static get APP_REQUEST_OPTIONS(): any {
    const headers: HttpHeaders = new HttpHeaders({
      'ROCOTO-API-KEY': '1U3hPKS0vd03TDnHBmF92f0a5uZw7aqs',
    });
    return {
      headers,
    };
  }
}
