import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-comment-btn',
  templateUrl: './comment-btn.component.html',
})
export class CommentBtnComponent implements OnInit {
  constructor() {}

  @Input() commentsCount: number;

  ngOnInit() {}
}
