import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SocialRoutingModule } from './social-routing.module';
import { FacebookCardComponent } from './components/facebook-card/facebook-card.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { ProfileSearchPageComponent } from '@app/modules/social/components/profile/profile-search-page/profile-search-page.component';
import { ProfileSearchWidgetComponent } from '@app/modules/social/components/profile/profile-search-widget/profile-search-widget.component';
import { ProfileUpdateImageComponent } from '@app/modules/social/components/profile/profile-update-image/profile-update-image.component';
import { ProfileGalleryImageComponent } from '@app/modules/social/components/profile/profile-gallery-image/profile-gallery-image.component';
import { FollowBtnComponent } from '@app/modules/social/components/follow/follow-btn/follow-btn.component';
import { PostListComponent } from '@app/modules/social/components/post/post-list/post-list.component';
import { ProfilePageWidgetComponent } from '@app/modules/social/components/profile/profile-page-widget/profile-page-widget.component';
import { SocialButtonComponent } from '@app/modules/social/components/widget/social-button/social-button.component';
import { ProfilePageComponent } from '@app/modules/social/components/profile/profile-page/profile-page.component';
import { LikePreviewComponent } from '@app/modules/social/components/post/like-preview/like-preview.component';
import { ProfilePopularComponent } from '@app/modules/social/components/profile/profile-popular/profile-popular.component';
import { ProfileWidgetComponent } from '@app/modules/social/components/profile/profile-widget/profile-widget.component';
import { CommentFormComponent } from '@app/modules/social/components/post/comment-form/comment-form.component';
import { CommentComponent } from '@app/modules/social/components/post/comment/comment.component';
import { CommentBtnComponent } from '@app/modules/social/components/post/comment-btn/comment-btn.component';
import { LikeBtnComponent } from '@app/modules/social/components/post/like-btn/like-btn.component';
import { PostFormComponent } from '@app/modules/social/components/post/post-form/post-form.component';
import { PostComponent } from '@app/modules/social/components/post/post.component';
import { FeedsComponent } from '@app/modules/social/components/feeds/feeds.component';
import { RecentActivityComponent } from '@app/modules/social/components/recent-activity/recent-activity.component';
import { CommentListComponent } from '@app//modules/social/components/post/comment-list/comment-list.component';
import { UserSystemComponent } from '@app//modules/social/components/system/user-system/user-system.component';
import {SharedModule} from '@app/modules/shared/shared.module';
import {FormsModule} from '@angular/forms';
import { SocialBannersComponent } from './components/social-banners/social-banners.component';
import {NzAvatarModule} from 'ng-zorro-antd';

@NgModule({
  declarations: [
    FacebookCardComponent,
    RecentActivityComponent,
    FeedsComponent,
    PostComponent,
    PostFormComponent,
    LikeBtnComponent,
    CommentBtnComponent,
    CommentComponent,
    CommentFormComponent,
    ProfileWidgetComponent,
    ProfilePopularComponent,
    LikePreviewComponent,
    ProfilePageComponent,
    SocialButtonComponent,
    ProfilePageWidgetComponent,
    PostListComponent,
    FollowBtnComponent,
    ProfileGalleryImageComponent,
    ProfileUpdateImageComponent,
    ProfileSearchWidgetComponent,
    ProfileSearchPageComponent,
    UserSystemComponent,
    CommentListComponent,
    SocialBannersComponent,
  ],
    exports: [
        FacebookCardComponent,
        ProfileWidgetComponent,
        ProfilePopularComponent,
        UserSystemComponent,
        FeedsComponent,
        SocialButtonComponent,
        SocialBannersComponent
    ],
    imports: [
        CommonModule,
        SocialRoutingModule,
        InfiniteScrollModule,
        SharedModule,
        FormsModule,
        NzAvatarModule,
    ]
})
export class SocialModule { }
