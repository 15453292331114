import { Service } from '@app/services/Service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class RecurringPaymentService extends Service {
    constructor(_http) {
        super();
        this._http = _http;
    }
    getUserRecurringPaymentInfo() {
        return this._http.get(`${this.API_ENDPOINT_API_INTERSOCIO}recurrent_payment_request_form`, this.APP_REQUEST_OPTIONS_AUTH);
    }
    saveUserRecurringPaymentInfo(data) {
        return this._http.post(`${this.API_ENDPOINT_API_INTERSOCIO}recurrent_payment_request_form`, data, this.APP_REQUEST_OPTIONS_AUTH);
    }
    updateUserRecurringPaymentInfo(data) {
        return this._http.put(`${this.API_ENDPOINT_API_INTERSOCIO}recurrent_payment_request_form`, data, this.APP_REQUEST_OPTIONS_AUTH);
    }
    deleteUserRecurringPaymentInfo() {
        return this._http.delete(`${this.API_ENDPOINT_API_INTERSOCIO}recurrent_payment_request_form`, this.APP_REQUEST_OPTIONS_AUTH);
    }
}
RecurringPaymentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RecurringPaymentService_Factory() { return new RecurringPaymentService(i0.ɵɵinject(i1.HttpClient)); }, token: RecurringPaymentService, providedIn: "root" });
