<div class="club-header-dropdown">
  <a class="club-header-button tw-text-white">
    <nz-badge [nzCount]="paymentList.length">
      <i
        nz-icon
        nzType="shopping-cart"
        nzTheme="outline"
        class="tw-text-white tw-text-2xl"
        style="width: 30px"
      ></i>
    </nz-badge>
  </a>

  <div class="club-header-dropdown-content" [ngStyle]="{ width: '400px' }">
    <ng-container *ngIf="paymentList.length">
      <div class="tw-flex tw-gap-2 tw-justify-between">
        <div class="tw-font-bold">Items</div>
        <div class="tw-font-bold">Costo</div>
      </div>
      <hr />
      <div
        *ngFor="let payment of paymentList"
        class="tw-flex tw-gap-2 tw-justify-between"
      >
        <div class="tw-uppercase tw-truncate">{{ payment.description }}</div>
        <div>{{ payment.amount | money }}</div>
      </div>
      <div class="tw-flex tw-gap-2 tw-justify-between" *ngIf="commission">
        <div class="tw-uppercase tw-truncate">
          {{ commission.debt.description }}
        </div>
        <div>{{ commission.debt.amount | money }}</div>
      </div>
      <hr />
      <div class="tw-flex tw-gap-2 tw-justify-between">
        <div>Total</div>
        <div>{{ totalAmount | money }}</div>
      </div>
      <hr />
      <button
        nz-button
        nzType="primary"
        routerLink="/payment"
        class="tw-w-full"
      >
        ¡PAGAR AHORA!
      </button>
    </ng-container>
    <ng-container *ngIf="!paymentList.length">
      <nz-empty></nz-empty>
    </ng-container>
  </div>
</div>
