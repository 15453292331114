import { Routes } from '@angular/router';
import { DashboardComponent } from '@app/modules/dashboard/components/dashboard/dashboard.component';
const ɵ0 = {
    title: 'dashboard',
    code: '2bbb3b3fcc50ad33bfb39f2c068fbbae',
};
const routes = [
    {
        path: '',
        component: DashboardComponent,
        data: ɵ0,
    }
];
export class DashboardRoutingModule {
}
export { ɵ0 };
