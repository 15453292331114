<app-flat-card>
  <h3 class="uk-text-bold">Historial de Inscripciones</h3>

  <div class="uk-text-center mt-4 mb-4" *ngIf="loadingInscriptionHistory">
    <span class="ro-spinner"></span>
    <p class="m-1">Recuperando información...</p>
  </div>

  <div
    class="fadeIn animated"
    *ngIf="userInscriptionList.length > 0 && !loadingInscriptionHistory"
  >
    <div class="uk-overflow-container">
      <p>Esta son tus inscripciones para este periodo.</p>
      <table
        class="uk-table uk-table-hover uk-table-striped uk-table-condensed club-socio-table"
      >
        <thead>
          <tr>
            <th></th>
            <th>N° de Pre-inscripción</th>
            <th>Periodo</th>
            <th>Usuario</th>
            <th>Fecha y Hora</th>
            <th>Vence</th>
            <th>Resumen</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let inscription of userInscriptionList">
            <td class="uk-text-center" ng-switch on="item.confirmed">
              <a
                title="Eliminar Inscripción"
                class="red club-icon-button"
                *ngIf="inscription.paidState() == 2"
                (click)="cancelInscription(inscription.id)"
              >
                <i class="uk-icon uk-icon-trash"></i>
              </a>
            </td>
            <td>{{ inscription.id }}</td>
            <td>{{ period.shortName }}</td>
            <td>{{ inscription.username }}</td>
            <td>
              {{
                inscription.createdAt
                  | amLocale: 'es'
                  | amDateFormat: 'DD/MM/YYYY, h:mm:ss a'
              }}<small
                >&nbsp;&nbsp; (
                {{ inscription.createdAt | amLocale: 'es' | amTimeAgo }}
                )</small
              >
            </td>
            <td>¡Vence Hoy! antes de las 11 pm</td>
            <td>
              <a (click)="showDetails(inscription)">Ver Detalle</a>
            </td>
            <td>
              <span class="green" *ngIf="inscription.paidState() == 1"
                >Pagado</span
              >
              <span class="red" *ngIf="inscription.paidState() == 2"
                >Sin pagar</span
              >
              <span class="red" *ngIf="inscription.paidState() == 3"
                >Existen Pendientes de Pago</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div
    class="fadeIn animated"
    *ngIf="userInscriptionList.length < 1 && !loadingInscriptionHistory"
  >
    <app-message
      [message]="
        'Usted no ha realizado ninguna pre-inscripción para este periodo'
      "
      [subMessage]="'¿Por qué no realizas una ahora?'"
    ></app-message>
  </div>
</app-flat-card>

<div id="inscription-detail-modal" class="uk-modal">
  <div class="uk-modal-dialog uk-modal-dialog-large">
    <a class="uk-modal-close uk-close"></a>

    <div>
      <div
        class="uk-overflow-container"
        *ngIf="selectedInscription.getCountRecords() > 0 && selectedInscription"
      >
        <div class="uk-clearfix">
          <button class="uk-button f-left" print printId="printThisElement">
            <i class="uk-icon uk-icon-print"></i> Imprimir
          </button>
        </div>
        <br />

        <div id="printThisElement">
          <table class="uk-table uk-table-condensed club-socio-table">
            <tr>
              <th rowspan="2" colspan="2"></th>
              <th colspan="3">
                Club Internacional Arequipa - InterSocio <br />
                Ficha de Pre-inscripción para Academias - Club Internacional
                Arequipa
              </th>
              <th rowspan="2" style="width:140px;">
                N° Inscripción<br />
                <span>{{ selectedInscription.id }}</span>
              </th>
            </tr>
            <tr>
              <th colspan="3">
                Estado:
                <span class="green" *ngIf="selectedInscription.paidState() == 1"
                  >Pagado</span
                >
                <span class="red" *ngIf="selectedInscription.paidState() == 2"
                  >Sin pagar</span
                >
                <span class="red" *ngIf="selectedInscription.paidState() == 3"
                  >Existen Pendientes de Pago</span
                >
              </th>
            </tr>
            <tr>
              <td colspan="2">
                <b>Socio</b><br />
                {{ selectedInscription.number }}
              </td>
              <td>
                <b>Usuario</b><br />
                {{ selectedInscription.username }}
              </td>
              <td colspan="2">
                <b>Fecha y Hora de Registro</b><br />
                {{
                  selectedInscription.createdAt
                    | amLocale: 'es'
                    | amDateFormat: 'DD/MM/YYYY, h:mm:ss a'
                }}
              </td>
              <td>
                <b>Periodo</b><br />
                {{ period.shortName }}
              </td>
            </tr>
            <tr>
              <td>N°</td>
              <td></td>
              <td>Pre-inscrito</td>
              <td>Academia</td>
              <td></td>
              <td>Precio</td>
            </tr>
            <tr
              *ngFor="
                let inscriptionRecord of selectedInscription.records;
                let idx = index
              "
            >
              <td class="uk-text-center">
                {{ idx + 1 }}
              </td>
              <td class="uk-text-center">
                <a
                  title="Eliminar Inscripción"
                  *ngIf="!inscriptionRecord.confirmed"
                  class="red club-icon-button"
                  (click)="cancelInscriptionRecord(inscriptionRecord.id)"
                >
                  <i class="uk-icon uk-icon-trash"></i>
                </a>
              </td>
              <td>
                {{ inscriptionRecord.name }}
              </td>
              <td>
                {{ inscriptionRecord.description }}
                <app-inscription-note [inscription]="inscriptionRecord"></app-inscription-note>
              </td>
              <td class="uk-text-center">
                <span *ngIf="inscriptionRecord.confirmed">
                  <i class="uk-icon uk-icon-check-circle green"></i
                ></span>
                <span *ngIf="!inscriptionRecord.confirmed">
                  <i class="uk-icon uk-icon-times-circle red"></i
                ></span>
              </td>
              <td class="uk-text-right">
                S/. {{ inscriptionRecord.price | number: '1.2-2' }}
              </td>
            </tr>
            <tr>
              <td colspan="5">
                * NO SE ACEPTAN CAMBIOS NI DEVOLUCIONES NI RECUPERACIÓN DE
                CLASES y se hará efectiva una vez realizado el pago respectivo -
                Esta ficha es válida sólo para los asociados del Club
                Internacional Arequipa
              </td>
              <td>
                <b class="uk-text-left">Total</b><br />
                <div class="uk-text-large uk-text-right">
                  S./ {{ selectedInscription.getTotal() | number: '1.2-2' }}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div
        class="fadeIn animated"
        *ngIf="selectedInscription.getCountRecords() < 1"
      >
        <app-message
          [message]="
            'En este momento no pudimos conseguir información para mostrar'
          "
          [subMessage]="'Intente nuevamenete más tarde.'"
        ></app-message>
      </div>
    </div>
  </div>
</div>
