/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./owl-carousel.component";
var styles_OwlCarouselComponent = [];
var RenderType_OwlCarouselComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OwlCarouselComponent, data: {} });
export { RenderType_OwlCarouselComponent as RenderType_OwlCarouselComponent };
export function View_OwlCarouselComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_OwlCarouselComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "owl-carousel", [], [[8, "className", 0]], null, null, View_OwlCarouselComponent_0, RenderType_OwlCarouselComponent)), i0.ɵdid(1, 4964352, null, 0, i1.OwlCarouselComponent, [i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).defaultClass; _ck(_v, 0, 0, currVal_0); }); }
var OwlCarouselComponentNgFactory = i0.ɵccf("owl-carousel", i1.OwlCarouselComponent, View_OwlCarouselComponent_Host_0, { options: "options", themeClass: "themeClass" }, {}, ["*"]);
export { OwlCarouselComponentNgFactory as OwlCarouselComponentNgFactory };
