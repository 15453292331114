import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot, CanActivateChild, UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Session } from '@app/classes/user/session';

// This guard verify session in routes

@Injectable()
export class SessionGuard implements CanActivate, CanActivateChild {
  sessionSystem: Session;

  constructor(private _router: Router) {
    this.sessionSystem = Session.getInstance();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.sessionSystem.isLogged() === false) {
      this._router.navigate(['/login']);
      return false;
    }
    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean|UrlTree {
    if (this.sessionSystem.isLogged() === false) {
      this._router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
