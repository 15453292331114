import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Directive({
  selector: '[print]',
  host: {
    '(click)': 'onclickPrint($event)',
  },
})
export class PrintDirective {
  contentToPrint: string = '';
  printSection: any;
  sectionToPrintId: string = 'printSection';

  constructor(private el: ElementRef) {}

  @Input() printId;

  ngOnInit() {
    this.contentToPrint = this.printId;

    if (this.printId == 'creadential')
      this.sectionToPrintId = 'printSectionCredential';

    this.printSection = document.getElementById(this.sectionToPrintId);
    // if there is no printing section, create one
    if (!this.printSection) {
      this.printSection = document.createElement('div');
      this.printSection.id = this.sectionToPrintId;
      document.body.appendChild(this.printSection);
    }

    var instance = this;

    window.onafterprint = function() {
      // clean the print section before adding new content
      instance.printSection.innerHTML = '';
    };
  }

  onclickPrint(event: Event) {
    var elemToPrint = document.getElementById(this.contentToPrint);
    if (elemToPrint) {
      this.printSection.innerHTML = '';
      this.printElement(elemToPrint);
    }
  }

  printElement(elem) {
    // clones the element you want to print
    var domClone = elem.cloneNode(true);
    this.printSection.appendChild(domClone);
    window.print();
  }
}
