import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '@env/environment';
import {Module} from '@app/classes/module/module';
import {ModuleService} from '@app/services/system/module.service';

@Injectable({
  providedIn: 'root'
})
export class AppNotAvailableGuard implements CanActivate {
  constructor(
    private _moduleClass: Module,
    private _moduleService: ModuleService,
    private _router: Router,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._moduleService.getModuleStatus('')
      .pipe(
        map( moduleAvailability => {
          if ( !environment.production ) {
            this._router.navigate(['/']);
            return false;
          }
          if (this._moduleClass.moduleIsAvailable(moduleAvailability)) {
            this._router.navigate(['/']);
            return false;
          } else {
            return true;
          }
        }),
        catchError( () => {
          return of(true);
        })
      );
  }
}
