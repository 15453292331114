import { Component, OnInit, Input } from '@angular/core';
import { TransactionService } from '@app/services/payment/transaction.service';
import { Session } from '@app/classes/user/session';
import { VisaService } from '@app/services/payment/visa.service';
import { equals, pipe, path, __ } from 'ramda';
import { PaymentResponse } from '@app/modules/payment/classes/payment-response';
import { PaymentService } from '@app/data-services/payment.service';

const SUCCESS_CODE_ACTION = '000';
const isSuccessResponse = (visaResponse, fieldPath) =>
  pipe(path(fieldPath), equals(__, SUCCESS_CODE_ACTION))(visaResponse);

@Component({
  selector: 'app-payment-visa-response',
  providers: [TransactionService, VisaService],
  templateUrl: './payment-visa-response.component.html',
})
export class PaymentVisaResponseComponent implements OnInit {
  constructor(
    private _visaService: VisaService,
    private _transactionService: TransactionService,
    private paymentService: PaymentService
  ) {}

  @Input() paymentResponse: PaymentResponse;
  systemSession: Session;

  ngOnInit() {
    this.systemSession = Session.getInstance();
    this.paymentService.removeAllItems();
  }

  get isSuccessResponse() {
    return isSuccessResponse(this.paymentResponse, [
      'visaTransactionResponse',
      'actionCode',
    ]);
  }
}
