<button
  class="uk-button club-follow-button"
  [ngClass]="{ following: ownFollow }"
  *ngIf="!ownProfile"
  (click)="makeFollow()"
>
  <span *ngIf="!ownFollow"
    ><i class="uk-icon uk-icon-plus"></i>&nbsp;&nbsp; Seguir</span
  >
  <span *ngIf="ownFollow" class="msg"
    ><i class="uk-icon uk-icon-check"></i>&nbsp;&nbsp; Siguiendo</span
  >
  <span class="unfollow"
    ><i class="uk-icon uk-icon-close"></i>&nbsp;&nbsp; Dejar de Seguir</span
  >
</button>
