/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./recurring-payments-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/module-wrapper/module-wrapper.component.ngfactory";
import * as i3 from "../../../shared/components/module-wrapper/module-wrapper.component";
import * as i4 from "@angular/router";
import * as i5 from "../../components/recurring-payment-menu/recurring-payment-menu.component.ngfactory";
import * as i6 from "../../components/recurring-payment-menu/recurring-payment-menu.component";
import * as i7 from "./recurring-payments-page.component";
var styles_RecurringPaymentsPageComponent = [i0.styles];
var RenderType_RecurringPaymentsPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RecurringPaymentsPageComponent, data: {} });
export { RenderType_RecurringPaymentsPageComponent as RenderType_RecurringPaymentsPageComponent };
export function View_RecurringPaymentsPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-module-wrapper", [], null, null, null, i2.View_ModuleWrapperComponent_0, i2.RenderType_ModuleWrapperComponent)), i1.ɵdid(1, 114688, null, 0, i3.ModuleWrapperComponent, [i4.ActivatedRoute], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "div", [["class", "club-socio-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-recurring-payment-menu", [], null, null, null, i5.View_RecurringPaymentMenuComponent_0, i5.RenderType_RecurringPaymentMenuComponent)), i1.ɵdid(4, 114688, null, 0, i6.RecurringPaymentMenuComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); }, null); }
export function View_RecurringPaymentsPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-recurring-payments-page", [], null, null, null, View_RecurringPaymentsPageComponent_0, RenderType_RecurringPaymentsPageComponent)), i1.ɵdid(1, 114688, null, 0, i7.RecurringPaymentsPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecurringPaymentsPageComponentNgFactory = i1.ɵccf("app-recurring-payments-page", i7.RecurringPaymentsPageComponent, View_RecurringPaymentsPageComponent_Host_0, {}, {}, []);
export { RecurringPaymentsPageComponentNgFactory as RecurringPaymentsPageComponentNgFactory };
