/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../services/social/follow.service";
import * as i3 from "@angular/common/http";
import * as i4 from "./follow-btn.component";
import * as i5 from "../../../../../services/auth/auth.service";
var styles_FollowBtnComponent = [];
var RenderType_FollowBtnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FollowBtnComponent, data: {} });
export { RenderType_FollowBtnComponent as RenderType_FollowBtnComponent };
function View_FollowBtnComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "uk-icon uk-icon-plus"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0\u00A0 Seguir"]))], null, null); }
function View_FollowBtnComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "msg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "uk-icon uk-icon-check"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0\u00A0 Siguiendo"]))], null, null); }
function View_FollowBtnComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "button", [["class", "uk-button club-follow-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.makeFollow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { following: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FollowBtnComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FollowBtnComponent_3)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 2, "span", [["class", "unfollow"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "i", [["class", "uk-icon uk-icon-close"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0\u00A0 Dejar de Seguir"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "uk-button club-follow-button"; var currVal_1 = _ck(_v, 3, 0, _co.ownFollow); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = !_co.ownFollow; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.ownFollow; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_FollowBtnComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_FollowBtnComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.ownProfile; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FollowBtnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-follow-btn", [], null, null, null, View_FollowBtnComponent_0, RenderType_FollowBtnComponent)), i0.ɵprd(512, null, i2.FollowService, i2.FollowService, [i3.HttpClient]), i0.ɵdid(2, 114688, null, 0, i4.FollowBtnComponent, [i2.FollowService, i5.AuthService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var FollowBtnComponentNgFactory = i0.ɵccf("app-follow-btn", i4.FollowBtnComponent, View_FollowBtnComponent_Host_0, { ownFollow: "ownFollow", ownProfile: "ownProfile", user: "user" }, {}, []);
export { FollowBtnComponentNgFactory as FollowBtnComponentNgFactory };
