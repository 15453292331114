/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../message.component.ngfactory";
import * as i2 from "../message.component";
import * as i3 from "./message-module-disabled.component";
var styles_MessageModuleDisabledComponent = [];
var RenderType_MessageModuleDisabledComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MessageModuleDisabledComponent, data: {} });
export { RenderType_MessageModuleDisabledComponent as RenderType_MessageModuleDisabledComponent };
export function View_MessageModuleDisabledComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-message", [], null, null, null, i1.View_MessageComponent_0, i1.RenderType_MessageComponent)), i0.ɵdid(1, 114688, null, 0, i2.MessageComponent, [], { message: [0, "message"], subMessage: [1, "subMessage"], icon: [2, "icon"] }, null)], function (_ck, _v) { var currVal_0 = "Actualmente el m\u00F3dulo se encuentra deshabilitado"; var currVal_1 = "Puede volver a intentarlo m\u00E1s tarde"; var currVal_2 = "lock"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_MessageModuleDisabledComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-message-module-disabled", [], null, null, null, View_MessageModuleDisabledComponent_0, RenderType_MessageModuleDisabledComponent)), i0.ɵdid(1, 114688, null, 0, i3.MessageModuleDisabledComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessageModuleDisabledComponentNgFactory = i0.ɵccf("app-message-module-disabled", i3.MessageModuleDisabledComponent, View_MessageModuleDisabledComponent_Host_0, {}, {}, []);
export { MessageModuleDisabledComponentNgFactory as MessageModuleDisabledComponentNgFactory };
