import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-inscription-step-title',
  templateUrl: './inscription-step-title.component.html',
  styleUrls: ['./inscription-step-title.component.scss']
})
export class InscriptionStepTitleComponent implements OnInit {
  @Input() step;
  @Input() title;
  constructor() { }

  ngOnInit() {
  }

}
