import {Component, Input, OnInit} from '@angular/core';

declare var UIkit;

@Component({
  selector: 'app-ui-kit-modal',
  templateUrl: './ui-kit-modal.component.html',
  styleUrls: ['./ui-kit-modal.component.scss']
})
export class UiKitModalComponent implements OnInit {
  @Input() modalName: string;

  constructor() { }

  get modalRef() {
    return UIkit.modal(`#${this.modalName}`, { center: true });
  }

  ngOnInit() {
  }

  show() {
    this.modalRef.show();
  }
  hide() {
    this.modalRef.hide();
  }
}
