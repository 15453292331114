import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Service } from '../Service.js';
import { AppSettings } from '@app/helpers/app.settings';

@Injectable()
export class VisaService extends Service {
  constructor(private _http: HttpClient) {
    super();
  }

  getSessionToken(data) {
    return this._http.post(
      this.API_ENDPOINT_API_INTERSOCIO + 'payments/session',
      data,
      this.APP_REQUEST_OPTIONS_AUTH
    );
  }

  getVisaResponseValue() {
    return this._http.get('/assets/json/visa_response.json');
  }

  validateTransaction(data) {
    // return this._http.post(`http://localhost:3300/v1/payments/authorize`, data);
    return this._http.post(`${this.API_ENDPOINT_API_INTERSOCIO}payments/authorize`, data, this.APP_REQUEST_OPTIONS_AUTH);
  }
}
