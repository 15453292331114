import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Service } from '../Service.js';
import { AppHelper } from '@app/helpers/app.helper';

@Injectable()
export class PostService extends Service {
  constructor(private _http: HttpClient) {
    super();
  }

  getPost(filter) {
    return this._http.get(
      this.API_ENDPOINT + 'post?' + AppHelper.serializeObj(filter),
      this.APP_REQUEST_OPTIONS
    );
  }

  savePost(data) {
    return this._http
      .post(this.API_ENDPOINT + 'post', data, this.APP_REQUEST_OPTIONS)
      .toPromise()
      .catch((error) => {
        console.log(error);
        console.log(error._body);
      });
  }
}
