<header
  id="application-header"
  class="application-header tw-select-none tw-relative tw-z-10 tw-shadow"
>
  <app-unverified-account *ngIf="!accountConfirmed"></app-unverified-account>
  <div class="application-header-bar">
    <div
      class="uk-visible-small tw-flex tw-justify-between tw-h-full tw-bg-intersocio-blue"
    >
      <a
        id="intersocio-toggle-navigation-button"
        class="toggle-nav tw-h-full tw-flex tw-justify-center tw-items-center tw-w-16 tw-text-3xl"
        (click)="showResponsiveMenu.emit()"
      >
        <i
          class="uk-icon uk-icon-bars tw-text-white"
          [hidden]="isResponsiveMenuShown"
        ></i>
        <i
          class="uk-icon uk-icon-close tw-text-white"
          [hidden]="!isResponsiveMenuShown"
        ></i>
      </a>
      <p
        class="tw-h-full tw-flex tw-justify-center tw-items-center !tw-m-0 tw-font-bold tw-text-xl tw-text-white"
      >
        Club Internacional Arequipa
      </p>
      <div class="tw-flex tw-mr-4">
        <app-header-dropdown>
          <div dropButton class="tw-text-3xl tw-text-white">
            <i class="uk-icon uk-icon-ellipsis-v tw-text-white"></i>
          </div>
          <app-header-menu
            dropContent
            [userSession]="user"
            [menuItems]="menuItems"
            (closeSession)="closeSession.emit()"
            (navClicked)="onNavClick()"
          >
            <ng-container ngProjectAs="[menuItem]">
              <button
                nz-button
                nzType="primary"
                [nzSize]="'small'"
                (click)="searchUpdate()"
                class="tw-mt-1 tw-w-full"
                [nzLoading]="loadingUpdates"
              >
                Actualizar App
              </button>
            </ng-container>
          </app-header-menu>
        </app-header-dropdown>
      </div>
    </div>
    <div class="uk-hidden-small uk-grid uk-grid-small uk-grid-preserve">
      <div class="uk-width-large-2-5 uk-width-medium-2-5 club-socio-main-icon">
        <div class="tw-flex">
          <div class="tw-flex tw-justify-center" style="width: 250px">
            <img
              src="assets/img/inter/inter-socio.logo.svg"
              class="tw-w-48"
              alt="intersocio"
            />
          </div>
          <a
            href="http://www.clubinter.org.pe/"
            target="_blank"
            class="club-header-button uk-hidden-medium uk-hidden-small"
          >
            <span
              class="tw-ml-2 tw-hidden xl:tw-inline-block tw-font-bold tw-text-xl tw-text-white"
              >Club Internacional Arequipa</span
            >
          </a>
        </div>
      </div>
      <div
        class="uk-width-large-3-5 uk-width-medium-3-5 uk-text-right club-socio-header-nav tw-p-0 tw-pr-4"
      >
        <!--<app-search-main-widget
          class="tw-hidden lg:tw-inline-block tw-mr-1"
        ></app-search-main-widget>-->
        <!--
        <app-notification-widget
          [notifier]="notifier"
        ></app-notification-widget>
        -->
        <button
          nz-button
          nzType="primary"
          class="tw-my-2 tw-mx-2 !tw-bg-intersocio-blue-400 !tw-text-white tw-border-0"
          [routerLink]="['/state/debts']"
        >
          Estado de deudas
        </button>
        <app-payment-cart></app-payment-cart>
        <app-header-dropdown *ngIf="user">
          <app-header-user-button
            dropButton
            [user]="user"
          ></app-header-user-button>
          <app-header-menu
            dropContent
            [userSession]="user"
            [menuItems]="menuItems"
            (closeSession)="closeSession.emit()"
            (navClicked)="onNavClick()"
          >
            <ng-container ngProjectAs="[menuItem]">
              <button
                nz-button
                nzType="primary"
                [nzSize]="'small'"
                (click)="searchUpdate()"
                class="tw-mt-1 tw-w-full"
                [nzLoading]="loadingUpdates"
              >
                Actualizar App
              </button>
            </ng-container>
          </app-header-menu>
        </app-header-dropdown>
      </div>
    </div>
  </div>
  <div loading-view class="loading-view-wrapper"></div>
</header>
