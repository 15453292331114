import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '@app/helpers/app.settings';
import { AppHelper } from '@app/helpers/app.helper';
import { Service } from '../Service.js';

@Injectable()
export class TransactionService extends Service {
  constructor(private _http: HttpClient) {
    super();
  }

  getTransactionByToken(data) {
    return this._http.get(
      this.API_ENDPOINT_API_INTERSOCIO + 'debts/transactions/' + data,
      this.APP_REQUEST_OPTIONS_AUTH
    );
  }

  getTransaction(filter) {
    return this._http.get(
      this.API_ENDPOINT_API_INTERSOCIO +
        'debts/transactions/' +
        AppHelper.serializeObj(filter),
      this.APP_REQUEST_OPTIONS_AUTH
    );
  }
}
