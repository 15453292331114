import { Component, OnInit } from '@angular/core';
import {RecurringPaymentsStoreService} from '@app/modules/recurring-payments/data/recurring-payments-store.service';
import {UserRecurringPayment} from '@app/modules/recurring-payments/classes/user-recurring-payment';

@Component({
  selector: 'app-recurring-payment-status',
  templateUrl: './recurring-payment-status.component.html',
  styleUrls: ['./recurring-payment-status.component.scss']
})
export class RecurringPaymentStatusComponent implements OnInit {

  constructor(
    private _recurringPaymentStore: RecurringPaymentsStoreService
  ) { }

  currentUserRecurringPayment: UserRecurringPayment;

  ngOnInit() {
    const { userAffiliation } = this._recurringPaymentStore.recurrentPaymentState;
    this.currentUserRecurringPayment = userAffiliation;
  }

}
