import { Component, OnInit, Input } from '@angular/core';
import stc from 'string-to-color';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
})
export class CommentComponent implements OnInit {
  constructor() {}

  @Input() comment: any;

  ngOnInit() {}

  public stc = stc;
}
