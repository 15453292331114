import { Component, OnInit, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-profile-page-widget',
  templateUrl: './profile-page-widget.component.html',
})
export class ProfilePageWidgetComponent implements OnInit {
  constructor() {}

  @Input() profile: any = {};

  url: any = undefined;

  ngOnInit() {
    if (this.profile.profile_public) { this.url = '/user/' + this.profile.user; }
  }
}
