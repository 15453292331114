import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "../services/system/module.service";
import * as i2 from "../classes/module/module";
// @ts-ignore
export class ModuleAvailabilityService {
    constructor(_moduleService, _moduleClass) {
        this._moduleService = _moduleService;
        this._moduleClass = _moduleClass;
    }
    resolve(route, state) {
        // your logic goes here
        return this._moduleService.getModuleStatus(route.data.moduleId)
            .pipe(map((moduleStatus) => {
            return {
                available: environment.production ? this._moduleClass.moduleIsAvailable(moduleStatus) : true,
                currentTime: moduleStatus.time
            };
        }));
    }
}
ModuleAvailabilityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModuleAvailabilityService_Factory() { return new ModuleAvailabilityService(i0.ɵɵinject(i1.ModuleService), i0.ɵɵinject(i2.Module)); }, token: ModuleAvailabilityService, providedIn: "root" });
