import { Pipe, PipeTransform } from '@angular/core';

export const currencyMap = [
  { code: 'S', description: 'SOLES', currency: 'PEN' },
  { code: 'D', description: 'DOLARES', currency: 'USD' },
];

export const currencies = {
  PEN: 'S',
  USD: 'D',
};

@Pipe({
  name: 'currency',
})
export class CurrencyPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    for (let i = 0; i < currencyMap.length; i++) {
      if (value === currencyMap[i].code) {
        return currencyMap[i].description;
      }
    }
    return null;
  }
}
