import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Module } from '@app/classes/module/module';
import { LoadingMainService } from '@app/services/ui/loading-main.service';
import { ModuleService } from '@app/services/system/module.service';
import { reject } from 'ramda';
import { ILocalModule } from '@app/interfaces/ILocalModule';

@Component({
  selector: 'app-application-lateral-side',
  templateUrl: './application-lateral-side.component.html',
  styleUrls: ['./application-lateral-side.component.scss'],
})
export class ApplicationLateralSideComponent implements OnInit {
  @Input() user: AppUser;
  @Output() closeSession: EventEmitter<any> = new EventEmitter<any>();
  @Output() showResponsiveMenu: EventEmitter<any> = new EventEmitter<any>();
  menusList: any = [];
  profile: any = {};
  navHeight: string;

  constructor(
    private _loadingMainService: LoadingMainService,
    private _moduleService: ModuleService
  ) {}

  ngOnInit() {
    this.getAppHeight();
    this.profile.info = {};
    this.getMenus();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getAppHeight();
  }

  getAppHeight() {
    this.navHeight = `${window.innerHeight - 50}px`;
  }

  getMenus() {
    const filter: any = {};
    filter.key = this.profile.key;
    filter.id = this.profile.id;
    this._loadingMainService.show();
    this._moduleService.getModulesState(filter).then(
      (response: any) => {
        if (response.success) {
          this.menusList = reject(
            (module: ILocalModule) => !module.includeInNavigation,
            Module.parseAppModules(response.data)
          );
        }
        this._loadingMainService.hide();
      },
      (error) => {
        this._loadingMainService.hide();
        this.menusList = reject(
          (module: ILocalModule) => !module.includeInNavigation,
          Module.parseAppModules([])
        );
        console.error(error);
      }
    );
  }
}
