/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../../../../directives/autoresize.directive";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../services/social/comment.service";
import * as i5 from "@angular/common/http";
import * as i6 from "./comment-form.component";
import * as i7 from "../../../../../services/auth/auth.service";
var styles_CommentFormComponent = [];
var RenderType_CommentFormComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CommentFormComponent, data: {} });
export { RenderType_CommentFormComponent as RenderType_CommentFormComponent };
function View_CommentFormComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "club-post-comment-form uk-clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["class", "profile-img"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 7, "textarea", [["autoresize", ""], ["class", "comment-form"], ["rows", "1"], ["style", "height: 30px;padding: 5px;"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "keypress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 3)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 3)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = (i0.ɵnov(_v, 6).onInputChange($event) !== false);
        ad = (pd_4 && ad);
    } if (("keypress" === en)) {
        var pd_5 = (i0.ɵnov(_v, 6).onKeyPressed($event) !== false);
        ad = (pd_5 && ad);
    } if (("ngModelChange" === en)) {
        var pd_6 = ((_co.comment = $event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(5, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵdid(6, 81920, null, 0, i2.AutoresizeDirective, [i0.ElementRef, i0.Renderer, i1.NgModel], { onEnter: [0, "onEnter"] }, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(8, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵted(-1, null, ["  "])), (_l()(), i0.ɵeld(10, 0, null, null, 0, "div", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.loadingCommentForm; var currVal_9 = _co.comment; _ck(_v, 5, 0, currVal_8, currVal_9); var currVal_10 = _co.makeComment; _ck(_v, 6, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sessionUser.profileImg; _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 8).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 8).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 8).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 8).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 8).ngClassValid; var currVal_6 = i0.ɵnov(_v, 8).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 8).ngClassPending; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_CommentFormComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommentFormComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sessionUser; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CommentFormComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-comment-form", [], null, null, null, View_CommentFormComponent_0, RenderType_CommentFormComponent)), i0.ɵprd(512, null, i4.CommentService, i4.CommentService, [i5.HttpClient]), i0.ɵdid(2, 114688, null, 0, i6.CommentFormComponent, [i7.AuthService, i4.CommentService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CommentFormComponentNgFactory = i0.ɵccf("app-comment-form", i6.CommentFormComponent, View_CommentFormComponent_Host_0, { postId: "postId", lastComment: "lastComment" }, {}, []);
export { CommentFormComponentNgFactory as CommentFormComponentNgFactory };
