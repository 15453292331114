import { BehaviorSubject } from 'rxjs';
import { UserRecurringPayment } from '@app/modules/recurring-payments/classes/user-recurring-payment';
import * as i0 from "@angular/core";
export class RecurringPaymentsStoreService {
    constructor() {
        this._recurrentPaymentState = new BehaviorSubject({
            userAffiliation: new UserRecurringPayment()
        });
        this.recurrentPaymentState$ = this._recurrentPaymentState.asObservable();
    }
    // @ts-ignore
    get recurrentPaymentState() {
        return this._recurrentPaymentState.getValue();
    }
    // @ts-ignore
    set recurrentPaymentState(val) {
        this._recurrentPaymentState.next(val);
    }
    setCurrentUserRecurringPayment(userRecurringPayment) {
        this.recurrentPaymentState = Object.assign({}, this.recurrentPaymentState, { userAffiliation: userRecurringPayment });
    }
}
RecurringPaymentsStoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RecurringPaymentsStoreService_Factory() { return new RecurringPaymentsStoreService(); }, token: RecurringPaymentsStoreService, providedIn: "root" });
