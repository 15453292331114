<div class="club-post-comment-form uk-clearfix" *ngIf="sessionUser">
  <img [src]="sessionUser.profileImg" class="profile-img" />
  <textarea
    class="comment-form"
    autoresize
    rows="1"
    style="height: 30px;padding: 5px;"
    [(ngModel)]="comment"
    [onEnter]="makeComment"
    [disabled]="loadingCommentForm"
  >
  </textarea>
  <div></div>
</div>
