<div>
  <div *ngIf="verificationSent" class="tw-bg-green-600 tw-px-4 tw-py-3 tw-text-center">
    <p class="tw-text-white !tw-m-0 tw-text-base !tw-mr-4">Hemos enviado un correo electrónico con un enlace de verificación</p>
  </div>
  <div *ngIf="!verificationSent" class="tw-flex tw-items-center tw-justify-center tw-bg-red-500 tw-px-4 tw-py-3 tw-text-center">
    <p class="tw-text-white !tw-m-0 tw-text-base !tw-mr-4">Verifica tu correo electrónico: <b>{{ userEmail }}</b></p>
    <div>
      <button
        type="button"
        (click)="sendEmailVerification()"
        class="tw-mb-1 md:tw-mb-0 tw-border-none tw-bg-red-600 tw-text-white tw-rounded-md tw-px-2 tw-py-1 tw-transition tw-duration-200 tw-ease tw-select-none hover:tw-bg-red-700 tw-mr-4"
      >
        Verificar
      </button>
      <button
        type="button"
        class="tw-border-none tw-bg-red-600 tw-text-white tw-rounded-md tw-px-2 tw-py-1 tw-transition tw-duration-200 tw-ease tw-select-none hover:tw-bg-red-700"
        [routerLink]="['/account-settings/general']" [queryParams]="{action: 'editEmail'}"
      >
        Actualizar mi correo
      </button>
    </div>
  </div>
</div>

