<app-spinner
  *ngIf="isProcessing"
  [message]="'Recuperando respuesta...'"
  class="fadeIn animated"
></app-spinner>

<app-message
  *ngIf="hasError"
  [message]="'Hubo un error procesando la respuesta del pago'"
  [subMessage]="
    'No se pudo procesar la verificación del pago. Comuníquese con el equipo de TI para reportar el error.'
  "
  [icon]="'warning'"
  class="fadeIn animated"
>
  <div class="tw-text-center tw-mt-4">
    <button class="uk-button" routerLink="/state">
      Regresa a mi estado de pagos
    </button>
  </div>
</app-message>

<ng-container *ngIf="isSuccess">
  <div class="tw-flex tw-flex-wrap tw--mx-2 fadeIn animated">
    <div class="tw-w-full md:tw-w-2/3 tw-px-2">
      <app-payment-visa-response
        [paymentResponse]="paymentResponse"
      ></app-payment-visa-response>
    </div>
    <div class="tw-w-full md:tw-w-1/3 tw-px-2">
      <app-payment-recomendations></app-payment-recomendations>
    </div>
  </div>
</ng-container>
