<div class="club-profile-page fadeIn animated">
  <div class="club-profile-page-header">
    <div
      class="club-profile-page-background"
      [ngStyle]="{ 'background-image': 'url(' + profile.background + ')' }"
    >
      <app-profile-update-image
        [type]="2"
        [ownProfile]="profile.own_profile"
      ></app-profile-update-image>

      <div class="club-profile-header-content">
        <div class="club-profile-page-image">
          <img [src]="profile.image"  alt=""/>
          <app-profile-update-image
            [type]="1"
            [ownProfile]="profile.own_profile"
          ></app-profile-update-image>
        </div>
        <div class="club-profile-name">
          <h3>{{ profile.name }}</h3>
          <span>{{ profile.user }}</span>
        </div>

        <div class="club-profile-buttons uk-float-right">
          <app-follow-btn
            class="follow-button"
            [ownFollow]="profile.own_follow"
            [ownProfile]="profile.own_profile"
            [user]="profile.user"
          ></app-follow-btn>
        </div>
      </div>
    </div>
    <div class="club-profile-page-nav">
      <ul>
        <li (click)="selectTab(1)" [ngClass]="{ 'uk-active': currentTab == 1 }">
          <a>Historias</a>
        </li>
        <li (click)="selectTab(3)" [ngClass]="{ 'uk-active': currentTab == 3 }">
          <a>Fotos</a>
        </li>
      </ul>
    </div>
    <div class="club-profile-buttons-mobile">
      <app-follow-btn
        class="follow-button"
        [ownFollow]="profile.own_follow"
        [ownProfile]="profile.own_profile"
        [user]="profile.user"
      ></app-follow-btn>
    </div>
  </div>

  <ul>
    <li *ngIf="currentTab == 1">
      <div class="uk-grid uk-grid-small">
        <div class="uk-width-large-2-3">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-large-1-3">
              <div class="club-socio-panel">
                <div class="header">Acerca de {{ profile.user }}</div>
                <div class="mb-1">
                  {{ profile.about }}
                </div>
                <div class="mb-1">
                  <b>Miembro desde</b><br />
                  {{ profile.date_joined | amLocale: 'es' | amTimeAgo }}
                </div>
                <div class="mb-1">
                  <b>Género</b><br />
                  <span *ngIf="profile.gender == 'M'">Másculino</span>
                  <span *ngIf="profile.gender == 'F'">Femenino</span>
                </div>
              </div>

              <div class="club-socio-panel">
                <div class="header">
                  Redes Sociales
                </div>
                <div class="uk-grid uk-grid-small">
                  <app-social-button
                    *ngFor="let link of profile.social"
                    [link]="link.url"
                    class="uk-width-large-1-2"
                    style="margin-bottom: 5px;"
                  ></app-social-button>
                </div>
              </div>
            </div>
            <div class="uk-width-large-2-3">
              <div class="club-socio-panel">
                <div class="header">
                  Localización
                </div>
                <div>
                  {{ profile.location }}
                </div>
              </div>
              <div class="club-socio-panel">
                <div class="header">
                  Trabajo
                </div>
                <div>
                  {{ profile.work }}
                </div>
              </div>
              <div class="club-socio-panel">
                <div class="header">
                  Seguidores
                </div>
                <div
                  class="uk-grid uk-grid-small uk-grid-width-large-1-3 uk-grid-width-medium-1-2"
                >
                  <app-profile-page-widget
                    *ngFor="let follower of profileFollowers"
                    [profile]="follower.user_info"
                  ></app-profile-page-widget>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-large-1-3">
          <div class="club-socio-panel">
            <div class="header">
              Publicaciones
            </div>
          </div>
          <div class="post-wrapper">
            <app-post-list [ownUser]="user"></app-post-list>
          </div>
        </div>
      </div>
    </li>

    <li *ngIf="currentTab == 3">
      <div class="club-socio-panel">
        <div class="header">Galería de {{ profile.name }}</div>
      </div>

      <app-post-list
        [type]="[1, 3]"
        [ownUser]="user"
        [grid]="5"
        [wrap]="'gallery'"
      ></app-post-list>
    </li>
  </ul>
</div>
