export default  {
  code: '78f749a37344060203b100e5317a2c1d',
  icon: 'form',
  localEnabled: true,
  name: 'Información de Socio',
  url: 'member-information',
  includeInNavigation: true,
  publishEnabled: true,
    new: true,
};
