<div class="ro-version-app tw-fixed tw-z-50 tw-text-center fadeIn animated">
  <div
    class="tw-bg-gray-800 tw-px-6 tw-py-4 tw-rounded-lg tw-max-w-sm tw-block tw-mx-auto tw-shadow-xl"
  >
    <div class="tw-flex tw-justify-center tw-items-center">
      <p class="tw-mb-0 tw-text-white tw-mr-4 tw-leading-0">
        Existe una nueva versión disponible
      </p>
      <a
        class="tw-text-red-400 hover:tw-text-red-400 tw-text-lg"
        (click)="updateButtonClicked.emit()"
        >Actualizar</a
      >
    </div>
  </div>
</div>
