import { Routes } from '@angular/router';
import { MainComponent } from '@app/modules/application/pages/main/main.component';
import { SearchPageComponent } from '@app/modules/application/components/search/search-page/search-page.component';
import { ProfilePageComponent } from '@app/modules/social/components/profile/profile-page/profile-page.component';
import { ModuleAvailabilityService } from '@app/resolvers/module-availability.service';
const ɵ0 = () => import("./../dashboard/dashboard.module.ngfactory").then((m) => m.DashboardModuleNgFactory), ɵ1 = () => import("./../web-profile/web-profile.module.ngfactory").then((m) => m.WebProfileModuleNgFactory), ɵ2 = () => import("./../account-settings/account-settings.module.ngfactory").then((m) => m.AccountSettingsModuleNgFactory), ɵ3 = () => import("./../member-state/member-state.module.ngfactory").then((m) => m.MemberStateModuleNgFactory), ɵ4 = () => import("./../payment/payment.module.ngfactory").then((m) => m.PaymentModuleNgFactory), ɵ5 = () => import("./../meeting/meeting.module.ngfactory").then((m) => m.MeetingModuleNgFactory), ɵ6 = () => import("./../polling/polling.module.ngfactory").then((m) => m.PollingModuleNgFactory), ɵ7 = () => import("./../member-information-update/member-information-update.module.ngfactory").then((m) => m.MemberInformationUpdateModuleNgFactory), ɵ8 = () => import("./../recurring-payments/recurring-payments.module.ngfactory").then((m) => m.RecurringPaymentsModuleNgFactory), ɵ9 = {
    moduleId: 'academies',
}, ɵ10 = () => import("./../booking/booking.module.ngfactory").then((m) => m.BookingModuleNgFactory), ɵ11 = () => import("./../academy/academy.module.ngfactory").then((m) => m.AcademyModuleNgFactory), ɵ12 = {
    moduleId: 'academies',
}, ɵ13 = () => import("./../virtual-id/virtual-id.module.ngfactory").then((m) => m.VirtualIdModuleNgFactory);
const routes = [
    {
        path: '',
        component: MainComponent,
        children: [
            {
                path: '',
                loadChildren: ɵ0,
            },
            {
                path: 'search',
                component: SearchPageComponent,
            },
            {
                path: 'user/:user',
                component: ProfilePageComponent,
            },
            {
                path: 'user',
                redirectTo: '',
            },
            {
                path: 'profile/setting',
                loadChildren: ɵ1,
            },
            {
                path: 'account-settings',
                loadChildren: ɵ2,
            },
            {
                path: 'state',
                loadChildren: ɵ3,
            },
            {
                path: 'payment',
                loadChildren: ɵ4,
            },
            {
                path: 'events',
                loadChildren: ɵ5,
            },
            {
                path: 'polling',
                loadChildren: ɵ6,
            },
            {
                path: 'member-information',
                loadChildren: ɵ7,
            },
            {
                path: 'recurring-payments',
                loadChildren: ɵ8,
                data: ɵ9,
                resolve: {
                    moduleInfo: ModuleAvailabilityService,
                },
            },
            {
                path: 'booking',
                loadChildren: ɵ10,
            },
            {
                path: 'inscription',
                loadChildren: ɵ11,
                data: ɵ12,
                resolve: {
                    moduleInfo: ModuleAvailabilityService,
                },
            },
            {
                path: 'virtual-id',
                loadChildren: ɵ13,
            },
        ],
    },
];
export const ApplicationRoutes = routes;
export class ApplicationRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13 };
