import { ModuleService } from '@app/services/system/module.service';
import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { reject, values, find, propEq, prop, isNil, has, indexBy, mergeRight, mergeWith, pipe, map, omit } from 'ramda';
import { AppConstants } from '@app/app.constants';
import { environment } from '@env/environment';

const localModulesMatcher = (remoteModules) => {
  const native = indexBy(prop('code'), remoteModules);
  const local = indexBy(prop('code'), values(AppConstants.INSTALLED_MODULES));
  return values(mergeWith(mergeRight, native, local));
};

const parseValueModule = (module) => ({
  ...module,
  active: module.active === '1',
  visible: module.active === '1',
  order: module.order ? parseInt(module.order, 10) : null,
});

const omitNoModuleProps = (module) => (omit([
  'id_module', 'created_at', 'class_icon',
], module));

const rejectUnPublishedModule = (modules) => reject(
  module => has('active', module) ? false : !prop('publishEnabled', module),
  modules,
);

@Injectable()
export class Module {
  constructor(private _moduleService: ModuleService) {}

  static parseAppModules(remoteModules) {
    const matchedModules = pipe(
      map(parseValueModule),
      map(omitNoModuleProps),
      localModulesMatcher,
    )(remoteModules);
    return environment.production ? rejectUnPublishedModule(matchedModules) : matchedModules;
  }

  getModuleStatus(onStatus: Function, module = '') {
    this._moduleService.getModuleStatus(module)
      .subscribe(
      (moduleStatus: any) => {
        onStatus(this.moduleIsAvailable(moduleStatus), moduleStatus.time);
      },
      error => {
        onStatus(false, null);
      }
    );
  }

  moduleIsAvailable(moduleStatus) {
    if (!has('availability', moduleStatus)) {
      return false;
    }
    let isAvailable = true;
    mainLoop: for (let i = 0; i < moduleStatus.availability.length; i++) {
      const now = moduleStatus.time;
      let isBetween = false;
      if (!moduleStatus.availability[i].day) {
        isBetween = this._checkTimeAvailability(
          moduleStatus.availability[i].c_start,
          moduleStatus.availability[i].c_end,
          now
        );

        if (isBetween) {
          isAvailable = !isBetween;
          break mainLoop;
        }
      } else {
        if (
          moment(now).format('YYYY-MM-DD') ===
          moduleStatus.availability[i].day
        ) {
          isBetween = this._checkTimeAvailability(
            moduleStatus.availability[i].c_start,
            moduleStatus.availability[i].c_end,
            now
          );
          if (isBetween) {
            isAvailable = !isBetween;
            break mainLoop;
          }
        }
      }
    }
    return isAvailable;
  }

  private _checkTimeAvailability(from, to, now) {
    const fromMoment = moment(from, 'HH:mm:ss').tz('America/Lima');
    const toMoment = moment(to, 'HH:mm:ss').tz('America/Lima');
    const nowMoment = moment(now).tz('America/Lima');
    const nowMomentTime = moment(nowMoment.format('HH:mm:ss'), 'HH:mm:ss');
    return nowMomentTime.isBetween(fromMoment, toMoment);
  }
}
