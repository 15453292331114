<app-flat-card
  class="club-post fadeIn animated tw-block !tw-mb-4"
  [ngClass]="{ 'full-height': modalContent }"
>
  <div [ngClass]="{ 'modal-wrapper': modalContent }">
    <div id="modal-header">
      <div class="club-post-header uk-clearfix" *ngIf="post.user_info">
        <div class="club-post-info">
          <nz-avatar
            [nzText]="post.user.slice(0, 2).toUpperCase()"
            [ngStyle]="{ backgroundColor: stc(post.user) }"
            nzSize="large"
            class="tw-mr-2"
          ></nz-avatar>
          <div>
            <div>
              <span class="uk-display-block uk-text-bold">
                {{ post.user || 'Usuario' }}
              </span>
              <!--<a
                class="uk-display-block uk-text-bold"
                routerLink="/user/{{ post.user_info.user }}"
                *ngIf="post.user_info.profile_public"
                >{{ post.user_info.user }}</a
              >-->
              <span
                class="uk-text-muted uk-text-small"
                *ngIf="post.post_info"
                >{{
                  post.post_info.created_at | amLocale : 'es' | amTimeAgo
                }}</span
              >
              &nbsp;<span class="uk-text-muted uk-text-small">-</span>&nbsp;
              <span class="uk-text-muted uk-text-small uk-text-lowercase">{{
                post.action
              }}</span>
            </div>
          </div>
        </div>
        <div class="uk-float-right">
          <i class="uk-icon uk-icon-bookmark blue" *ngIf="post.pinned == 1"></i>
        </div>
      </div>

      <div class="club-post-body" *ngIf="post.post_info">
        <div
          *ngIf="post.post_info.content"
          class="mb-05"
          [innerHTML]="post.post_info.content | nl2br | url2a"
        ></div>
        <div *ngIf="post.post_info.image && !modalContent" class="mb-05">
          <img
            [src]="post.post_info.image"
            class="uk-width-1-1"
            (click)="clickImage()"
            style="cursor: pointer"
          />
        </div>
      </div>
      <hr />
      <div class="club-post-footer uk-clearfix">
        <app-like-btn
          [ownLike]="post.own_like"
          (onLiked)="updateLike($event)"
          [likesCount]="post.likes_count"
          [postId]="post.id_post"
        ></app-like-btn>
        <app-comment-btn
          [commentsCount]="post.comments_count"
        ></app-comment-btn>
        <app-like-preview
          [likes]="post.likes"
          [likesCount]="post.likes_count"
          [idPost]="post.id_post"
          style="float: right; margin-top: -5px"
          [appTooltip]="post.likes"
          [appTooltipLike]="post.likes_count"
        ></app-like-preview>
      </div>
    </div>
    <div id="modal-comments" [ngClass]="{ scrolled: modalContent }">
      <div class="club-post-comments">
        <hr />
        <app-comment-list
          *ngIf="post.comments"
          [comments]="post.comments"
          [count]="post.comments.length"
        ></app-comment-list>
        <app-comment-form
          [postId]="post.id_post"
          [lastComment]="getLastComment"
        ></app-comment-form>
      </div>
    </div>
  </div>
</app-flat-card>
