/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inscription-form-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/inscription/inscription.component.ngfactory";
import * as i3 from "../../../../services/academy/academy.service";
import * as i4 from "@angular/common/http";
import * as i5 from "../../../../data-services/member.service";
import * as i6 from "../../../../classes/module/module";
import * as i7 from "../../../../services/system/module.service";
import * as i8 from "../../components/inscription/inscription.component";
import * as i9 from "./inscription-form-page.component";
import * as i10 from "@angular/router";
var styles_InscriptionFormPageComponent = [i0.styles];
var RenderType_InscriptionFormPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InscriptionFormPageComponent, data: {} });
export { RenderType_InscriptionFormPageComponent as RenderType_InscriptionFormPageComponent };
export function View_InscriptionFormPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-inscription", [], null, null, null, i2.View_InscriptionComponent_0, i2.RenderType_InscriptionComponent)), i1.ɵprd(512, null, i3.AcademyService, i3.AcademyService, [i4.HttpClient]), i1.ɵprd(512, null, i5.MemberService, i5.MemberService, [i4.HttpClient]), i1.ɵprd(512, null, i6.Module, i6.Module, [i7.ModuleService]), i1.ɵdid(4, 114688, null, 0, i8.InscriptionComponent, [i3.AcademyService, i5.MemberService, i6.Module], { currentPeriod: [0, "currentPeriod"], currentTime: [1, "currentTime"], members: [2, "members"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentPeriod; var currVal_1 = _co.currentTime; var currVal_2 = _co.members; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_InscriptionFormPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-inscription-form-page", [], null, null, null, View_InscriptionFormPageComponent_0, RenderType_InscriptionFormPageComponent)), i1.ɵdid(1, 114688, null, 0, i9.InscriptionFormPageComponent, [i10.ActivatedRoute, i3.AcademyService, i5.MemberService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InscriptionFormPageComponentNgFactory = i1.ɵccf("app-inscription-form-page", i9.InscriptionFormPageComponent, View_InscriptionFormPageComponent_Host_0, {}, {}, []);
export { InscriptionFormPageComponentNgFactory as InscriptionFormPageComponentNgFactory };
