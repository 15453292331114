import { Session } from '@app/classes/user/session';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class LoginGuard {
    constructor(_router) {
        this._router = _router;
        this.sessionSystem = Session.getInstance();
    }
    canActivate(next, state) {
        if (this.sessionSystem.isLogged() === true) {
            this._router.navigate(['/']);
            return false;
        }
        return true;
    }
}
LoginGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginGuard_Factory() { return new LoginGuard(i0.ɵɵinject(i1.Router)); }, token: LoginGuard, providedIn: "root" });
