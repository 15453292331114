<app-flat-card>
  <owl-carousel
    [options]="{
      items: 1,
      loop: true,
      autoplay: true,
      autoplayTimeout: 4000,
      dosts: true
    }"
    *ngIf="recommendationsList.length > 0"
  >
    <div
      class="club-testimonial "
      *ngFor="let recommendation of recommendationsList"
      [innerHTML]="recommendation.content"
    ></div>
  </owl-carousel>
</app-flat-card>
