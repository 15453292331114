/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../components/application-header/application-header.component.ngfactory";
import * as i2 from "../../components/application-header/application-header.component";
import * as i3 from "../../../../data-services/member.service";
import * as i4 from "@angular/common";
import * as i5 from "../../components/application-lateral-side/application-lateral-side.component.ngfactory";
import * as i6 from "../../components/application-lateral-side/application-lateral-side.component";
import * as i7 from "../../../../services/ui/loading-main.service";
import * as i8 from "../../../../services/system/module.service";
import * as i9 from "@angular/router";
import * as i10 from "../../components/main-modal/main-modal.component.ngfactory";
import * as i11 from "../../components/main-modal/main-modal.component";
import * as i12 from "../../components/feedback/feedback.component.ngfactory";
import * as i13 from "../../components/feedback/feedback.component";
import * as i14 from "@angular/common/http";
import * as i15 from "../../../../classes/module/module";
import * as i16 from "../../../../services/auth/auth.service";
import * as i17 from "../../../../data-services/session.service";
import * as i18 from "../../services/deep-link.service";
import * as i19 from "./main.component";
import * as i20 from "../../../../data-services/analytics.service";
import * as i21 from "../../../shared/services/on-destroy.service";
var styles_MainComponent = [];
var RenderType_MainComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MainComponent, data: {} });
export { RenderType_MainComponent as RenderType_MainComponent };
function View_MainComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 20, "div", [["class", "fadeIn animated"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-application-header", [], null, [[null, "closeSession"], [null, "showResponsiveMenu"], ["window", "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("closeSession" === en)) {
        var pd_1 = (_co.closeSession() !== false);
        ad = (pd_1 && ad);
    } if (("showResponsiveMenu" === en)) {
        var pd_2 = (_co.toggleMenuVisibility() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_ApplicationHeaderComponent_0, i1.RenderType_ApplicationHeaderComponent)), i0.ɵdid(2, 114688, null, 0, i2.ApplicationHeaderComponent, [i3.MemberService], { user: [0, "user"], isResponsiveMenuShown: [1, "isResponsiveMenuShown"] }, { closeSession: "closeSession", showResponsiveMenu: "showResponsiveMenu" }), (_l()(), i0.ɵeld(3, 0, null, null, 13, "div", [["class", "club-socio-wrap folded"]], null, null, null, null, null)), i0.ɵprd(512, null, i4.ɵNgStyleImpl, i4.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(5, 278528, null, 0, i4.NgStyle, [i4.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 10, "div", [["class", "club-socio-content"], ["style", "width: 100%"]], null, null, null, null, null)), i0.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(8, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(9, { activeNav: 0 }), (_l()(), i0.ɵeld(10, 0, null, null, 1, "app-application-lateral-side", [], null, [[null, "closeSession"], [null, "showResponsiveMenu"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("closeSession" === en)) {
        var pd_1 = (_co.closeSession() !== false);
        ad = (pd_1 && ad);
    } if (("showResponsiveMenu" === en)) {
        var pd_2 = (_co.toggleMenuVisibility() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_ApplicationLateralSideComponent_0, i5.RenderType_ApplicationLateralSideComponent)), i0.ɵdid(11, 114688, null, 0, i6.ApplicationLateralSideComponent, [i7.LoadingMainService, i8.ModuleService], { user: [0, "user"] }, { closeSession: "closeSession", showResponsiveMenu: "showResponsiveMenu" }), (_l()(), i0.ɵeld(12, 0, null, null, 4, "div", [["class", "club-socio-view"]], null, null, null, null, null)), i0.ɵprd(512, null, i4.ɵNgStyleImpl, i4.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(14, 278528, null, 0, i4.NgStyle, [i4.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i0.ɵeld(15, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(16, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(17, 0, null, null, 3, "div", [["class", "load-proccess-wrap"], ["id", "main-loading"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 2, "div", [["class", "load-loading ro-loading"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 1, "span", [["style", "display: inline-block; margin-left: 5px"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Procesando..."]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; var currVal_1 = _co.isResponsiveMenuShown; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.contentWrapperStyles; _ck(_v, 5, 0, currVal_2); var currVal_3 = "club-socio-content"; var currVal_4 = _ck(_v, 9, 0, _co.isResponsiveMenuShown); _ck(_v, 8, 0, currVal_3, currVal_4); var currVal_5 = _co.user; _ck(_v, 11, 0, currVal_5); var currVal_6 = _co.contentViewStyles; _ck(_v, 14, 0, currVal_6); _ck(_v, 16, 0); }, null); }
export function View_MainComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_MainComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-main-modal", [], null, null, null, i10.View_MainModalComponent_0, i10.RenderType_MainModalComponent)), i0.ɵdid(3, 114688, null, 0, i11.MainModalComponent, [], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "uk-modal no-select"], ["id", "feedback-user-modal"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "uk-modal-dialog uk-modal-dialog-small"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "a", [["class", "uk-modal-close uk-close"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "app-feedback", [], null, null, null, i12.View_FeedbackComponent_0, i12.RenderType_FeedbackComponent)), i0.ɵdid(8, 114688, null, 0, i13.FeedbackComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); _ck(_v, 8, 0); }, null); }
export function View_MainComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "app-main", [], null, [["window", "click"]], function (_v, en, $event) { var ad = true; if (("window:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).onWindowClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_MainComponent_0, RenderType_MainComponent)), i0.ɵprd(4608, null, i8.ModuleService, i8.ModuleService, [i9.Router, i14.HttpClient]), i0.ɵprd(4608, null, i7.LoadingMainService, i7.LoadingMainService, []), i0.ɵprd(4608, null, i15.Module, i15.Module, [i8.ModuleService]), i0.ɵprd(512, null, i16.AuthService, i16.AuthService, [i14.HttpClient, i9.Router, i17.SessionService]), i0.ɵprd(512, null, i18.DeepLinkService, i18.DeepLinkService, [i9.Router]), i0.ɵdid(6, 114688, null, 0, i19.MainComponent, [i16.AuthService, i18.DeepLinkService, i20.AnalyticsService, i3.MemberService, i21.OnDestroyService], null, null)], function (_ck, _v) { _ck(_v, 6, 0); }, null); }
var MainComponentNgFactory = i0.ɵccf("app-main", i19.MainComponent, View_MainComponent_Host_0, {}, {}, []);
export { MainComponentNgFactory as MainComponentNgFactory };
