import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-btn',
  template: `
    <span class="ro-loading " *ngIf="show"></span>
    <span style="vertical-align: middle;" *ngIf="show">{{ message }}</span>
    <span style="vertical-align: middle;" *ngIf="!show">{{ content }}</span>
  `,
})
export class LoadingBtnComponent {
  @Input() show = false;
  @Input() type = '';
  @Input() content = '';
  @Input() message = '';

  constructor() {
    this.show = false;
    this.type = '';
    this.message = 'Cargando...';
  }
}
