/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./recurring-payment-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../../../shared/components/ui-pack/flat-card/flat-card.component.ngfactory";
import * as i4 from "../../../shared/components/ui-pack/flat-card/flat-card.component";
import * as i5 from "@angular/common";
import * as i6 from "./recurring-payment-info.component";
var styles_RecurringPaymentInfoComponent = [i0.styles];
var RenderType_RecurringPaymentInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RecurringPaymentInfoComponent, data: {} });
export { RenderType_RecurringPaymentInfoComponent as RenderType_RecurringPaymentInfoComponent };
function View_RecurringPaymentInfoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "tw-block tw-mx-auto uk-button uk-button-large tw-uppercase"], ["routerLink", "/recurring-payments/affiliation"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" \u00A1Afiliarme Ahora! "]))], function (_ck, _v) { var currVal_0 = "/recurring-payments/affiliation"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RecurringPaymentInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "app-flat-card", [], [[8, "className", 0]], null, null, i3.View_FlatCardComponent_0, i3.RenderType_FlatCardComponent)), i1.ɵdid(1, 49152, null, 0, i4.FlatCardComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 10, "div", [["class", "\n  tw-flex tw-flex-wrap tw-flex-col-reverse\n  md:tw-flex-row\n"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "tw-w-full md:tw-w-2/6 tw-p-4 lg:tw-mb-0 tw-flex tw-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["src", "assets/modules/recurring-payments/images/calendar-2.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "tw-w-full md:tw-w-4/6 tw-p-4 tw-flex tw-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h3", [["class", "!tw-mb-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00BFQu\u00E9 son los Pagos Recurrentes?"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Con el d\u00E9bito autom\u00E1tico le permite estar al d\u00EDa en sus cuotas y deudas autom\u00E1ticamente, sin preocuparse por fechas l\u00EDmite o perder tiempo haciendo fila."])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecurringPaymentInfoComponent_1)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.userRecurringPayments.isDisaffiliated || _co.userRecurringPayments.isRejected); _ck(_v, 12, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
export function View_RecurringPaymentInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-recurring-payment-info", [], null, null, null, View_RecurringPaymentInfoComponent_0, RenderType_RecurringPaymentInfoComponent)), i1.ɵdid(1, 114688, null, 0, i6.RecurringPaymentInfoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecurringPaymentInfoComponentNgFactory = i1.ɵccf("app-recurring-payment-info", i6.RecurringPaymentInfoComponent, View_RecurringPaymentInfoComponent_Host_0, { userRecurringPayments: "userRecurringPayments" }, {}, []);
export { RecurringPaymentInfoComponentNgFactory as RecurringPaymentInfoComponentNgFactory };
