import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';
import { roLogger } from '@app/helpers/roLogger';
import * as i0 from "@angular/core";
export class AnalyticsService {
    constructor() {
        const logger = roLogger({ context: 'GA' });
        logger('GA service initialized');
        this.analytics = Analytics({
            app: 'intersocio-app',
            plugins: [
                googleAnalytics({
                    measurementIds: ['G-47GSHBTM6G'],
                }),
            ],
        });
    }
}
AnalyticsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(); }, token: AnalyticsService, providedIn: "root" });
