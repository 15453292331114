import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MemberService } from '@app/data-services/member.service';

declare var jQuery: any;

@Component({
  selector: 'app-application-header',
  templateUrl: './application-header.component.html',
  styleUrls: ['./application-header.component.scss'],
})
export class ApplicationHeaderComponent implements OnInit {
  @Input() user: AppUser;
  @Input() isResponsiveMenuShown: boolean;
  @Output() closeSession: EventEmitter<any> = new EventEmitter<any>();
  @Output() showResponsiveMenu: EventEmitter<any> = new EventEmitter<any>();

  menuItems = [];
  accountConfirmed = true;
  loadingUpdates = false;
  constructor(private _userInformationService: MemberService) {}

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    if (!this.accountConfirmed) {
      this.getUserInformation();
    }
  }

  ngOnInit() {
    this.menuItems = [
      {
        name: 'Mi perfil web',
        path: '/user/' + this.user.username,
      },
      {
        name: 'Configurar mi perfil web',
        path: '/profile/setting',
      },
      {
        name: 'Configuración de cuenta',
        path: '/account-settings',
      },
    ];
  }
  onNavClick() {
    jQuery('.club-header-dropdown-content.active').removeClass('active');
  }

  getUserInformation() {
    this._userInformationService.getUserInfo().subscribe(
      ({ email_confirmed: emailConfirmed }: any) => {
        this.accountConfirmed = emailConfirmed;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  searchUpdate() {
    window.location.reload(true);
    /*this.loadingUpdates = true;
    navigator.serviceWorker.getRegistration().then(function (reg) {
      if (reg) {
        reg.unregister().then(() => {
          window.location.reload(true);
        });
      } else {
        window.location.reload(true);
      }
    });*/
  }
}
