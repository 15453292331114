import * as i0 from "@angular/core";
const GLOBAL_POS = 'bottom-center';
export class RoNotificationService {
    constructor() { }
    warning(message) {
        UIkit.notify(message, { status: 'warning', pos: GLOBAL_POS });
    }
    error({ error }) {
        this.danger(`${error.error || error.message}`);
    }
    danger(message, title) {
        const titleHtml = title ? `<b>${title}</b>` : '';
        const messageHtml = `${titleHtml}<p class="m-0">${message}</p>`;
        UIkit.notify(messageHtml, { status: 'danger', pos: GLOBAL_POS });
    }
    success(message) {
        UIkit.notify(message, { status: 'success', pos: GLOBAL_POS });
    }
    modalAlert(content) {
        UIkit.modal.alert(content, { center: true });
    }
    modalConfirm(question, onSuccess) {
        UIkit.modal.confirm(question, onSuccess, { center: true });
    }
}
RoNotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RoNotificationService_Factory() { return new RoNotificationService(); }, token: RoNotificationService, providedIn: "root" });
