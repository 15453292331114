import { Injectable } from '@angular/core';

declare var jQuery: any;
declare var UIkit: any;

@Injectable()
export class LoadingMainService {
  constructor() {}

  show() {
    let $load = jQuery('#main-loading');
    $load.removeClass('hide-loading animate');
    $load.addClass('show-loading animate');
    jQuery('body').addClass('disable-screen');
  }

  hide() {
    let $load = jQuery('#main-loading');
    $load.removeClass('show-loading animate');
    $load.addClass('hide-loading animate');
    jQuery('body').removeClass('disable-screen');
  }
}
