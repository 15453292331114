import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SearchPersonComponent } from './components/commons/utils/search-person/search-person.component';
import { BadgeComponent } from './components/commons/ui/badge/badge.component';
import { ModalComponent } from './components/commons/modal/modal.component';
import { PasswordStrengthDirective } from './directives/password-strength.directive';
import { GooglePlacesDirective } from './directives/google-places.directive';

import { LoginComponent } from './components/login/login.component';
import { SessionGuard } from './guards/session.guard';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { PaymentModule } from '@app/modules/payment/payment.module';
import { RecurringPaymentsModule } from '@app/modules/recurring-payments/recurring-payments.module';
import { SharedModule } from '@app/modules/shared/shared.module';

import { AppAvailabilityGuard } from '@app/guards/app-availability.guard';
import { Module } from '@app/classes/module/module';
import { ModuleService } from '@app/services/system/module.service';
import { AcademyModule } from '@app/modules/academy/academy.module';
import { ModuleAvailabilityService } from '@app/resolvers/module-availability.service';
import { LoginLayoutComponent } from './components/login/login-layout/login-layout.component';
import { ApplicationModule } from '@app/modules/application/application.module';
import { DashboardModule } from '@app/modules/dashboard/dashboard.module';
import { WebProfileModule } from '@app/modules/web-profile/web-profile.module';
import { TokenInterceptor } from '@app/modules/shared/interceptors/token.interceptor';
import { AuthService } from '@app/services/auth/auth.service';
import { NgZorroAntdModule, NZ_I18N, es_ES } from 'ng-zorro-antd';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import es from '@angular/common/locales/es';
import { LoginFormComponent } from './components/login/login-form/login-form.component';
import { AnalyticsService } from '@app/data-services/analytics.service';

registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    GooglePlacesDirective,
    PasswordStrengthDirective,
    ModalComponent,
    BadgeComponent,
    BadgeComponent,
    SearchPersonComponent,
    LoginLayoutComponent,
    LoginFormComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    PaymentModule,
    RecurringPaymentsModule,
    SharedModule,
    AcademyModule,
    ApplicationModule,
    DashboardModule,
    WebProfileModule,
    NgZorroAntdModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
      deps: [AuthService],
    },
    AuthService,
    SessionGuard,
    AppAvailabilityGuard,
    Module,
    ModuleService,
    ModuleAvailabilityService,
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: NZ_I18N, useValue: es_ES },
    AnalyticsService,
  ],
  bootstrap: [AppComponent],
  exports: [LoginLayoutComponent],
})
export class AppModule {}
