import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/services/auth/auth.service';
import { Router } from '@angular/router';
import { Session } from '@app//classes/user/session';
import { AppHelper } from '@app/helpers/app.helper';
import { RoNotificationService } from '@app/modules/shared/services/ro-notification.service';
import { propOr, pipe, trim, isEmpty, equals, forEach, isNil } from 'ramda';

declare var jQuery: any;
declare var UIkit: any;

@Component({
  selector: 'app-login',
  providers: [AuthService],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    private _authService: AuthService,
    private _routerService: Router,
    private _roNotification: RoNotificationService
  ) {}

  systemSession: Session;
  regiterView = false;
  dataRegistration: any = {};
  loadingLogin = false;
  loadingRegistration = false;
  dataError: any = {};
  passwordMatch = true;

  ngOnInit() {
    this.systemSession = Session.getInstance();
  }

  registration(event) {
    if (!this._verifyFieldsRegistration()) {
      this._roNotification.danger(
        'Debe ingresar los datos de manera correcta.'
      );
      return;
    }
    this.loadingRegistration = true;
    this._authService.registration(this.dataRegistration).subscribe(
      (responseRegistration: any) => {
        this._roNotification.success(
          'El usuario fué creado de manera correcta.'
        );
        this.dataRegistration = {};
        this.regiterView = false;
        this.loadingRegistration = false;
      },
      (error) => {
        this._parseErrorResponse(error.error);
        this.loadingRegistration = false;
      }
    );
  }
  _verifyFieldsRegistration() {
    const isInvalidField = (field) =>
      pipe(propOr('', field), trim, isEmpty)(this.dataRegistration);
    if (isInvalidField('dni')) {
      this.dataError.dni = true;
    } else {
      delete this.dataError.dni;
    }

    if (isInvalidField('member_number')) {
      this.dataError.member_number = true;
    } else {
      delete this.dataError.member_number;
    }

    if (isInvalidField('username')) {
      this.dataError.username = true;
    } else {
      delete this.dataError.username;
    }

    if (isInvalidField('email')) {
      this.dataError.email = true;
    } else {
      delete this.dataError.email;
    }

    if (isInvalidField('password')) {
      this.dataError.password = true;
    } else {
      delete this.dataError.password;
    }

    return equals(this.dataError, {}) ? true : this.dataError;
  }

  private _parseErrorResponse(errorList) {
    const fieldsList = [
      'dni',
      'member_number',
      'username',
      'email',
      'password',
    ];
    forEach((field) => {
      if (!isNil(errorList[field])) {
        this.dataError[field] = true;
      } else {
        delete this.dataError[field];
      }
    }, fieldsList);
    const errorHtml =
      '<b>Existen Errores</b><br>' + AppHelper.parseErrorResponse(errorList);
    this._roNotification.danger(errorHtml);
  }
  onPasswordMatch(event) {
    if (event !== '' && this.dataRegistration.password_confirmation) {
      this.passwordMatch = event === this.dataRegistration.password;
    } else {
      this.passwordMatch = true;
    }
  }
}
