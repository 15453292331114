/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inscription-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/ui-pack/layout/module-layout-navigation/module-layout-navigation.component.ngfactory";
import * as i3 from "../../../shared/components/ui-pack/layout/module-layout-navigation/module-layout-navigation.component";
import * as i4 from "../../../shared/components/ui-pack/flat-card/flat-card.component.ngfactory";
import * as i5 from "../../../shared/components/ui-pack/flat-card/flat-card.component";
import * as i6 from "../../../shared/components/ui-pack/left-navigation/left-navigation.component.ngfactory";
import * as i7 from "../../../shared/components/ui-pack/left-navigation/left-navigation.component";
import * as i8 from "@angular/router";
import * as i9 from "../../../shared/components/message/message.component.ngfactory";
import * as i10 from "../../../shared/components/message/message.component";
import * as i11 from "../../../shared/components/module-wrapper/module-wrapper.component.ngfactory";
import * as i12 from "../../../shared/components/module-wrapper/module-wrapper.component";
import * as i13 from "@angular/common";
import * as i14 from "./inscription-page.component";
import * as i15 from "../../../../services/academy/academy.service";
import * as i16 from "../../../../data-services/member.service";
var styles_InscriptionPageComponent = [i0.styles];
var RenderType_InscriptionPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InscriptionPageComponent, data: {} });
export { RenderType_InscriptionPageComponent as RenderType_InscriptionPageComponent };
function View_InscriptionPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "club-socio-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "app-module-layout-navigation", [], null, null, null, i2.View_ModuleLayoutNavigationComponent_0, i2.RenderType_ModuleLayoutNavigationComponent)), i1.ɵdid(2, 114688, null, 0, i3.ModuleLayoutNavigationComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "app-flat-card", [], [[8, "className", 0]], null, null, i4.View_FlatCardComponent_0, i4.RenderType_FlatCardComponent)), i1.ɵdid(5, 49152, null, 0, i5.FlatCardComponent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "h3", [["class", "!tw-mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "app-left-navigation", [], null, null, null, i6.View_LeftNavigationComponent_0, i6.RenderType_LeftNavigationComponent)), i1.ɵdid(9, 114688, null, 0, i7.LeftNavigationComponent, [], { navigationItems: [0, "navigationItems"] }, null), (_l()(), i1.ɵeld(10, 0, null, 1, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(11, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(12, 212992, null, 0, i8.RouterOutlet, [i8.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_2 = _co.navigation; _ck(_v, 9, 0, currVal_2); _ck(_v, 12, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 5).classes; _ck(_v, 4, 0, currVal_0); var currVal_1 = ((_co.title + " ") + _co.currentPeriod.name); _ck(_v, 7, 0, currVal_1); }); }
function View_InscriptionPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "club-socio-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-message", [], null, null, null, i9.View_MessageComponent_0, i9.RenderType_MessageComponent)), i1.ɵdid(2, 114688, null, 0, i10.MessageComponent, [], { message: [0, "message"], subMessage: [1, "subMessage"], icon: [2, "icon"] }, null)], function (_ck, _v) { var currVal_0 = "El m\u00F3dulo de academias se encuentra en mantenimiento"; var currVal_1 = "Estamos preprando las academias para un nuevo mes de Inscripciones, <br>  "; var currVal_2 = "cogs"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_InscriptionPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "app-module-wrapper", [], null, null, null, i11.View_ModuleWrapperComponent_0, i11.RenderType_ModuleWrapperComponent)), i1.ɵdid(1, 114688, null, 0, i12.ModuleWrapperComponent, [i8.ActivatedRoute], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_InscriptionPageComponent_1)), i1.ɵdid(3, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_InscriptionPageComponent_2)), i1.ɵdid(5, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.moduleIsActive; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.moduleIsActive === false); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_InscriptionPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-inscription-page", [], null, null, null, View_InscriptionPageComponent_0, RenderType_InscriptionPageComponent)), i1.ɵdid(1, 114688, null, 0, i14.InscriptionPageComponent, [i8.ActivatedRoute, i15.AcademyService, i16.MemberService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InscriptionPageComponentNgFactory = i1.ɵccf("app-inscription-page", i14.InscriptionPageComponent, View_InscriptionPageComponent_Host_0, {}, {}, []);
export { InscriptionPageComponentNgFactory as InscriptionPageComponentNgFactory };
