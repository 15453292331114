/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./profile-page-widget.component";
var styles_ProfilePageWidgetComponent = [];
var RenderType_ProfilePageWidgetComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProfilePageWidgetComponent, data: {} });
export { RenderType_ProfilePageWidgetComponent as RenderType_ProfilePageWidgetComponent };
function View_ProfilePageWidgetComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(2, 2), (_l()(), i0.ɵeld(3, 0, null, null, 11, "div", [["class", "club-profile-widget-page"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 5, "div", [["class", "header"]], null, null, null, null, null)), i0.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(6, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(7, { "background-image": 0 }), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "profile-img"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, ["", ""])), (_l()(), i0.ɵeld(13, 0, null, null, 1, "span", [["class", "uk-text-muted uk-text-small"]], null, null, null, null, null)), (_l()(), i0.ɵted(14, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 2, 0, "/user", _co.profile.user); _ck(_v, 1, 0, currVal_2); var currVal_3 = _ck(_v, 7, 0, (("url(" + _co.profile.background) + ")")); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _co.profile.image; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.profile.name; _ck(_v, 12, 0, currVal_5); var currVal_6 = _co.profile.user; _ck(_v, 14, 0, currVal_6); }); }
function View_ProfilePageWidgetComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "club-profile-widget-page"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "header"]], null, null, null, null, null)), i0.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(4, { "background-image": 0 }), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "profile-img"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "span", [["class", "uk-text-muted uk-text-small"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, (("url(" + _co.profile.background) + ")")); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.profile.image; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.profile.name; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.profile.user; _ck(_v, 11, 0, currVal_3); }); }
export function View_ProfilePageWidgetComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfilePageWidgetComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfilePageWidgetComponent_2)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profile.profile_public; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.profile.profile_public; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ProfilePageWidgetComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-profile-page-widget", [], null, null, null, View_ProfilePageWidgetComponent_0, RenderType_ProfilePageWidgetComponent)), i0.ɵdid(1, 114688, null, 0, i3.ProfilePageWidgetComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfilePageWidgetComponentNgFactory = i0.ɵccf("app-profile-page-widget", i3.ProfilePageWidgetComponent, View_ProfilePageWidgetComponent_Host_0, { profile: "profile" }, {}, []);
export { ProfilePageWidgetComponentNgFactory as ProfilePageWidgetComponentNgFactory };
