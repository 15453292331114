/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/components/message/message.component.ngfactory";
import * as i2 from "../../../shared/components/message/message.component";
import * as i3 from "./inscription-form/inscription-form.component.ngfactory";
import * as i4 from "../../../../services/academy/academy.service";
import * as i5 from "@angular/common/http";
import * as i6 from "../../../../services/academy/group.service";
import * as i7 from "../../../../services/academy/schedule.service";
import * as i8 from "../../../../data-services/member.service";
import * as i9 from "./inscription-form/inscription-form.component";
import * as i10 from "../../../../services/ui/loading-main.service";
import * as i11 from "../../../shared/services/ro-notification.service";
import * as i12 from "../../../shared/pipes/member-state.pipe";
import * as i13 from "@angular/common";
import * as i14 from "../../../../classes/module/module";
import * as i15 from "../../../../services/system/module.service";
import * as i16 from "./inscription.component";
var styles_InscriptionComponent = [];
var RenderType_InscriptionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InscriptionComponent, data: {} });
export { RenderType_InscriptionComponent as RenderType_InscriptionComponent };
function View_InscriptionComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "app-message", [["icon", "meh"], ["message", "Usted no puede acceder a la inscripciones"]], null, null, null, i1.View_MessageComponent_0, i1.RenderType_MessageComponent)), i0.ɵdid(2, 114688, null, 0, i2.MessageComponent, [], { message: [0, "message"], subMessage: [1, "subMessage"], icon: [2, "icon"] }, null), i0.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Usted no puede acceder a la inscripciones"; var currVal_1 = i0.ɵinlineInterpolate(3, "Estimado socio hemos podido comprobar que no cumple con los siguiente requisitos para realizar la inscripci\u00F3n: ", _co.verificationRequiredStateMsg, " ", i0.ɵunv(_v, 2, 1, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), _co.verificationRequiredState)), " ", _co.verificationRequiredPeriodMsg, " <br> <small>Recuerda que si esta informaci\u00F3n no es correcta puede recargar la p\u00E1gina para actualizar.</small><br>En caso de tener alg\u00FAn inconveniente puede comunicarse con <b>webmaster@clubinter.org.pe</b>"); var currVal_2 = "meh"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_InscriptionComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-inscription-form", [], null, null, null, i3.View_InscriptionFormComponent_0, i3.RenderType_InscriptionFormComponent)), i0.ɵprd(512, null, i4.AcademyService, i4.AcademyService, [i5.HttpClient]), i0.ɵprd(512, null, i6.GroupService, i6.GroupService, [i5.HttpClient]), i0.ɵprd(512, null, i7.ScheduleService, i7.ScheduleService, [i5.HttpClient]), i0.ɵprd(512, null, i8.MemberService, i8.MemberService, [i5.HttpClient]), i0.ɵdid(5, 114688, null, 0, i9.InscriptionFormComponent, [i4.AcademyService, i6.GroupService, i7.ScheduleService, i10.LoadingMainService, i8.MemberService, i11.RoNotificationService], { period: [0, "period"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentPeriod; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_InscriptionComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i12.MemberStatePipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InscriptionComponent_1)), i0.ɵdid(2, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InscriptionComponent_2)), i0.ɵdid(4, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.verificationErrors.state || _co.verificationErrors.period); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.verificationErrors.state && !_co.verificationErrors.period); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_InscriptionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "app-inscription", [], null, null, null, View_InscriptionComponent_0, RenderType_InscriptionComponent)), i0.ɵprd(512, null, i4.AcademyService, i4.AcademyService, [i5.HttpClient]), i0.ɵprd(512, null, i8.MemberService, i8.MemberService, [i5.HttpClient]), i0.ɵprd(512, null, i14.Module, i14.Module, [i15.ModuleService]), i0.ɵdid(4, 114688, null, 0, i16.InscriptionComponent, [i4.AcademyService, i8.MemberService, i14.Module], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var InscriptionComponentNgFactory = i0.ɵccf("app-inscription", i16.InscriptionComponent, View_InscriptionComponent_Host_0, { currentPeriod: "currentPeriod", currentTime: "currentTime", members: "members" }, {}, []);
export { InscriptionComponentNgFactory as InscriptionComponentNgFactory };
