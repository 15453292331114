/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./flat-card.component";
var styles_FlatCardComponent = [];
var RenderType_FlatCardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FlatCardComponent, data: {} });
export { RenderType_FlatCardComponent as RenderType_FlatCardComponent };
export function View_FlatCardComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_FlatCardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-flat-card", [], [[8, "className", 0]], null, null, View_FlatCardComponent_0, RenderType_FlatCardComponent)), i0.ɵdid(1, 49152, null, 0, i1.FlatCardComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
var FlatCardComponentNgFactory = i0.ɵccf("app-flat-card", i1.FlatCardComponent, View_FlatCardComponent_Host_0, { class: "class" }, {}, ["*"]);
export { FlatCardComponentNgFactory as FlatCardComponentNgFactory };
