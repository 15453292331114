import { Injectable } from '@angular/core';
import { Service } from '../Service.js';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '@app/helpers/app.settings';
import { AppHelper } from '@app/helpers/app.helper';

@Injectable()
export class LikeService extends Service {
  constructor(private _http: HttpClient) {
    super();
  }

  makeLike(data) {
    return this._http
      .post(
        this.API_ENDPOINT + 'post/like',
        data,
        this.APP_REQUEST_OPTIONS
      )
      .toPromise()
      .catch((error) => {
        console.log(error);
        console.log(error._body);
      });
  }

  getLikes(filter) {
    return this._http
      .get(
        this.API_ENDPOINT +
          'post/like?' +
          AppHelper.serializeObj(filter),
        this.APP_REQUEST_OPTIONS
      )
      .toPromise()
      .catch((error) => {
        console.log(error);
        console.log(error._body);
      });
  }
}
