import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { SessionGuard } from './guards/session.guard';
import {AppAvailabilityGuard} from '@app/guards/app-availability.guard';
import {AppNotAvailableComponent} from '@app/modules/application/components/app-not-available/app-not-available.component';
import {AppNotAvailableGuard} from '@app/guards/app-not-available.guard';
import {LoginGuard} from '@app/guards/login.guard';
import {ApplicationRoutes} from '@app/modules/application/application-routing.module';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'app-not-available',
    component: AppNotAvailableComponent,
    canActivate: [AppNotAvailableGuard],
  },
  {
    path: 'recover',
    loadChildren: () => import('@app/modules/auth-recover/auth-recover.module').then(m => m.AuthRecoverModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'verification/email',
    loadChildren: () => import('@app/modules/verification/verification.module').then(m => m.VerificationModule),
    canActivate: [LoginGuard],
  },
  {
    path: '',
    children: ApplicationRoutes,
    canActivate: [AppAvailabilityGuard, SessionGuard],
    canActivateChild: [SessionGuard],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
