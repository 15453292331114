import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {UserRecurringPayment} from '@app/modules/recurring-payments/classes/user-recurring-payment';

interface RecurringPaymentsState {
  userAffiliation: UserRecurringPayment;
}

@Injectable({
  providedIn: 'root'
})
export class RecurringPaymentsStoreService {

  constructor() { }

  // @ts-ignore
  get recurrentPaymentState(): RecurringPaymentsState {
    return this._recurrentPaymentState.getValue();
  }

  // @ts-ignore
  set recurrentPaymentState(val: RecurringPaymentsState) {
    this._recurrentPaymentState.next(val);
  }
  private readonly _recurrentPaymentState = new BehaviorSubject<RecurringPaymentsState>({
    userAffiliation: new UserRecurringPayment()
  });
  readonly recurrentPaymentState$ = this._recurrentPaymentState.asObservable();

  setCurrentUserRecurringPayment(userRecurringPayment: UserRecurringPayment) {
    this.recurrentPaymentState = {
      ...this.recurrentPaymentState,
      userAffiliation: userRecurringPayment
    };
  }

}
