<app-flat-card>
<div class="uk-grid uk-grid-small">
    <!--
  <div class="uk-width-large-1-2 mb-1">
    <h3 class="uk-text-bold">Noticias y Información</h3>
    <div class="mb-05" [innerHTML]="academyFeed"></div>
  </div>
  -->
  <div class="uk-width-large-1-1">
    <h4 class="mb-1"><b>Recomendaciones</b></h4>
    <div class="club-recommendation-list">
      <app-recommendation-widget
        [title]="'Vencimiento de Cupos'"
        [content]="
          'Los cupos serán liberado si no realizas el pago de la inscripción el mismo día, entonces otra persona podrá usar tu lugar.'
        "
      >
      </app-recommendation-widget>

      <app-recommendation-widget
        [title]="'Verificación de Periodo Actual'"
        [content]="
          'Recuerda que debes verificar el periodo de la inscripción, el sistema esta disponible el 27 del mes anterior.'
        "
      >
      </app-recommendation-widget>

      <app-recommendation-widget
        [title]="'Información extra'"
        [content]="
          'Algunas academias pueden tener condiciones especiales, se sugiere leer la información extra que aparece al escoger la academia.'
        "
      >
      </app-recommendation-widget>

      <app-recommendation-widget
        [title]="'Cambios y Devoluciones'"
        [content]="
          'Recuerda que no existen cambio ni devoluciones en este tipo de transacción, asi que procura escoger con cuidado antes de realizar los pagos.'
        "
      >
      </app-recommendation-widget>
    </div>
  </div>
</div>
</app-flat-card>
