<div class="club-socio-page">
  <app-module-layout-navigation>
    <ng-container leftSide>
      <app-flat-card>
        <h2 class="!tw-mb-4">Configuración de Perfil</h2>
        <app-left-navigation
          [navigationItems]="[{
          name: 'Configurar mi perfil web',
          path: '/profile/setting'
        }]"
        ></app-left-navigation>
      </app-flat-card>
    </ng-container>
    <ng-container rightSide>
      <app-profile-web-setting></app-profile-web-setting>
    </ng-container>
  </app-module-layout-navigation>
</div>
