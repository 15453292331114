/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./update-app-version.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./update-app-version.component";
var styles_UpdateAppVersionComponent = [i0.styles];
var RenderType_UpdateAppVersionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UpdateAppVersionComponent, data: {} });
export { RenderType_UpdateAppVersionComponent as RenderType_UpdateAppVersionComponent };
export function View_UpdateAppVersionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "ro-version-app tw-fixed tw-z-50 tw-text-center fadeIn animated"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "tw-bg-gray-800 tw-px-6 tw-py-4 tw-rounded-lg tw-max-w-sm tw-block tw-mx-auto tw-shadow-xl"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "tw-flex tw-justify-center tw-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "tw-mb-0 tw-text-white tw-mr-4 tw-leading-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Existe una nueva versi\u00F3n disponible "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["class", "tw-text-red-400 hover:tw-text-red-400 tw-text-lg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateButtonClicked.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Actualizar"]))], null, null); }
export function View_UpdateAppVersionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-update-app-version", [], null, null, null, View_UpdateAppVersionComponent_0, RenderType_UpdateAppVersionComponent)), i1.ɵdid(1, 114688, null, 0, i2.UpdateAppVersionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UpdateAppVersionComponentNgFactory = i1.ɵccf("app-update-app-version", i2.UpdateAppVersionComponent, View_UpdateAppVersionComponent_Host_0, {}, { updateButtonClicked: "updateButtonClicked" }, []);
export { UpdateAppVersionComponentNgFactory as UpdateAppVersionComponentNgFactory };
