/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-visa-timeout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/message/message.component.ngfactory";
import * as i3 from "../../../shared/components/message/message.component";
import * as i4 from "@angular/router";
import * as i5 from "./payment-visa-timeout.component";
import * as i6 from "../../../../data-services/analytics.service";
var styles_PaymentVisaTimeoutComponent = [i0.styles];
var RenderType_PaymentVisaTimeoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentVisaTimeoutComponent, data: {} });
export { RenderType_PaymentVisaTimeoutComponent as RenderType_PaymentVisaTimeoutComponent };
export function View_PaymentVisaTimeoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "club-socio-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "app-message", [], null, null, null, i2.View_MessageComponent_0, i2.RenderType_MessageComponent)), i1.ɵdid(3, 114688, null, 0, i3.MessageComponent, [], { message: [0, "message"], subMessage: [1, "subMessage"], icon: [2, "icon"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "div", [["class", "tw-text-center tw-mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["class", "uk-button"], ["routerLink", "/state"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Regresa a mi estado de pagos"]))], function (_ck, _v) { var currVal_0 = "El proceso demor\u00F3 demasiado"; var currVal_1 = "La espera por la respuesta del servicio de pagos ha demorado mucho. <br> Vuelve a intentarlo m\u00E1s tarde"; var currVal_2 = "clock-o"; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "/state"; _ck(_v, 6, 0, currVal_3); }, null); }
export function View_PaymentVisaTimeoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-visa-timeout", [], null, null, null, View_PaymentVisaTimeoutComponent_0, RenderType_PaymentVisaTimeoutComponent)), i1.ɵdid(1, 114688, null, 0, i5.PaymentVisaTimeoutComponent, [i6.AnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentVisaTimeoutComponentNgFactory = i1.ɵccf("app-payment-visa-timeout", i5.PaymentVisaTimeoutComponent, View_PaymentVisaTimeoutComponent_Host_0, {}, {}, []);
export { PaymentVisaTimeoutComponentNgFactory as PaymentVisaTimeoutComponentNgFactory };
