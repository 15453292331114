import { path } from 'ramda';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../data/recurring-payments-store.service";
export class DisaffiliationFormGuard {
    constructor(router, _recurringPaymentStore) {
        this.router = router;
        this._recurringPaymentStore = _recurringPaymentStore;
    }
    canActivate(next, state) {
        const isAffiliated = path(['userAffiliation', 'isAffiliated'], this._recurringPaymentStore.recurrentPaymentState);
        if (isAffiliated) {
            return true;
        }
        this.router.navigate(['/recurring-payments']);
        return false;
    }
}
DisaffiliationFormGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DisaffiliationFormGuard_Factory() { return new DisaffiliationFormGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.RecurringPaymentsStoreService)); }, token: DisaffiliationFormGuard, providedIn: "root" });
