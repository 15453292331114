import { Subject } from 'rxjs';
import { OnDestroy } from '@angular/core';
import * as i0 from "@angular/core";
export class OnDestroyService {
    constructor() {
        this.destroy$ = new Subject();
    }
    get onDestroy$() {
        return this.destroy$.asObservable();
    }
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
OnDestroyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OnDestroyService_Factory() { return new OnDestroyService(); }, token: OnDestroyService, providedIn: "root" });
