/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/ui-pack/ui-kit-modal/ui-kit-modal.component.ngfactory";
import * as i3 from "../../../shared/components/ui-pack/ui-kit-modal/ui-kit-modal.component";
import * as i4 from "./main-modal.component";
var styles_MainModalComponent = [i0.styles];
var RenderType_MainModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainModalComponent, data: {} });
export { RenderType_MainModalComponent as RenderType_MainModalComponent };
export function View_MainModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { modalTest: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "app-ui-kit-modal", [["modalName", "MAIN_MODAL"]], null, null, null, i2.View_UiKitModalComponent_0, i2.RenderType_UiKitModalComponent)), i1.ɵdid(2, 114688, [[1, 4], ["modalTest", 4]], 0, i3.UiKitModalComponent, [], { modalName: [0, "modalName"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "h3", [["class", "tw-text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["M\u00F3dulo de Pagos en L\u00EDnea"])), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "p", [["class", "tw-text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Estimado socio, por el momento tenemos inconvenientes con el pago, estamos trabajando en el problema, gracias por su comprensi\u00F3n"])), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "div", [["class", "tw-mt-2 tw-text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "uk-button tw-mx-auto"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAccept() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Aceptar"]))], function (_ck, _v) { var currVal_0 = "MAIN_MODAL"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MainModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-main-modal", [], null, null, null, View_MainModalComponent_0, RenderType_MainModalComponent)), i1.ɵdid(1, 114688, null, 0, i4.MainModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MainModalComponentNgFactory = i1.ɵccf("app-main-modal", i4.MainModalComponent, View_MainModalComponent_Host_0, {}, {}, []);
export { MainModalComponentNgFactory as MainModalComponentNgFactory };
