import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
import { roLogger } from '@app/helpers/roLogger';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    /* if ('serviceWorker' in navigator && environment.production) {
      const logger = roLogger({ context: 'SwApplication' });
      navigator.serviceWorker.register('ngsw-worker.js');
      logger('Registering the ngsw-worker.js');
    }*/
  })
  .catch((err) => console.log(err));
