<div class="club-socio-page">
  <div>
    <app-message
      [message]="'El proceso demoró demasiado'"
      [subMessage]="'La espera por la respuesta del servicio de pagos ha demorado mucho. <br> Vuelve a intentarlo más tarde'"
      [icon]="'clock-o'"
    >
      <div class="tw-text-center tw-mt-4">
        <button class="uk-button" routerLink="/state">Regresa a mi estado de pagos</button>
      </div>
    </app-message>
  </div>
</div>
