/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-control-errors.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./form-control-errors.component";
var styles_FormControlErrorsComponent = [i0.styles];
var RenderType_FormControlErrorsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormControlErrorsComponent, data: {} });
export { RenderType_FormControlErrorsComponent as RenderType_FormControlErrorsComponent };
function View_FormControlErrorsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "tw-block tw-text-sm tw-truncate xl:tw-text-red-500"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fieldError; _ck(_v, 2, 0, currVal_0); }); }
export function View_FormControlErrorsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "ro-form-control-errors": 0 }), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormControlErrorsComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.hasErrors); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.hasErrors; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_FormControlErrorsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-control-errors", [], null, null, null, View_FormControlErrorsComponent_0, RenderType_FormControlErrorsComponent)), i1.ɵdid(1, 114688, null, 0, i3.FormControlErrorsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormControlErrorsComponentNgFactory = i1.ɵccf("app-form-control-errors", i3.FormControlErrorsComponent, View_FormControlErrorsComponent_Host_0, { control: "control", submitted: "submitted", customErrorMessage: "customErrorMessage" }, {}, ["*"]);
export { FormControlErrorsComponentNgFactory as FormControlErrorsComponentNgFactory };
