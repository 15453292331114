import { Debt } from '../classes/payment/debt';
import { find, isNil, pipe, not, reject, propEq } from 'ramda';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '@app/helpers/app.settings';
import { LOG_OUT_EVENT } from '@app/data-services/session.service';

const DEBT_KEYS = 'IS:SELECTED_DEBTS';

export interface Commission {
  percentage: number;
  description: string;
  debt: {
    serviceId: number;
    transactionId: string;
    description: string;
    amount: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private _paymentList$: BehaviorSubject<Debt[]> = new BehaviorSubject<Debt[]>(
    sessionStorage.getItem(DEBT_KEYS)
      ? JSON.parse(sessionStorage.getItem(DEBT_KEYS))
      : []
  );

  public paymentList$ = this._paymentList$.asObservable().pipe(
    tap((payDebts) => {
      sessionStorage.setItem(DEBT_KEYS, JSON.stringify(payDebts));
    })
  );

  public commission$: Observable<Commission> = combineLatest([
    this.paymentList$,
    this.getCommission(),
  ]).pipe(
    map(([payDebts, commissionResponse]) => {
      if (!commissionResponse) return null;
      const total = payDebts.reduce((prev, debt) => prev + debt.amount, 0);
      const commissionAmount = (commissionResponse.value / 100) * total;
      return {
        percentage: commissionResponse.value,
        description: commissionResponse.description,
        debt: {
          serviceId: commissionResponse.service_id,
          description: commissionResponse.name,
          transactionId: commissionResponse.service_id,
          amount: commissionAmount,
        },
      } as Commission;
    })
  );

  public totalWithCommission$ = combineLatest([
    this.paymentList$,
    this.commission$,
  ]).pipe(
    map(([payDebts, commission]) => ({
      total: payDebts.reduce((prev, debt) => prev + debt.amount, 0),
      commission,
    })),
    map(({ total, commission }) => {
      if (!commission) return total;
      return total + commission.debt.amount;
    })
  );

  constructor(private http: HttpClient) {
    window.addEventListener(LOG_OUT_EVENT, () => {
      this._paymentList$.next([]);
      sessionStorage.removeItem(DEBT_KEYS);
    });
  }

  static isEqualDebt(debt1: Debt, debt2: Debt) {
    return (
      debt1.id === debt2.id &&
      debt1.amount === debt2.amount &&
      debt1.description === debt2.description &&
      debt1.type === debt2.type
    );
  }

  addItem(item: Debt) {
    if (this.existInList(item)) {
      return;
    }
    this._paymentList$.next([...this._paymentList$.value, item]);
  }

  removeItem(item: Debt) {
    this._paymentList$.next(
      reject(propEq('id', item.id), this._paymentList$.value)
    );
  }

  existInList(debt: Debt) {
    return pipe(
      find((listDebt: Debt) => PaymentService.isEqualDebt(listDebt, debt)),
      isNil,
      not
    )(this._paymentList$.value);
  }

  removeAllItems() {
    this._paymentList$.next([]);
  }

  private getCommission() {
    return this.http
      .get(`${AppSettings.API_ENDPOINT_API_INTERSOCIO}payment_comissions`)
      .pipe(
        catchError(() => {
          console.error('An error occurred getting the commissions');
          return of(null);
        })
      );
  }
}
