import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {Session} from '@app/classes/user/session';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  sessionSystem: Session;

  constructor(private _router: Router) {
    this.sessionSystem = Session.getInstance();
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.sessionSystem.isLogged() === true) {
      this._router.navigate(['/']);
      return false;
    }
    return true;
  }
}
