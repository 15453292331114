/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-recomendations.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/recommendation/recommendation-widget/recommendation-widget.component.ngfactory";
import * as i3 from "../../../shared/components/recommendation/recommendation-widget/recommendation-widget.component";
import * as i4 from "./payment-recomendations.component";
var styles_PaymentRecomendationsComponent = [i0.styles];
var RenderType_PaymentRecomendationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentRecomendationsComponent, data: {} });
export { RenderType_PaymentRecomendationsComponent as RenderType_PaymentRecomendationsComponent };
export function View_PaymentRecomendationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "club-socio-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [["class", "mb-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Recomendaciones"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "club-recommendation-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-recommendation-widget", [], null, null, null, i2.View_RecommendationWidgetComponent_0, i2.RenderType_RecommendationWidgetComponent)), i1.ɵdid(6, 114688, null, 0, i3.RecommendationWidgetComponent, [], { title: [0, "title"], content: [1, "content"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-recommendation-widget", [], null, null, null, i2.View_RecommendationWidgetComponent_0, i2.RenderType_RecommendationWidgetComponent)), i1.ɵdid(8, 114688, null, 0, i3.RecommendationWidgetComponent, [], { title: [0, "title"], content: [1, "content"] }, null)], function (_ck, _v) { var currVal_0 = "Consulta de Transacciones"; var currVal_1 = "Recuerda que puedes consultas la respuestas de tus transacciones en InterSocio en la pesta\u00F1a Pagos Web"; _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_2 = "Transacciones Fallidas"; var currVal_3 = "Recuerda revisar el campo Respuesta de VISA en caso tener una respuesta fallida, en esta se mostrar\u00E1 el motivo. "; _ck(_v, 8, 0, currVal_2, currVal_3); }, null); }
export function View_PaymentRecomendationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-recomendations", [], null, null, null, View_PaymentRecomendationsComponent_0, RenderType_PaymentRecomendationsComponent)), i1.ɵdid(1, 114688, null, 0, i4.PaymentRecomendationsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentRecomendationsComponentNgFactory = i1.ɵccf("app-payment-recomendations", i4.PaymentRecomendationsComponent, View_PaymentRecomendationsComponent_Host_0, {}, {}, []);
export { PaymentRecomendationsComponentNgFactory as PaymentRecomendationsComponentNgFactory };
