/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../../../../directives/autoresize.directive";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../services/social/post.service";
import * as i5 from "@angular/common/http";
import * as i6 from "./post-form.component";
import * as i7 from "../../../../../services/auth/auth.service";
var styles_PostFormComponent = [".postImage[_ngcontent-%COMP%] {\n        position: relative;\n        display: inline-block;\n      }\n      .postImage[_ngcontent-%COMP%]   .uk-close[_ngcontent-%COMP%] {\n        position: absolute;\n        top: 0;\n        top: 0;\n        right: 0;\n        background: #000;\n        color: #fff;\n      }"];
var RenderType_PostFormComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_PostFormComponent, data: {} });
export { RenderType_PostFormComponent as RenderType_PostFormComponent };
function View_PostFormComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "postImage"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "a", [["class", "uk-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.postData.image = undefined) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["style", "width:80px;"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.postData.image; _ck(_v, 2, 0, currVal_0); }); }
function View_PostFormComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Publicando..."]))], null, null); }
function View_PostFormComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Publicar"]))], null, null); }
export function View_PostFormComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 23, "div", [["class", "club-socio-panel"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 22, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i1.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(3, 4210688, null, 0, i1.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.NgForm]), i0.ɵdid(5, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 6, "textarea", [["autoresize", ""], ["class", "uk-width-1-1"], ["name", "content"], ["placeholder", "\u00BFQue estas pensando?"], ["rows", "2"], ["style", "padding: 5px;"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "keypress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 7).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 7)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 7)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = (i0.ɵnov(_v, 10).onInputChange($event) !== false);
        ad = (pd_4 && ad);
    } if (("keypress" === en)) {
        var pd_5 = (i0.ɵnov(_v, 10).onKeyPressed($event) !== false);
        ad = (pd_5 && ad);
    } if (("ngModelChange" === en)) {
        var pd_6 = ((_co.postData.content = $event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(9, 671744, null, 0, i1.NgModel, [[2, i1.ControlContainer], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵdid(10, 81920, null, 0, i2.AutoresizeDirective, [i0.ElementRef, i0.Renderer, i1.NgModel], null, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(12, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵeld(13, 0, null, null, 10, "div", [["class", "uk-clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PostFormComponent_1)), i0.ɵdid(15, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(16, 0, null, null, 2, "label", [["class", "uk-float-left uk-button club-button-blue uk-button-medium"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 0, "i", [["class", "uk-icon uk-icon-picture-o"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 0, "input", [["id", "post-img"], ["style", "display:none;"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.uploadImage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 4, "button", [["class", "uk-float-right uk-button uk-button-medium"], ["type", "submit"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.makePost() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PostFormComponent_2)), i0.ɵdid(21, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PostFormComponent_3)), i0.ɵdid(23, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(24, 0, null, null, 7, "div", [["class", "uk-modal"], ["id", "crop-img"]], null, null, null, null, null)), (_l()(), i0.ɵeld(25, 0, null, null, 6, "div", [["class", "uk-modal-dialog"]], null, null, null, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 0, "a", [["class", "uk-modal-close uk-close"]], null, null, null, null, null)), (_l()(), i0.ɵeld(27, 0, null, null, 0, "div", [["id", "upload-demo"], ["style", "width:100%; height: 305px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(28, 0, null, null, 3, "div", [["class", "uk-clearfix"], ["style", "padding-top: 35px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(29, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(30, 0, null, null, 1, "button", [["class", "uk-button uk-float-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectImage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Seleccionar "]))], function (_ck, _v) { var _co = _v.component; var currVal_14 = "content"; var currVal_15 = _co.postData.content; _ck(_v, 9, 0, currVal_14, currVal_15); _ck(_v, 10, 0); var currVal_16 = _co.postData.image; _ck(_v, 15, 0, currVal_16); var currVal_19 = _co.loadingPost; _ck(_v, 21, 0, currVal_19); var currVal_20 = !_co.loadingPost; _ck(_v, 23, 0, currVal_20); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 5).ngClassValid; var currVal_5 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = i0.ɵnov(_v, 12).ngClassUntouched; var currVal_8 = i0.ɵnov(_v, 12).ngClassTouched; var currVal_9 = i0.ɵnov(_v, 12).ngClassPristine; var currVal_10 = i0.ɵnov(_v, 12).ngClassDirty; var currVal_11 = i0.ɵnov(_v, 12).ngClassValid; var currVal_12 = i0.ɵnov(_v, 12).ngClassInvalid; var currVal_13 = i0.ɵnov(_v, 12).ngClassPending; _ck(_v, 6, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_17 = _co.postData.image; _ck(_v, 16, 0, currVal_17); var currVal_18 = (_co.loadingPost || !_co.postData.content); _ck(_v, 19, 0, currVal_18); }); }
export function View_PostFormComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-post-form", [], null, null, null, View_PostFormComponent_0, RenderType_PostFormComponent)), i0.ɵprd(512, null, i4.PostService, i4.PostService, [i5.HttpClient]), i0.ɵdid(2, 114688, null, 0, i6.PostFormComponent, [i4.PostService, i7.AuthService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var PostFormComponentNgFactory = i0.ɵccf("app-post-form", i6.PostFormComponent, View_PostFormComponent_Host_0, { onSuccess: "onSuccess" }, {}, []);
export { PostFormComponentNgFactory as PostFormComponentNgFactory };
