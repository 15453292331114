/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../ui-pack/flat-card/flat-card.component.ngfactory";
import * as i2 from "../ui-pack/flat-card/flat-card.component";
import * as i3 from "@angular/common";
import * as i4 from "./title-page.component";
var styles_TitlePageComponent = [];
var RenderType_TitlePageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TitlePageComponent, data: {} });
export { RenderType_TitlePageComponent as RenderType_TitlePageComponent };
function View_TitlePageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "uk-icon socio-icon ", _co.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_TitlePageComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0\u00A0"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.description; _ck(_v, 3, 0, currVal_0); }); }
export function View_TitlePageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "app-flat-card", [["class", "!tw-mb-2 tw-block"], ["customClasses", "!tw-py-4"]], [[8, "className", 0]], null, null, i1.View_FlatCardComponent_0, i1.RenderType_FlatCardComponent)), i0.ɵdid(1, 49152, null, 0, i2.FlatCardComponent, [], { class: [0, "class"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 5, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TitlePageComponent_1)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(5, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TitlePageComponent_2)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "!tw-mb-2 tw-block"; _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.icon; _ck(_v, 4, 0, currVal_2); var currVal_4 = _co.description; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.title; _ck(_v, 5, 0, currVal_3); }); }
export function View_TitlePageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-title-page", [], null, null, null, View_TitlePageComponent_0, RenderType_TitlePageComponent)), i0.ɵdid(1, 114688, null, 0, i4.TitlePageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TitlePageComponentNgFactory = i0.ɵccf("app-title-page", i4.TitlePageComponent, View_TitlePageComponent_Host_0, { title: "title", description: "description", icon: "icon" }, {}, []);
export { TitlePageComponentNgFactory as TitlePageComponentNgFactory };
