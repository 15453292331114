import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AcademyRoutingModule } from './academy-routing.module';
import {InscriptionComponent} from '@app/modules/academy/components/inscription/inscription.component';
import {SharedModule} from '@app/modules/shared/shared.module';
import {InscriptionFeedsComponent} from '@app/modules/academy/components/inscription/inscription-feeds/inscription-feeds.component';
import {InscriptionFormComponent} from '@app/modules/academy/components/inscription/inscription-form/inscription-form.component';
import {InscriptionHistoryComponent} from '@app/modules/academy/components/inscription/inscription-history/inscription-history.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { InscriptionPageComponent } from './pages/inscription-page/inscription-page.component';
import {AcademyService} from '@app/services/academy/academy.service';
import { InscriptionNoteComponent } from './components/inscription/inscription-note/inscription-note.component';
import { InscriptionHistoryPageComponent } from './pages/inscription-history-page/inscription-history-page.component';
import { InscriptionFormPageComponent } from './pages/inscription-form-page/inscription-form-page.component';
import { InscriptionInfoPageComponent } from './pages/inscription-info-page/inscription-info-page.component';
import { NgZorroAntdModule  } from 'ng-zorro-antd';
import { InscriptionInformationComponent } from './components/inscription/inscription-form/inscription-information/inscription-information.component';
import { InscriptionStepTitleComponent } from './components/inscription/inscription-form/inscription-step-title/inscription-step-title.component';

@NgModule({
  declarations: [
    InscriptionComponent,
    InscriptionFeedsComponent,
    InscriptionFormComponent,
    InscriptionHistoryComponent,
    InscriptionPageComponent,
    InscriptionNoteComponent,
    InscriptionHistoryPageComponent,
    InscriptionFormPageComponent,
    InscriptionInfoPageComponent,
    InscriptionInformationComponent,
    InscriptionStepTitleComponent,
  ],
  imports: [
    CommonModule,
    AcademyRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    NgZorroAntdModule
  ],
  providers: [
    AcademyService
  ]
})
export class AcademyModule { }
