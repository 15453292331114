export class Schedule {
  public id: number = 0;
  public idService: number = 0;
  public quota: number = 0;
  public availableQuota: number = 0;
  public day: string = '';
  public sDay: string = '';
  public time: string = '';
  public idInfo: number = 0;

  constructor(schedule?) {
    if (schedule !== undefined) {
      this.idInfo = schedule.id_info ? schedule.id_info : 0;
      this.idService = schedule.id_service ? schedule.id_service : 0;
      this.quota = schedule.quota ? schedule.quota : 0;
      this.day = schedule.day ? schedule.day : '';
      this.sDay = schedule.s_day ? schedule.s_day : '';
      this.time = schedule.time ? schedule.time : '';
      this.id = schedule.id_schedule ? schedule.id_schedule : 0;
      this.availableQuota = schedule.available_quota
        ? schedule.available_quota
        : 0;
    }
  }
}
