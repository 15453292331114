/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unverified-account.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../services/account-verification.service";
import * as i5 from "@angular/common/http";
import * as i6 from "./unverified-account.component";
import * as i7 from "../../../../services/ui/loading-main.service";
import * as i8 from "../../../shared/services/ro-notification.service";
var styles_UnverifiedAccountComponent = [i0.styles];
var RenderType_UnverifiedAccountComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnverifiedAccountComponent, data: {} });
export { RenderType_UnverifiedAccountComponent as RenderType_UnverifiedAccountComponent };
function View_UnverifiedAccountComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "tw-bg-green-600 tw-px-4 tw-py-3 tw-text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "tw-text-white !tw-m-0 tw-text-base !tw-mr-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Hemos enviado un correo electr\u00F3nico con un enlace de verificaci\u00F3n"]))], null, null); }
function View_UnverifiedAccountComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "tw-flex tw-items-center tw-justify-center tw-bg-red-500 tw-px-4 tw-py-3 tw-text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [["class", "tw-text-white !tw-m-0 tw-text-base !tw-mr-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Verifica tu correo electr\u00F3nico: "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "tw-mb-1 md:tw-mb-0 tw-border-none tw-bg-red-600 tw-text-white tw-rounded-md tw-px-2 tw-py-1 tw-transition tw-duration-200 tw-ease tw-select-none hover:tw-bg-red-700 tw-mr-4"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendEmailVerification() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Verificar "])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "button", [["class", "tw-border-none tw-bg-red-600 tw-text-white tw-rounded-md tw-px-2 tw-py-1 tw-transition tw-duration-200 tw-ease tw-select-none hover:tw-bg-red-700"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(10, { action: 0 }), i1.ɵpad(11, 1), (_l()(), i1.ɵted(-1, null, [" Actualizar mi correo "]))], function (_ck, _v) { var currVal_1 = _ck(_v, 10, 0, "editEmail"); var currVal_2 = _ck(_v, 11, 0, "/account-settings/general"); _ck(_v, 9, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userEmail; _ck(_v, 4, 0, currVal_0); }); }
export function View_UnverifiedAccountComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnverifiedAccountComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnverifiedAccountComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.verificationSent; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.verificationSent; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_UnverifiedAccountComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-unverified-account", [], null, null, null, View_UnverifiedAccountComponent_0, RenderType_UnverifiedAccountComponent)), i1.ɵprd(512, null, i4.AccountVerificationService, i4.AccountVerificationService, [i5.HttpClient]), i1.ɵdid(2, 114688, null, 0, i6.UnverifiedAccountComponent, [i4.AccountVerificationService, i7.LoadingMainService, i8.RoNotificationService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var UnverifiedAccountComponentNgFactory = i1.ɵccf("app-unverified-account", i6.UnverifiedAccountComponent, View_UnverifiedAccountComponent_Host_0, {}, {}, []);
export { UnverifiedAccountComponentNgFactory as UnverifiedAccountComponentNgFactory };
