import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileService } from '@app/services/social/profile.service';
import { AuthService } from '@app/services/auth/auth.service';
import { PostService } from '@app/services/social/post.service';
import { PostListComponent } from '../../post/post-list/post-list.component';
import { User } from '@app/classes/user/user';
import { Session } from '@app/classes/user/session';
import { propOr, pipe, filter, hasPath } from 'ramda';

declare var jQuery: any;

@Component({
  selector: 'app-profile-page',
  providers: [ProfileService, PostService],
  styles: [
    `
      .post-wrapper {
        max-height: 80vh;
        overflow-y: auto;
        overflow-x: hidden;
      }
    `,
  ],
  templateUrl: './profile-page.component.html',
})
export class ProfilePageComponent implements OnInit {
  constructor(
    private _routeService: ActivatedRoute,
    private _profileService: ProfileService,
    private _authService: AuthService,
    private _routerService: Router
  ) {}

  user: any = {};
  sessionUser: User;
  profile: any = {};
  @ViewChild(PostListComponent, {static: false}) postList: PostListComponent;

  currentTab = 1;

  get profileFollowers() {
    return pipe(propOr([], 'followers'), filter(follower => hasPath(['user_info', 'name'], follower)))(this.profile);
  }

  ngOnInit() {
    this.sessionUser = Session.getInstance().getUser();

    // for changin params
    this._routeService.params.forEach(params => {
      jQuery('.club-socio-view').scrollTop(0);
      this.user = params.user;
      this.getProfile();

      if (this.postList != undefined) { this.postList.reset(this.user); }
    });
  }

  getProfile() {
    const data: any = {};
    data.key = this.sessionUser.key;
    data.id = this.sessionUser.id;
    data.user_session = this.sessionUser.user;
    data.user = this.user;

    this._profileService.getProfile(data).then(
      (response: any) => {
        if (response.success) {
          this.profile = response.data;
        } else { this._routerService.navigate(['/']); }
      },
      error => {
        console.error('No se pudo obtener la información.');
        console.error(error);
      }
    );
  }
  selectTab(value) {
    this.currentTab = value;
  }
}
