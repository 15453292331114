<app-flat-card>
  <div class="tw-py-4 tw-bg-gray-200 tw-rounded-tl tw-rounded-tr">
    <h3 class="tw-text-center">Formulario de Desafiliación</h3>
  </div>
  <hr class="!tw-m-0">
  <div class="tw-p-4 lg:tw-p-8">
    <div>
      <div class="tw-flex tw-flex-wrap tw--m-2 tw-items-baseline tw-mb-4">
        <div class="tw-mb-4 tw-w-full md:tw-w-1/2 tw-px-2">
          <label class="uk-form-label tw-block tw-mb-2 tw-uppercase">Correo de Contacto</label>
          <p class="tw-m-0 tw-border tw-border-solid tw-border-gray-300 tw-rounded tw-px-3 tw-py-1">
            {{ currentUserRecurringPayment.email }}
          </p>
        </div>
        <div class="tw-mb-4 tw-w-full md:tw-w-1/2 tw-px-2">
          <label class="uk-form-label tw-block tw-mb-2 tw-uppercase">Fecha de Solicitud</label>
          <p class="tw-m-0 tw-border tw-border-solid tw-border-gray-300 tw-rounded tw-px-3 tw-py-1">
            {{ currentDateFriendly }}, Arequipa - Perú
          </p>
        </div>
      </div>
      <div class="tw-block tw-mb-6 uk-form-controls">
        <label class="uk-form-label tw-block tw-mb-2">TIPO DE TARJETA</label>
        <p class="tw-m-0 tw-border tw-border-solid tw-border-gray-300 tw-rounded tw-px-3 tw-py-1 tw-uppercase">
          {{ currentUserRecurringPayment.cardBrand }}
        </p>
      </div>
      <div class="tw-flex tw-flex-wrap tw--mx-2 tw-py-4">
        <div
          class="tw-mb-4 uk-form-controls tw-w-full md:tw-w-4/12 sm:tw-w-1/2 tw-px-2"
        >
          <label class="uk-form-label tw-block tw-mb-2 tw-truncate">NOMBRE DEL TITULAR DE LA TARJETA</label>
          <p class="tw-m-0 tw-border tw-border-solid tw-border-gray-300 tw-rounded tw-px-3 tw-py-1">{{ currentUserRecurringPayment.maskedCardNumber }}</p>
        </div >
        <div
          class="tw-mb-4 uk-form-controls tw-w-full md:tw-w-3/12 sm:tw-w-1/2 tw-px-2"
        >
          <label class="uk-form-label tw-block tw-mb-2 tw-truncate">DNI DEL TITULAR DE LA TARJETA</label>
          <p class="tw-m-0 tw-border tw-border-solid tw-border-gray-300 tw-rounded tw-px-3 tw-py-1">{{ currentUserRecurringPayment.cardHolder }}</p>
        </div >
      </div>
      <div class="tw-py-4 tw-block">
        <label class="uk-form-label tw-block tw-mb-2 tw-truncate">Nº DE CARNET DE SOCIOS AFILIADOS</label>
        <p class="tw-m-0 tw-border tw-border-solid tw-border-gray-300 tw-rounded tw-px-3 tw-py-1">
          {{ currentUserRecurringPayment.linkedAssociateNumbers }}
        </p>
      </div>
      <div class="tw-mb-4">
        <p class="tw-text-justify tw-p-6 tw-rounded tw-mb-6 tw-bg-blue-100">NOTA:- El proceso de desafiliación se realizará inmediatamente después que el socio haya enviado el presente documento, por lo que en adelante será responsabilidad del socio mantenerse al día en el pago de sus cuotas sociales u otras obligaciones que adquiera con el club.- El socio puede retomar el servicio las veces que desee, siempre y cuando realice la afiliación correspondiente de su tarjeta en la Oficina de Tecnologías de la Información. De acuerdo a lo dispuesto por la Ley 29733 (Ley de Protección de Datos Personales) y su Reglamento, quien suscribe brinda su consentimiento de manera libre, previa, inequívoca, informada y expresa al CLUB INTERNACIONAL AREQUIPA a efectos de que realice el acopio y tratamiento de mis datos personales CON EL OBJETO DE ACTUALIZAR EL REGISTRO Y BASE DE DATOS DEL CLUB Y PODER SER NOTIFICADO VÁLIDAMENTE CON LAS COMUNICACIONES QUE SE EMITA.Asimismo, AUTORIZO al Club Internacional Arequipa para que me notifique a través del correo electrónico proporcionado, todos los actos administrativos de carácter particular, así como también los procedimientos, comunicados y trámites en general.</p>
        <label class="ro-input-checkbox tw-inline-block tw-mr-4 tw-cursor-pointer">
          <input type="checkbox" [value]="false" [(ngModel)]="acceptTerms">
          He leído y estoy de acuerdo con la información consignada en este documento
          <span class="checkmark"></span>
        </label>
      </div>
      <div>
        <button type="button" class="uk-button uk-button-default uk-button-large !tw-mr-2 !tw-mb-2" routerLink="/recurring-payments/">Cancelar</button>
        <button
          type="button"
          class="uk-button uk-button-large !tw-mr-2"
          (click)="validateForm()"
          [disabled]="!acceptTerms"
        >Enviar Solicitud de Desafiliación</button>
    </div>
    </div>
  </div>
</app-flat-card>>
