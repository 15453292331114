import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-social-button',
  templateUrl: './social-button.component.html',
})
export class SocialButtonComponent implements OnInit {
  constructor() {}

  @Input() link = '';

  socialButton: any = {};
  dbSocialNetwork: any = [
    'facebook',
    'twitter',
    'instagram',
    'plus',
    'linkedin',
    'youtube',
  ];

  ngOnInit() {
    this.socialButton.url = this.link;
    this.socialButton.type = this.getType();
  }

  getType() {
    for (let i = 0; i < this.dbSocialNetwork.length; i++) {
      const exist = this.link.search(this.dbSocialNetwork[i]);
      if (exist > 0) { return this.dbSocialNetwork[i]; }
    }

    return this.getHostName(this.link);
  }

  getHostName(url) {
    const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (
      match != null &&
      match.length > 2 &&
      typeof match[2] === 'string' &&
      match[2].length > 0
    ) {
      return match[2];
    } else {
      return null;
    }
  }
}
