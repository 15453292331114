<div class="club-socio-page">
  <app-title-page [title]="title" icon="uk-icon-shopping-cart"></app-title-page>
  <app-payment-stepper
    *ngIf="paymentCount$ | async as paymentCount; else emptyTemplate"
  ></app-payment-stepper>

  <ng-template #emptyTemplate>
    <div class="club-socio-panel">
      <app-message
        [message]="'Usted no tiene elementos seleccionados para el pago'"
        [subMessage]="
          'Puede dirigirse a estado de socio y seleccionar elementos.'
        "
        [icon]="'cart'"
      >
        <button
          nz-button
          nzType="primary"
          [routerLink]="['/state/debts']"
          class="tw-mt-2"
        >
          Estado de Socio
        </button>
      </app-message>
    </div>
  </ng-template>
</div>
