<app-flat-card>
  <div class="uk-grid uk-grid-small">
    <div
      *ngFor="let profile of profilePopularList"
      style="margin-bottom: 10px;"
      class="uk-width-large-1-2"
    >
      <app-profile-widget
        [userInfo]="profile.user_info"
        [text]="profile.total_follower + ' seguidores'"
      ></app-profile-widget>
    </div>
  </div>
</app-flat-card>
