<app-flat-card class="tw-block tw-mx-auto" style="width: 380px; max-width: 100%;">
  <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
    <nz-form-item>
      <nz-form-control nzErrorTip="Campo requerido!">
        <p class="tw-mb-2 tw-leading-none">Usuario o correo electrónico</p>
        <nz-input-group nzPrefixIcon="user">
          <input type="text" nz-input formControlName="userName" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control nzErrorTip="Campo requerido!">
        <p class="tw-mb-2 tw-leading-none">Contraseña</p>
        <nz-input-group nzPrefixIcon="lock">
          <input type="password" nz-input formControlName="password" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <div nz-row class="tw-mb-2">
      <div nz-col [nzSpan]="12">
        <a routerLink="/recover">Olvidé mi contraseña</a>
      </div>
    </div>
    <button
      nz-button class="tw-block tw-w-full tw-mb-2" [nzType]="'primary'"
      [nzSize]="'large'"
      [nzLoading]="isLoading"
    >Iniciar Sesión</button>
    o <a (click)="registerClick.emit()"> Regístrarme ahora! </a>
  </form>
</app-flat-card>
