/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./recurring-payment-status-indicator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./recurring-payment-status-indicator.component";
var styles_RecurringPaymentStatusIndicatorComponent = [i0.styles];
var RenderType_RecurringPaymentStatusIndicatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RecurringPaymentStatusIndicatorComponent, data: {} });
export { RenderType_RecurringPaymentStatusIndicatorComponent as RenderType_RecurringPaymentStatusIndicatorComponent };
export function View_RecurringPaymentStatusIndicatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "uk-badge !tw-py-2 !tw-px-4 tw-rounded !tw-inline-flex tw-items-center"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "tw-uppercase tw-text-base"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "uk-badge !tw-py-2 !tw-px-4 tw-rounded !tw-inline-flex tw-items-center"; var currVal_1 = _ck(_v, 3, 0, _co.styleIndicator.class); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.userRecurringPayment.localState.name; _ck(_v, 5, 0, currVal_2); }); }
export function View_RecurringPaymentStatusIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-recurring-payment-status-indicator", [], null, null, null, View_RecurringPaymentStatusIndicatorComponent_0, RenderType_RecurringPaymentStatusIndicatorComponent)), i1.ɵdid(1, 114688, null, 0, i3.RecurringPaymentStatusIndicatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecurringPaymentStatusIndicatorComponentNgFactory = i1.ɵccf("app-recurring-payment-status-indicator", i3.RecurringPaymentStatusIndicatorComponent, View_RecurringPaymentStatusIndicatorComponent_Host_0, { userRecurringPayment: "userRecurringPayment" }, {}, []);
export { RecurringPaymentStatusIndicatorComponentNgFactory as RecurringPaymentStatusIndicatorComponentNgFactory };
