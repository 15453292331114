/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./social-button.component";
var styles_SocialButtonComponent = [];
var RenderType_SocialButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SocialButtonComponent, data: {} });
export { RenderType_SocialButtonComponent as RenderType_SocialButtonComponent };
export function View_SocialButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4], [8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.socialButton.url, ""); var currVal_1 = i0.ɵinlineInterpolate(1, "club-social-button club-social-button-", _co.socialButton.type, " uk-text-truncate"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.socialButton.type; _ck(_v, 1, 0, currVal_2); }); }
export function View_SocialButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-social-button", [], null, null, null, View_SocialButtonComponent_0, RenderType_SocialButtonComponent)), i0.ɵdid(1, 114688, null, 0, i1.SocialButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SocialButtonComponentNgFactory = i0.ɵccf("app-social-button", i1.SocialButtonComponent, View_SocialButtonComponent_Host_0, { link: "link" }, {}, []);
export { SocialButtonComponentNgFactory as SocialButtonComponentNgFactory };
