import md5 from 'md5';
import stringToColor from 'string-to-color';

export const roLogger = ({ context = '', debug = true }) => {
  const hashedContext = md5(context);

  return (...args: [any?, ...any[]]) => {
    if (!debug) return;

    console.log.apply(null, [
      `%c:: LOG [%c ${context} %c]`,
      'color: gray',
      `color: ${stringToColor(hashedContext)}; background: black`,
      'color: gray',
      ...args,
    ]);
  };
};
