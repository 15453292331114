<app-login-layout>
  <app-login-form
    *ngIf="!regiterView"
    (registerClick)="regiterView = true"
  ></app-login-form>

  <div class="fadeIn animated uk-width-1-1">
    <div
      class="club-login-panel fadeIn animated"
      [ngClass]="{ 'club-loading-wrapper': loadingLogin }"
      [hidden]="!regiterView"
    >
      <h3 class="uk-text-center !tw-mb-2"><b>Registrarte</b></h3>
      <form
        class="uk-form box-padding-4 !tw-mb-2"
        (submit)="registration($event)"
        ngNativeValidate
      >
        <div class="club-login-form">
          <div style="margin-bottom: 10px;">
            <label class="uk-form-label"
            >Documento de Identidad</label
            >
            <div class="uk-form-controls uk-width-1-1">
              <input
                id="reg_dni"
                class="uk-width-1-1"
                name="dni"
                type="text"
                required
                minlength="7"
                maxlength="10"
                placeholder="Documento"
                [(ngModel)]="dataRegistration.dni"
                autocomplete="off"
                pattern="[0-9]*"
                ng-pattern="regex2"
                [ngClass]="{ 'uk-form-danger': dataError.dni }"
              />
            </div>
          </div>

          <div style="margin-bottom: 10px;">
            <label class="uk-form-label"
            >Número de Socio</label
            >
            <div class="uk-form-controls" title="Debe tener 5 dígitos">
              <input
                id="reg_member"
                class="uk-width-1-1"
                name="member_number"
                type="text"
                required
                maxlength="5"
                placeholder="Número de Socio"
                autocomplete="off"
                [(ngModel)]="dataRegistration.member_number"
                pattern="[0-9]*"
                ng-pattern="regex2"
                [ngClass]="{ 'uk-form-danger': dataError.member_number }"
              />
            </div>
          </div>

          <div style="margin-bottom: 10px;">
            <label class="uk-form-label"
            >Correo Electrónico</label
            >
            <div class="uk-form-controls">
              <input
                class="uk-width-1-1"
                type="email"
                required
                autocomplete="off"
                placeholder="Correo Electrónico"
                [(ngModel)]="dataRegistration.email"
                name="email"
                [ngClass]="{ 'uk-form-danger': dataError.email }"
              />
            </div>
          </div>

          <div style="margin-bottom: 10px;">
            <label class="uk-form-label"
            >Elige un nombre de usuario</label
            >
            <div class="uk-form-controls">
              <input
                class="uk-width-1-1"
                name="username"
                type="text"
                ng-pattern="regex"
                maxlength="20"
                minlength="6"
                placeholder="Usuario"
                autocomplete="off"
                [(ngModel)]="dataRegistration.username"
                title="Mínimo 6 caracteres, letras y/o números"
                [ngClass]="{ 'uk-form-danger': dataError.username }"
                required
              />
            </div>
          </div>

          <div style="margin-bottom: 10px;">
            <label class="uk-form-label"
            >Crea una contraseña</label
            >
            <div class="uk-form-controls">
              <input
                class="uk-width-1-1"
                id="password"
                name="password"
                type="password"
                placeholder="Contraseña"
                required
                autocomplete="off"
                [(ngModel)]="dataRegistration.password"
                [ngClass]="{ 'uk-form-danger': dataError.password }"
                minlength="6"
              />
            </div>
          </div>

          <label class="uk-form-label"
          >Confirma la contraseña
            <small style="color: red" *ngIf="!passwordMatch"
            >No coinciden</small
            >
          </label>

          <div class="uk-form-controls">
            <input
              class="uk-width-1-1"
              name="password_confirmation"
              type="password"
              placeholder="Confirme Contraseña"
              required
              autocomplete="off"
              [(ngModel)]="dataRegistration.password_confirmation"
              (ngModelChange)="onPasswordMatch($event)"
              [ngClass]="{ 'uk-form-danger': !passwordMatch }"
              minlength="6"
            />
          </div>
          <br />

          <div class="uk-form-controls">
            <label class="ro-input-checkbox tw-inline-block tw-mr-4 tw-cursor-pointer">
              <input type="checkbox" [(ngModel)]="dataRegistration.tyc" name="tyc" />
                &nbsp;Acepto los
                <a href="http://www.clubinter.org.pe/tyc/content/intersocio" target="_blank" >términos y condiciones.</a>
              <span class="checkmark"></span>
            </label>
          </div>
        </div>

        <button
          type="submit"
          class="uk-button uk-button-large uk-width-1-1"
          [disabled]="!dataRegistration.tyc || loadingRegistration"
        >
          <app-loading-btn
            content="Registrarme"
            [show]="loadingRegistration"
          ></app-loading-btn>
        </button>
      </form>
      <div class="club-login-panel-description uk-text-center">
        <div>
          <span>¿Ya tienes una cuenta?</span>
          <a (click)="regiterView = false"><b>Iniciar Sesión</b></a>
        </div>
      </div>
    </div>
  </div>
</app-login-layout>
