/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "angular2-moment/time-ago.pipe";
import * as i4 from "angular2-moment/locale.pipe";
import * as i5 from "./profile-widget.component";
var styles_ProfileWidgetComponent = [];
var RenderType_ProfileWidgetComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProfileWidgetComponent, data: {} });
export { RenderType_ProfileWidgetComponent as RenderType_ProfileWidgetComponent };
function View_ProfileWidgetComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["class", "profile-img"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userInfo.image; _ck(_v, 0, 0, currVal_0); }); }
function View_ProfileWidgetComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "uk-display-inlineblock uk-text-bold"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userInfo.user; _ck(_v, 1, 0, currVal_0); }); }
function View_ProfileWidgetComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "uk-display-inlineblock uk-text-bold"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(2, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵinlineInterpolate(1, "/user/", _co.userInfo.user, ""); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.userInfo.user; _ck(_v, 2, 0, currVal_3); }); }
function View_ProfileWidgetComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [["class", "uk-text-muted uk-text-small"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵppd(2, 2), i0.ɵpid(131072, i3.TimeAgoPipe, [i0.ChangeDetectorRef, i0.NgZone])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 3).transform(i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), _co.mutedNext, "es")))); _ck(_v, 1, 0, currVal_0); }); }
function View_ProfileWidgetComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); }); }
function View_ProfileWidgetComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "club-profile-widget uk-clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileWidgetComponent_2)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 9, "div", [["class", "club-profile-widget-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "club-profile-widget-header"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileWidgetComponent_3)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileWidgetComponent_4)), i0.ɵdid(8, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileWidgetComponent_5)), i0.ɵdid(10, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileWidgetComponent_6)), i0.ɵdid(12, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userInfo.image; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.userInfo.profile_public; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.userInfo.profile_public; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.mutedNext; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.text; _ck(_v, 12, 0, currVal_4); }, null); }
function View_ProfileWidgetComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "club-profile-widget club-profile-widget-horizontal  uk-clearfix"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { link: 0, "club-socio-panel": 1, hover: 2 }), i0.ɵdid(4, 16384, null, 0, i1.RouterLink, [i1.Router, i1.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 0, "img", [["class", "profile-img"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "club-profile-widget-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "p", [["class", "m-0 uk-text-truncate"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "b", [["class", "uk-text-muted"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "club-profile-widget club-profile-widget-horizontal  uk-clearfix"; var currVal_1 = _ck(_v, 3, 0, _co.link, _co.panelStyle, _co.hover); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.profileLink; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.userInfo.image; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.userInfo.name; _ck(_v, 8, 0, currVal_4); var currVal_5 = _co.userInfo.user; _ck(_v, 10, 0, currVal_5); }); }
export function View_ProfileWidgetComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i4.LocalePipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileWidgetComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileWidgetComponent_7)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type == 1); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.type == 2); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ProfileWidgetComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-profile-widget", [], null, null, null, View_ProfileWidgetComponent_0, RenderType_ProfileWidgetComponent)), i0.ɵdid(1, 114688, null, 0, i5.ProfileWidgetComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileWidgetComponentNgFactory = i0.ɵccf("app-profile-widget", i5.ProfileWidgetComponent, View_ProfileWidgetComponent_Host_0, { userInfo: "userInfo", mutedNext: "mutedNext", text: "text", type: "type", link: "link", panelStyle: "panelStyle", hover: "hover" }, {}, []);
export { ProfileWidgetComponentNgFactory as ProfileWidgetComponentNgFactory };
