<div class="club-socio-page">
    <div class="club-socio-page-content">
        <div class="tw-flex tw-flex-wrap tw--mx-2">
            <div class="tw-w-full md:tw-w-7/12 tw-px-2">
                <div class="club-dash-feeds">
                    <app-feeds>
                        <div class="tw-block md:tw-hidden" focusedContent>
                            <app-social-banners></app-social-banners>
                        </div>
                    </app-feeds>
                </div>
            </div>
            <div
                id="right-section"
                class="tw-w-full md:tw-w-5/12 tw-px-2 tw-hidden md:tw-block"
            >
                <div
                    class="feed-sticky-content tw-fixed tw-overflow-scroll"
                    [ngStyle]="feedStickyContentStyles"
                >
                    <app-social-banners></app-social-banners>
                    <app-profile-popular
                        class="tw-block tw-mb-4"
                        [limit]="9"
                    ></app-profile-popular>
                    <app-recommendation
                        class="tw-block tw-mb-4"
                        [typeRecommmendation]="'DASH'"
                    ></app-recommendation>
                </div>
            </div>
        </div>
    </div>
</div>
