export const environment = {
  production: true,
  version: '8.7.2',
  buildTimeStamp: 'Thu Jul 18 2024 17:46:40 GMT+0000 (Coordinated Universal Time)',
  baseUrlRails: 'https://api-intersocio.clubinter.org.pe/v1/',
  baseUrlCodeIgniter: 'https://dev-intersocio.clubinter.org.pe/api/v2/',
  baseUrlApiInter: 'https://api-inter.clubinter.org.pe/v1/',
  baseUrlApp: 'https://intersocio.clubinter.org.pe/',
  baseUrlSlim: 'https://api-v2-intersocio.clubinter.org.pe/api/v1/',
  visa: {
    paymentButtonLib: 'https://static-content.vnforapps.com/v2/js/checkout.js',
    merchantId: '168420003',
  },
  globalMessageModalActive: false,
  academies: {
    monthLimitRestriction: 5
  },
  polling: {
    signing: {
      url: 'https://signing-intersocio.clubinter.org.pe/'
    },
    poll: {
      url: 'https://poll-intersocio.clubinter.org.pe/'
    },
  },
};
