import {NiubizResponse} from '@app/modules/payment/classes/niubiz-response';
import { propOr, tryCatch, always, pipe, prop, ifElse, has } from 'ramda';
import * as moment from 'moment';

export class PaymentResponse {
  transactionId: number;
  user: string;
  userId: number;
  memberNumber: string;
  clubTicker: string;
  amount: number;
  state: string;
  payedItems: Array<any>;
  cardBrand: string;
  cardPan: string;
  transactionDateFormatted: string;
  visaTransactionResponse: NiubizResponse;
  constructor(paymentResponse?) {
    const getFrom = (defaultVal, field) => propOr(defaultVal, field, paymentResponse);
    this.transactionId = getFrom(0, 'id_transaction');
    this.user = getFrom('', 'user');
    this.userId = getFrom('', 'user_id');
    this.memberNumber = getFrom('', 'num_member');
    this.clubTicker = getFrom('', 'club_ticket');
    this.amount = getFrom(0, 'amount');
    this.state = getFrom('', 'state');
    this.payedItems = tryCatch(i => JSON.parse(i), always([]))(getFrom('', 'items'));
    this.cardBrand = getFrom('', 'card_brand');
    this.cardPan = getFrom('', 'card_pan');
    this.visaTransactionResponse = pipe(
      tryCatch(i => JSON.parse(i), always({})),
      ifElse(has('dataMap'), prop('dataMap'), prop('data')),
      r => new NiubizResponse(r)
    )(getFrom('', 'service_response'));
    this.transactionDateFormatted = moment.utc(getFrom('', 'updated_at')).local().format('YYYY-MM-DD HH:mm:ss');
  }
}
