import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import stc from 'string-to-color';

declare var jQuery: any;

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styles: [
    `
      .full-height {
        height: 100%;
        position: relative;
      }
      .modal-wrapper {
        position: absolute;
        bottom: 0;
        top: 0;
        overflow: auto;
        padding: inherit;
        right: 0;
        left: 0;
      }
      @media (max-width: 767px) {
        .modal-wrapper {
          position: relative;
          padding: 0;
        }
      }
    `,
  ],
})
export class PostComponent implements OnInit, OnChanges {
  @Input() post: any = {};
  @Input() onClickImage: Function = undefined;
  @Input() modalContent = false;

  constructor(private _elmRef: ElementRef) {}

  ngOnInit() {}

  ngOnChanges() {}

  getLastComment = (lastComment) => {
    this.insertComment(lastComment);
  };

  insertComment(comment) {
    this.post.comments.push(comment);
  }

  updateLike(event) {
    this.post.own_like = event.ownLike;
    this.post.likes_count = event.likesCount;
  }

  clickImage() {
    if (this.onClickImage != undefined) {
      this.onClickImage(this.post);
    }
  }

  public stc = stc;
}
