import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { OwlCarouselComponent } from '../owl-carousel/owl-carousel/owl-carousel.component';

import { RecommendationService } from '../../../../services/system/recommendation.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { Session } from '@app/classes/user/session';
import { User } from '@app/classes/user/user';

declare var jQuery: any;

@Component({
  selector: 'app-recommendation',
  providers: [RecommendationService, AuthService],
  templateUrl: './recommendation.component.html',
})
export class RecommendationComponent implements OnInit {
  constructor(
    private _recommendationService: RecommendationService,
    private _authService: AuthService
  ) {}

  @Input() typeRecommmendation: any = '';

  session: User;
  recommendationsList: any = [];
  ngOnInit() {
    this.session = Session.getInstance().getUser();
    this.getRecommendations();
  }

  getRecommendations() {
    const data: any = {};
    data.key = this.session.key;
    data.id = this.session.id;
    data.type = this.typeRecommmendation;
    this._recommendationService.getRecommendations(data).then(
      (response: any) => {
        this.recommendationsList = response.data;
      },
      error => {
        console.error('No se puede obtener las recomendaciones');
      }
    );
  }
}
