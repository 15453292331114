<a
  *ngIf="sliceStart > 1"
  class="club-post-more-btn"
  (click)="showMoreComments()"
  >Ver ({{ sliceStart }}) comentarios anteriores</a
>
<app-comment
  *ngFor="let comment of commentList | slice: sliceStart:sliceEnd"
  [comment]="comment"
></app-comment>
