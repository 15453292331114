import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {Module} from '@app/classes/module/module';
import {ModuleService} from '@app/services/system/module.service';
import {catchError, map} from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AppAvailabilityGuard implements CanActivate {
  constructor(
    private _moduleClass: Module,
    private _moduleService: ModuleService,
    private _router: Router,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._moduleService.getModuleStatus('')
      .pipe(
        map( moduleAvailability => {
          if ( !environment.production ) { return true; }
          if (this._moduleClass.moduleIsAvailable(moduleAvailability)) {
            return true;
          } else {
            this._router.navigate(['/app-not-available']);
            return false;
          }
        }),
        catchError( (error) => {
          console.error('error', error);
          this._router.navigate(['/app-not-available']);
          return of(false);
        })
      );
  }
}
