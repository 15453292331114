/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../owl-carousel/owl-carousel/owl-carousel.component.ngfactory";
import * as i2 from "../owl-carousel/owl-carousel/owl-carousel.component";
import * as i3 from "@angular/common";
import * as i4 from "../ui-pack/flat-card/flat-card.component.ngfactory";
import * as i5 from "../ui-pack/flat-card/flat-card.component";
import * as i6 from "../../../../services/system/recommendation.service";
import * as i7 from "@angular/common/http";
import * as i8 from "../../../../services/auth/auth.service";
import * as i9 from "@angular/router";
import * as i10 from "../../../../data-services/session.service";
import * as i11 from "./recommendation.component";
var styles_RecommendationComponent = [];
var RenderType_RecommendationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RecommendationComponent, data: {} });
export { RenderType_RecommendationComponent as RenderType_RecommendationComponent };
function View_RecommendationComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "club-testimonial "]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.content; _ck(_v, 0, 0, currVal_0); }); }
function View_RecommendationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "owl-carousel", [], [[8, "className", 0]], null, null, i1.View_OwlCarouselComponent_0, i1.RenderType_OwlCarouselComponent)), i0.ɵdid(1, 4964352, null, 0, i2.OwlCarouselComponent, [i0.ElementRef], { options: [0, "options"] }, null), i0.ɵpod(2, { items: 0, loop: 1, autoplay: 2, autoplayTimeout: 3, dosts: 4 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_RecommendationComponent_2)), i0.ɵdid(4, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 2, 0, 1, true, true, 4000, true); _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.recommendationsList; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).defaultClass; _ck(_v, 0, 0, currVal_0); }); }
export function View_RecommendationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-flat-card", [], [[8, "className", 0]], null, null, i4.View_FlatCardComponent_0, i4.RenderType_FlatCardComponent)), i0.ɵdid(1, 49152, null, 0, i5.FlatCardComponent, [], null, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_RecommendationComponent_1)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.recommendationsList.length > 0); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
export function View_RecommendationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-recommendation", [], null, null, null, View_RecommendationComponent_0, RenderType_RecommendationComponent)), i0.ɵprd(512, null, i6.RecommendationService, i6.RecommendationService, [i7.HttpClient]), i0.ɵprd(512, null, i8.AuthService, i8.AuthService, [i7.HttpClient, i9.Router, i10.SessionService]), i0.ɵdid(3, 114688, null, 0, i11.RecommendationComponent, [i6.RecommendationService, i8.AuthService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var RecommendationComponentNgFactory = i0.ɵccf("app-recommendation", i11.RecommendationComponent, View_RecommendationComponent_Host_0, { typeRecommmendation: "typeRecommmendation" }, {}, []);
export { RecommendationComponentNgFactory as RecommendationComponentNgFactory };
