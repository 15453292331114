<div class="club-post-comment uk-clearfix fadeIn animated tw-flex">
  <nz-avatar
    [nzText]="comment.user.slice(0, 2).toUpperCase()"
    [ngStyle]="{ backgroundColor: stc(comment.user) }"
    nzSize="large"
    class="tw-mr-2"
  ></nz-avatar>
  <div class="club-post-comment-content">
    <div class="club-post-comment-header">
      <span
        class="uk-display-inlineblock uk-text-bold"
        *ngIf="!comment.user_info.profile_public"
      >
        {{ comment.user_info.user || 'Usuario' }}
      </span>
      <a
        class="uk-display-inlineblock uk-text-bold"
        routerLink="/user/{{ comment.user_info.user }}"
        *ngIf="comment.user_info.profile_public"
      >
        {{ comment.user_info.user }}
      </a>
      <span class="uk-text-muted uk-text-small">
        {{ comment.created_at | amLocale : 'es' | amTimeAgo }}
      </span>
    </div>
    <div
      [innerHTML]="comment.comment | nl2br | url2a"
      style="word-break: break-word"
    ></div>
  </div>
</div>
