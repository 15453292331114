import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { capitalizeFirst } from 'ramda-extension';
import RecurringPaymentConstants from '@app/modules/recurring-payments/recurring-payment-constants';
import { CreditCardValidators } from 'angular-cc-library';
import { UserRecurringPayment } from '@app/modules/recurring-payments/classes/user-recurring-payment';
import { Session } from '@app/classes/user/session';
import { all, test, length, pipe, lte, __, and, toString, prop, dissoc, propOr, ifElse, always, isEmpty, reject, isNil } from 'ramda';
import { Crypt } from 'hybrid-crypto-js';
const linkedAssociateNumberValidator = () => {
    return (control) => {
        const isValidNumber = test(/^[0-9]*$/);
        const hasValidLength = pipe(length, lte(__, 5));
        const allValuesAreValid = all(value => and(isValidNumber(value), hasValidLength(value)));
        if (allValuesAreValid(control.value)) {
            return null;
        }
        return { linkedAssociateNumbers: true };
    };
};
const ɵ0 = linkedAssociateNumberValidator;
const MAX_DEBIT_AMOUNT = 500;
const DEFAULT_DEBIT_AMOUNT = 200;
export class RecurringPaymentAffiliationFormComponent {
    constructor(_fb, _recurringPaymentStore, _recurringPaymentService, _roNotificationService, _routerService, _loadingMainService, _tokenService) {
        this._fb = _fb;
        this._recurringPaymentStore = _recurringPaymentStore;
        this._recurringPaymentService = _recurringPaymentService;
        this._roNotificationService = _roNotificationService;
        this._routerService = _routerService;
        this._loadingMainService = _loadingMainService;
        this._tokenService = _tokenService;
        this.cardTypes = RecurringPaymentConstants.paymentTypes;
        this.submitted = false;
        this.userEmail = '';
        this.pKeyCard = '';
        this.editCardNumberActive = false;
        this.inputCurrencyOptions = {
            prefix: 'S/ ',
            thousands: '.',
            decimal: ',',
            align: 'left',
            allowNegative: false,
            min: 0,
            max: MAX_DEBIT_AMOUNT
        };
    }
    ngOnInit() {
        const { userAffiliation } = this._recurringPaymentStore.recurrentPaymentState;
        this.currentUserRecurringPayment = userAffiliation;
        this.getPublicKey();
        this.userEmail = Session.getInstance().getUser().email;
        const getValue = (defaultValue, field) => ifElse(prop('isAffiliated'), propOr(defaultValue, field), always(defaultValue))(this.currentUserRecurringPayment);
        this.form = this._fb.group({
            notify_by_email: [getValue(false, 'notifyByEmail'), Validators.required],
            email: [getValue(null, 'email') || this.userEmail, [Validators.required, Validators.email]],
            linked_associate_numbers: [getValue([], 'linkedAssociateNumbers'), [Validators.required, linkedAssociateNumberValidator()]],
            ahead_fee: [getValue(false, 'aheadFee'), Validators.required],
            card_brand: [getValue(null, 'cardBrand'), Validators.required],
            max_debit_amount: [getValue(DEFAULT_DEBIT_AMOUNT, 'maxDebitAmount'), Validators.required],
            acceptTerms: [false, Validators.required],
            enc_card_number: [getValue('', 'cardNumber'), this.isUpdateAffiliation ? [] : [CreditCardValidators.validateCCNumber]],
            expiration_date: [getValue('', 'expirationDate'), [CreditCardValidators.validateExpDate]],
            card_holder: [getValue('', 'cardHolder'), [Validators.required, Validators.maxLength(50)]],
            dni_card_holder: [
                getValue('', 'dniCardHolder'),
                [Validators.required, Validators.maxLength(15), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]
            ],
        });
        this.currentDate = moment().format('YYYY-MM-DD hh:mm:ss');
        this.currentDateFriendly = capitalizeFirst(moment().locale('es').format('LLLL').toString());
    }
    get isUpdateAffiliation() {
        return this.currentUserRecurringPayment.isAffiliated;
    }
    getPublicKey() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { public_key: publicKey } = yield this._tokenService.generateKey().toPromise();
            this.pKeyCard = publicKey;
        });
    }
    encryptValue(value) {
        // const publicKey = pki.publicKeyFromPem(this.pKeyCard);
        // const simple_rsa = util.encode64(publicKey.encrypt(value));
        const crypt = new Crypt();
        return JSON.parse(crypt.encrypt(this.pKeyCard, value));
    }
    validateForm() {
        this.submitted = true;
        if (this.form.invalid) {
            jQuery('.club-socio-view').animate({
                scrollTop: 0
            }, 500);
            return;
        }
        this.currentUserRecurringPayment.isAffiliated ? this.updateAffiliation(this.form.value) : this.saveAffiliation(this.form.value);
    }
    saveAffiliation(dataAffiliation) {
        const newAffiliation = this.processAffiliationForm(dataAffiliation);
        this._loadingMainService.show();
        this._recurringPaymentService.saveUserRecurringPaymentInfo({ recurrent_payment: newAffiliation })
            .subscribe(response => this.handleSuccess(response), error => this.handleError());
    }
    updateAffiliation(dataAffiliation) {
        const newAffiliation = this.processAffiliationForm(dataAffiliation);
        this._loadingMainService.show();
        this._recurringPaymentService.updateUserRecurringPaymentInfo({ recurrent_payment: newAffiliation })
            .subscribe(response => this.handleSuccess(response), error => this.handleError());
    }
    processAffiliationForm(dataForm) {
        const affiliationData = Object.assign({}, dissoc('acceptTerms', dataForm), { linked_associate_numbers: pipe(prop('linked_associate_numbers'), toString)(dataForm), enc_card_number: pipe(prop('enc_card_number'), ifElse(isEmpty, always(null), val => this.encryptValue(val)))(dataForm), update_card_number: this.isUpdateAffiliation });
        return reject(isNil, affiliationData);
    }
    handleError() {
        this._loadingMainService.hide();
        this._roNotificationService.danger('Hubo un error procesando el formulario.');
    }
    handleSuccess(response) {
        this._loadingMainService.hide();
        this._recurringPaymentStore.setCurrentUserRecurringPayment(new UserRecurringPayment(response));
        this._roNotificationService.success('Solicitud enviada satisfactoriamente.');
        this._routerService.navigate(['/recurring-payments']);
    }
    changeUpdateCard() {
        this.editCardNumberActive = !this.editCardNumberActive;
        if (this.editCardNumberActive) {
            this.form.get('enc_card_number').setValidators([CreditCardValidators.validateCCNumber]);
        }
        else {
            this.form.get('enc_card_number').setValidators([]);
        }
        this.form.get('enc_card_number').updateValueAndValidity({ emitEvent: false });
    }
}
export { ɵ0 };
