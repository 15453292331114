import { Injectable } from '@angular/core';
import { Service } from '../Service.js';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '@app/helpers/app.settings';

@Injectable()
export class ScheduleService extends Service {
  constructor(private _http: HttpClient) {
    super();
  }

  getAvailables(academy, group) {
    return this._http.get(
      this.API_ENDPOINT_API_INTERSOCIO +
        'academies/' +
        academy +
        '/groups/' +
        group +
        '?t=' +
        new Date().getTime(),
      this.APP_REQUEST_OPTIONS_AUTH
    );
  }

  getSchedule(idSchedule) {
    return this._http.get(
      this.API_ENDPOINT_API_INTERSOCIO + 'academies/schedule/' + idSchedule,
      this.APP_REQUEST_OPTIONS_AUTH
    );
  }
}
