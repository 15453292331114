import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DeepLinkService } from '@app/modules/application/services/deep-link.service';
import { AppSettings } from '@app/helpers/app.settings';
/*import { SwService } from '@app/services/sw.service';*/
import { filter } from 'rxjs/operators';

declare var jQuery: any;

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <app-update-app-version
      *ngIf="showUpdateVersionSnack"
      (updateButtonClicked)="onUpdateAppClick()"
    ></app-update-app-version>
  `,
  providers: [DeepLinkService],
})
export class AppComponent {
  showUpdateVersionSnack = false;

  constructor(
    router: Router,
    private _deepLinkService: DeepLinkService
  ) /*    private swService: SwService*/
  {
    this._deepLinkService.deepLinking();
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        jQuery('.club-socio-view').scrollTop(0); // resetting scroll view nav
      }
    });

    (window as any).appInformation = AppSettings.APP_INFO;

    /* swService.checkForUpdates();
    swService.newVersionAvailable$
      .pipe(filter((value) => Boolean(value)))
      .subscribe(() => {
        this.showUpdateVersionSnack = true;
      });*/
  }

  onUpdateAppClick() {
    /*this.swService.updateApp();*/
  }
}
