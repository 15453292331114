import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-not-available',
  templateUrl: './app-not-available.component.html',
  styleUrls: ['./app-not-available.component.scss']
})
export class AppNotAvailableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
