import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
})
export class CommentListComponent implements OnInit {
  constructor() {}

  commentList: any[] = [];
  commentCount = 0;
  initialMaxcommentListhowed = 3;
  maxcommentListhowed = 5;
  sliceStart = 0;
  sliceEnd = 2;

  // intercept values
  @Input('comments')
  get comments(): any[] {
    return this.commentList;
  }
  set comments(value: any[]) {
    this.commentList = value;
    this.initComments();
  }

  @Input('count') // bug, it we need to detect array count to change
  get count(): number {
    return this.commentCount;
  }
  set count(value: number) {
    this.initComments();
    this.commentCount = value;
  }

  ngOnInit() {}

  initComments() {
    if (!this.commentList) { return; }
    if (this.commentList.length > this.initialMaxcommentListhowed) {
      this.sliceStart =
        this.commentList.length - this.initialMaxcommentListhowed;
    }
    else { this.sliceStart = 0; }
    this.sliceEnd = this.commentList.length;
  }

  showMoreComments() {
    if (this.sliceStart - this.maxcommentListhowed <= 0) {
      this.sliceStart = 0;
      return;
    }
    this.sliceStart -= this.maxcommentListhowed;
  }
}
