<app-flat-card>
  <div class="mb-1">
    <h3 class="uk-text-bold">Perfil Web Público</h3>
    <p class="m-0">
      Los datos aquí mostrados podrán ser visualizados por los demás socios del
      Club. Puede hacer pública su información sólo para los socio miembros de
      nuestra plataforma "InterSocio" y de esta manera poder interactuar con
      ellos.
    </p>
  </div>

  <div>
    <label class="switch">
      <input
        type="checkbox"
        [(ngModel)]="profileWebData.visible"
        (ngModelChange)="disabledProfile()"
      />
      <span class="slider round"></span>
    </label>
    <span class="uk-display-inlineblock uk-text-top" style="margin-left: 10px;"
    >Activar el mi Perfil Web</span
    >
  </div>

  <div *ngIf="profileWebData.visible" class="fadeIn animated">
    <hr />
    <div class="uk-grid uk-grid-small">
      <div class="uk-width-large-1-2 mb-1">
        <p>
          Recuerda que la información colocada será visualiza sólo por socios
          registrados en InterSocio.
        </p>
        <form class="uk-form uk-form-stacked">
          <div class="uk-form-row">
            <label class="uk-form-label" for="form-s-it">Localización</label>
            <div class="uk-form-controls">
              <input
                type="text"
                class="uk-width-1-1"
                [(ngModel)]="profileWebData.location"
                googlePlaces
                name="profileWebData.location"
              />
            </div>
          </div>
          <div class="uk-form-row">
            <label class="uk-form-label" for="form-s-it">Lugar de trabajo</label>
            <div class="uk-form-controls">
              <input
                type="text"
                class="uk-width-1-1"
                googlePlaces
                [(ngModel)]="profileWebData.work"
                name="work"
              />
            </div>
          </div>

          <div class="uk-form-row">
            <label class="uk-form-label" for="form-s-t">Algo acerca de mi</label>
            <div class="uk-form-controls">
            <textarea
              id="form-s-t"
              cols="30"
              rows="3"
              class="uk-width-1-1"
              [(ngModel)]="profileWebData.about"
              name="comment"
            ></textarea>
            </div>
          </div>

          <div class="uk-form-row">
            <div class="uk-form-controls">
              <label class="uk-form-label" for="form-s-it"
              >Tus redes sociales</label
              >
              <div class="uk-clearfix">
                <input
                  type="text"
                  class="uk-float-left"
                  style="width: calc( 100% - 40px);"
                  placeholder="https://www.facebook.com/"
                  [(ngModel)]="socialLink.url"
                  name="socialLink.url"
                />
                <button
                  class="uk-button uk-float-left"
                  style="margin-left: 5px;"
                  (click)="addSocialLink()"
                >
                  <i class="uk-icon uk-icon-plus"></i>
                </button>
              </div>
              <div class="mt-1 uk-grid uk-grid-small uk-grid-width-large-1-2">
                <div
                  *ngFor="let link of profileWebData.social"
                  style="position: relative; "
                >
                  <app-social-button
                    [link]="link.url"
                    class="uk-width-large-1-2"
                    style="margin-bottom: 5px;"
                  ></app-social-button>
                  <a
                    class="club-icon-button red remove-social"
                    (click)="removeSocialLink(link)"
                  >
                    <i class="uk-icon uk-icon-trash"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="uk-form-row">
            <div class="uk-form-controls">
              <button
                class="uk-button uk-button-large"
                type="submit"
                [disabled]="loadingProfileWebData"
                (click)="saveProfileWebData()"
              >
                <span *ngIf="loadingProfileWebData">Guardando...</span>
                <span *ngIf="!loadingProfileWebData">Guardar la información</span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="uk-width-large-1-2">
        <h4 class="uk-text-bold m-0">¿Por qué no personalizas tu perfil?</h4>
        <p class="m-0">
          Recuerda que puede personalizar tu perfil web, agregar una foto y un
          fondo de perfil
        </p>

        <div class="mt-1">
          <a
            routerLink="/user/{{ currentUser.username }}"
            class="club-hover-link"
          >
            <div><span>Visitar mi perfil ahora!</span></div>
            <img
              src="assets/img/ui/profile_web_example.jpg"
              class="uk-width-1-1"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</app-flat-card>
