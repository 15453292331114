/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inscription-history-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/inscription/inscription-history/inscription-history.component.ngfactory";
import * as i3 from "../../../../services/academy/academy.service";
import * as i4 from "@angular/common/http";
import * as i5 from "../../../../services/ui/loading-main.service";
import * as i6 from "../../components/inscription/inscription-history/inscription-history.component";
import * as i7 from "./inscription-history-page.component";
import * as i8 from "../../../shared/services/ro-notification.service";
var styles_InscriptionHistoryPageComponent = [i0.styles];
var RenderType_InscriptionHistoryPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InscriptionHistoryPageComponent, data: {} });
export { RenderType_InscriptionHistoryPageComponent as RenderType_InscriptionHistoryPageComponent };
export function View_InscriptionHistoryPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-inscription-history", [], null, null, null, i2.View_InscriptionHistoryComponent_0, i2.RenderType_InscriptionHistoryComponent)), i1.ɵprd(512, null, i3.AcademyService, i3.AcademyService, [i4.HttpClient]), i1.ɵprd(512, null, i5.LoadingMainService, i5.LoadingMainService, []), i1.ɵdid(3, 114688, null, 0, i6.InscriptionHistoryComponent, [i3.AcademyService, i5.LoadingMainService], { period: [0, "period"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentPeriod; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_InscriptionHistoryPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-inscription-history-page", [], null, null, null, View_InscriptionHistoryPageComponent_0, RenderType_InscriptionHistoryPageComponent)), i1.ɵdid(1, 114688, null, 0, i7.InscriptionHistoryPageComponent, [i3.AcademyService, i8.RoNotificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InscriptionHistoryPageComponentNgFactory = i1.ɵccf("app-inscription-history-page", i7.InscriptionHistoryPageComponent, View_InscriptionHistoryPageComponent_Host_0, {}, {}, []);
export { InscriptionHistoryPageComponentNgFactory as InscriptionHistoryPageComponentNgFactory };
