<a [routerLink]="['/user', profile.user]" *ngIf="profile.profile_public">
  <div class="club-profile-widget-page">
    <div
      class="header"
      [ngStyle]="{ 'background-image': 'url(' + profile.background + ')' }"
    >
      <div class="profile-img">
        <img [src]="profile.image" />
      </div>
    </div>
    <div class="content">
      <h4>{{ profile.name }}</h4>
      <span class="uk-text-muted uk-text-small">{{ profile.user }}</span>
    </div>
  </div>
</a>

<div class="club-profile-widget-page" *ngIf="!profile.profile_public">
  <div
    class="header"
    [ngStyle]="{ 'background-image': 'url(' + profile.background + ')' }"
  >
    <div class="profile-img">
      <img [src]="profile.image" />
    </div>
  </div>
  <div class="content">
    <h4>{{ profile.name }}</h4>
    <span class="uk-text-muted uk-text-small">{{ profile.user }}</span>
  </div>
</div>
