import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/services/auth/auth.service';
import { RoNotificationService } from '@app/modules/shared/services/ro-notification.service';
import { Router } from '@angular/router';
import { SessionService } from '@app/data-services/session.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  @Output() registerClick: EventEmitter<any> = new EventEmitter<any>();
  validateForm!: FormGroup;
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private _authService: AuthService,
    private _roNotification: RoNotificationService,
    private _routerService: Router,
    private sessionService: SessionService
  ) {}

  ngOnInit() {
    this.validateForm = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
  }

  submitForm() {
    // tslint:disable-next-line:forin
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.invalid) {
      return;
    }

    this.login({
      login: this.validateForm.value.userName,
      password: this.validateForm.value.password,
    });
  }

  login(dataLogin: { login: any; password: any }) {
    this.isLoading = true;
    this._authService.login2(dataLogin).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.sessionService.create({
          ...response.body,
          authorization: response.headers.get('authorization'),
        });
        this._routerService.navigate(['/']);
      },
      (error) => {
        this._roNotification.danger('Usuario o Contraseña Incorrectos');
        this.isLoading = false;
      }
    );
  }
}
