/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/components/ui-pack/layout/module-layout-navigation/module-layout-navigation.component.ngfactory";
import * as i2 from "../../../shared/components/ui-pack/layout/module-layout-navigation/module-layout-navigation.component";
import * as i3 from "../../../shared/components/ui-pack/flat-card/flat-card.component.ngfactory";
import * as i4 from "../../../shared/components/ui-pack/flat-card/flat-card.component";
import * as i5 from "../../../shared/components/ui-pack/left-navigation/left-navigation.component.ngfactory";
import * as i6 from "../../../shared/components/ui-pack/left-navigation/left-navigation.component";
import * as i7 from "../profile-web-setting/profile-web-setting.component.ngfactory";
import * as i8 from "../../../../services/social/profile.service";
import * as i9 from "@angular/common/http";
import * as i10 from "../profile-web-setting/profile-web-setting.component";
import * as i11 from "./profile-setting-page.component";
import * as i12 from "@angular/router";
var styles_ProfileSettingPageComponent = [];
var RenderType_ProfileSettingPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProfileSettingPageComponent, data: {} });
export { RenderType_ProfileSettingPageComponent as RenderType_ProfileSettingPageComponent };
export function View_ProfileSettingPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "div", [["class", "club-socio-page"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 14, "app-module-layout-navigation", [], null, null, null, i1.View_ModuleLayoutNavigationComponent_0, i1.RenderType_ModuleLayoutNavigationComponent)), i0.ɵdid(2, 114688, null, 0, i2.ModuleLayoutNavigationComponent, [], null, null), (_l()(), i0.ɵeld(3, 0, null, 0, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 7, "app-flat-card", [], [[8, "className", 0]], null, null, i3.View_FlatCardComponent_0, i3.RenderType_FlatCardComponent)), i0.ɵdid(5, 49152, null, 0, i4.FlatCardComponent, [], null, null), (_l()(), i0.ɵeld(6, 0, null, 0, 1, "h2", [["class", "!tw-mb-4"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Configuraci\u00F3n de Perfil"])), (_l()(), i0.ɵeld(8, 0, null, 0, 3, "app-left-navigation", [], null, null, null, i5.View_LeftNavigationComponent_0, i5.RenderType_LeftNavigationComponent)), i0.ɵdid(9, 114688, null, 0, i6.LeftNavigationComponent, [], { navigationItems: [0, "navigationItems"] }, null), i0.ɵpod(10, { name: 0, path: 1 }), i0.ɵpad(11, 1), (_l()(), i0.ɵeld(12, 0, null, 1, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 2, "app-profile-web-setting", [], null, null, null, i7.View_ProfileWebSettingComponent_0, i7.RenderType_ProfileWebSettingComponent)), i0.ɵprd(512, null, i8.ProfileService, i8.ProfileService, [i9.HttpClient]), i0.ɵdid(15, 114688, null, 0, i10.ProfileWebSettingComponent, [i8.ProfileService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); var currVal_1 = _ck(_v, 11, 0, _ck(_v, 10, 0, "Configurar mi perfil web", "/profile/setting")); _ck(_v, 9, 0, currVal_1); _ck(_v, 15, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 5).classes; _ck(_v, 4, 0, currVal_0); }); }
export function View_ProfileSettingPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-profile-setting-page", [], null, null, null, View_ProfileSettingPageComponent_0, RenderType_ProfileSettingPageComponent)), i0.ɵdid(1, 114688, null, 0, i11.ProfileSettingPageComponent, [i12.ActivatedRoute, i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileSettingPageComponentNgFactory = i0.ɵccf("app-profile-setting-page", i11.ProfileSettingPageComponent, View_ProfileSettingPageComponent_Host_0, {}, {}, []);
export { ProfileSettingPageComponentNgFactory as ProfileSettingPageComponentNgFactory };
