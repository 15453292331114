/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./comment-btn.component";
var styles_CommentBtnComponent = [];
var RenderType_CommentBtnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CommentBtnComponent, data: {} });
export { RenderType_CommentBtnComponent as RenderType_CommentBtnComponent };
function View_CommentBtnComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.commentsCount; _ck(_v, 1, 0, currVal_0); }); }
export function View_CommentBtnComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "club-post-comment-wrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "club-post-comment-icon"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommentBtnComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.commentsCount > 0); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_CommentBtnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-comment-btn", [], null, null, null, View_CommentBtnComponent_0, RenderType_CommentBtnComponent)), i0.ɵdid(1, 114688, null, 0, i2.CommentBtnComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CommentBtnComponentNgFactory = i0.ɵccf("app-comment-btn", i2.CommentBtnComponent, View_CommentBtnComponent_Host_0, { commentsCount: "commentsCount" }, {}, []);
export { CommentBtnComponentNgFactory as CommentBtnComponentNgFactory };
