/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inscription-step-title.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./inscription-step-title.component";
var styles_InscriptionStepTitleComponent = [i0.styles];
var RenderType_InscriptionStepTitleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InscriptionStepTitleComponent, data: {} });
export { RenderType_InscriptionStepTitleComponent as RenderType_InscriptionStepTitleComponent };
export function View_InscriptionStepTitleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "!tw-mb-4 tw-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "step tw-text-red-500"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [["class", "tw-inline-block !tw-text-red-500 tw-ml-2 !tw-mt-0 "]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.step; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 4, 0, currVal_1); }); }
export function View_InscriptionStepTitleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-inscription-step-title", [], null, null, null, View_InscriptionStepTitleComponent_0, RenderType_InscriptionStepTitleComponent)), i1.ɵdid(1, 114688, null, 0, i2.InscriptionStepTitleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InscriptionStepTitleComponentNgFactory = i1.ɵccf("app-inscription-step-title", i2.InscriptionStepTitleComponent, View_InscriptionStepTitleComponent_Host_0, { step: "step", title: "title" }, {}, []);
export { InscriptionStepTitleComponentNgFactory as InscriptionStepTitleComponentNgFactory };
