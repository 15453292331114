<div class="uk-grid uk-grid-small">
  <div class="uk-width-large-2-3">
    <app-flat-card>
      <div class="club-payment-steps">
        <div
          class="club-payment-step"
          [ngClass]="{
            solved: steps[idx].solved,
            active: currentStep == steps[idx]
          }"
          *ngFor="let step of steps; let idx = index"
        >
          <div class="uk-clearfix content">
            <div
              class="uk-float-left uk-text-center"
              style="padding-top: 10px; padding-right: 10px"
            >
              <i class="uk-icon uk-icon-{{ step.icon }} unsolved-icon"></i>
              <i class="uk-icon uk-icon-check-circle green success-icon"></i>
            </div>
            <div class="uk-float-left">
              <h3 class="m-0">
                <b>{{ step.title }}</b>
              </h3>
              <small>{{ step.subTitle }}</small>
            </div>
          </div>
        </div>
      </div>
    </app-flat-card>
    <app-flat-card>
      <div class="tw-flex tw-justify-between">
        <div>
          <button
            nz-button
            nzType="primary"
            (click)="goBackStep()"
            *ngIf="steps[currentStepIndex - 1]"
          >
            <i class="uk-icon uk-icon-arrow-left"></i>&nbsp;&nbsp; Regresar
          </button>
        </div>

        <div>
          <button
            nz-button
            nzType="primary"
            (click)="goNextStep()"
            *ngIf="steps[currentStepIndex + 1]"
            [disabled]="currentStep.disabled"
          >
            Siguiente Paso&nbsp;&nbsp;
            <i class="uk-icon uk-icon-arrow-right"></i>
          </button>
        </div>
      </div>
      <hr />
      <div class="tw-my-4">
        <!-- first step -->
        <app-payment-list *ngIf="currentStep === steps[0]"></app-payment-list>

        <!-- second step -->
        <ng-container *ngIf="total$ | async as total">
          <app-payment-methods
            *ngIf="currentStep === steps[1]"
            [onAcceptTerms]="enableStep"
            [totalPayment]="+total"
          ></app-payment-methods>
        </ng-container>

        <app-payment-visa *ngIf="currentStep === steps[2]"></app-payment-visa>
      </div>
      <hr />
      <div class="tw-flex tw-justify-between">
        <div>
          <button
            nz-button
            nzType="primary"
            (click)="goBackStep()"
            *ngIf="steps[currentStepIndex - 1]"
          >
            <i class="uk-icon uk-icon-arrow-left"></i>&nbsp;&nbsp; Regresar
          </button>
        </div>

        <button
          nz-button
          nzType="primary"
          (click)="goNextStep()"
          *ngIf="steps[currentStepIndex + 1]"
          [disabled]="currentStep.disabled"
        >
          Siguiente Paso&nbsp;&nbsp;
          <i class="uk-icon uk-icon-arrow-right"></i>
        </button>
      </div>
    </app-flat-card>
  </div>

  <div class="uk-width-large-1-3">
    <app-flat-card>
      <div class="mb-1">
        <a class="uk-button" routerLink="/state/debts">
          Ir al estado de deudas
        </a>
      </div>
      <app-payment-recomendations></app-payment-recomendations>
    </app-flat-card>
  </div>
</div>
