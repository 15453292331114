/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./social-banners.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./social-banners.component";
var styles_SocialBannersComponent = [i0.styles];
var RenderType_SocialBannersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SocialBannersComponent, data: {} });
export { RenderType_SocialBannersComponent as RenderType_SocialBannersComponent };
function View_SocialBannersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "tw-cursor-pointer tw-rounded tw-overflow-hidden tw-mb-4 tw-shadow tw-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "intersocio"], ["class", "tw-w-full"]], [[8, "src", 4]], null, null, null, null))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.linkUrl; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.imageUrl; _ck(_v, 2, 0, currVal_1); }); }
function View_SocialBannersComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "tw-cursor-pointer tw-rounded tw-overflow-hidden tw-mb-4 tw-shadow tw-block"]], [[8, "href", 4], [8, "target", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "intersocio"], ["class", "tw-w-full"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.linkUrl; var currVal_1 = _v.parent.context.$implicit.target; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.context.$implicit.imageUrl; _ck(_v, 1, 0, currVal_2); }); }
function View_SocialBannersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SocialBannersComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SocialBannersComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.isExternal; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.isExternal; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_SocialBannersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SocialBannersComponent_1)), i1.ɵdid(1, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.banners; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SocialBannersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-social-banners", [], null, null, null, View_SocialBannersComponent_0, RenderType_SocialBannersComponent)), i1.ɵdid(1, 114688, null, 0, i4.SocialBannersComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SocialBannersComponentNgFactory = i1.ɵccf("app-social-banners", i4.SocialBannersComponent, View_SocialBannersComponent_Host_0, {}, {}, []);
export { SocialBannersComponentNgFactory as SocialBannersComponentNgFactory };
