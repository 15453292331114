
<div *ngIf="verificationErrors.state || verificationErrors.period">
  <app-message
    icon="meh"
    message="Usted no puede acceder a la inscripciones"
    subMessage="Estimado socio hemos podido comprobar que no cumple con los siguiente requisitos para realizar la inscripción: {{
    verificationRequiredStateMsg
  }} {{ verificationRequiredState | memberState }} {{
    verificationRequiredPeriodMsg
  }} <br> <small>Recuerda que si esta información no es correcta puede recargar la página para actualizar.</small><br>En caso de tener algún inconveniente puede comunicarse con <b>webmaster@clubinter.org.pe</b>"
  >
  </app-message>
</div>
<app-inscription-form
  [period]="currentPeriod"
  *ngIf="!verificationErrors.state && !verificationErrors.period"
></app-inscription-form>
