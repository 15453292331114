import { Component, OnInit, Input } from '@angular/core';
import { PostService } from '@app/services/social/post.service';

import { AuthService } from '@app/services/auth/auth.service';
import { User } from '@app/classes/user/user';
import { Session } from '@app/classes/user/session';
import { RoNotificationService } from '@app/modules/shared/services/ro-notification.service';
import { Post } from '@app/modules/social/social';
import { parseUserInfo } from '@app/modules/social/social.utils';
import { finalize } from 'rxjs/operators';

declare var jQuery: any;
declare var UIkit: any;

@Component({
  selector: 'app-post-list',
  providers: [PostService],
  templateUrl: './post-list.component.html',
})
export class PostListComponent implements OnInit {
  constructor(
    private _postService: PostService,
    private _authService: AuthService,
    private _roNotification: RoNotificationService
  ) {}

  sessionUser: User;
  @Input() ownUser: any = undefined;
  @Input() type: any = 2;
  @Input() grid = '';
  @Input() wrap = 'normal';

  postStart = 0;
  postList: Post[] = [];
  loadingPosts = false;

  currentPost: any = {};

  ngOnInit() {
    this.sessionUser = Session.getInstance().getUser();
    this.getLastPosts();
  }

  getLastPosts() {
    const filter: any = {};
    filter.user = this.sessionUser.user;
    filter.key = this.sessionUser.key;
    filter.id = this.sessionUser.id;
    filter.start = this.postStart;
    filter.order_comment = 'ASC';
    filter.type = this.type;

    if (this.ownUser !== undefined) {
      filter.filter_user = this.ownUser;
    }

    this.loadingPosts = true;
    this._postService
      .getPost(filter)
      .pipe(finalize(() => (this.loadingPosts = false)))
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.postList = [...this.postList, ...response.data];
          }
        },
        (error) => {
          console.error('No se pudo obtener los post.', error);
          this._roNotification.danger('No se pudieron cargar los post');
        }
      );
  }

  getNewFeeds() {
    this.postStart = this.postStart + 10;
    this.getLastPosts();
  }

  insertLastPost(post) {
    this.postList.unshift(post);
  }

  onLastPost = (newPost) => {
    this.insertLastPost(newPost);
  };

  // public
  reset(user) {
    this.ownUser = user;
    this.postList = [];
    this.postStart = 0;
    this.getLastPosts();
  }
  showImageClick = (currentPost) => {
    this.currentPost = {};
    this.currentPost = currentPost;
    const modal = UIkit.modal('#post-image-modal');
    modal.show();
  };
}
