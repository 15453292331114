/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./profile-update-image.component";
import * as i3 from "../../../../../services/social/profile.service";
import * as i4 from "../../../../../services/auth/auth.service";
var styles_ProfileUpdateImageComponent = [];
var RenderType_ProfileUpdateImageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProfileUpdateImageComponent, data: {} });
export { RenderType_ProfileUpdateImageComponent as RenderType_ProfileUpdateImageComponent };
function View_ProfileUpdateImageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "profile-media-edit profile-image-edit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openFileSelection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "span", [["class", "uk-text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "uk-icon uk-icon-picture-o"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Actualizar "])), (_l()(), i0.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" foto de Perfil"])), (_l()(), i0.ɵeld(7, 0, null, null, 0, "input", [["style", "display:none;"], ["type", "file"]], [[1, "id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.uploadImage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.inputId + _co._castToString(_co.type)); _ck(_v, 7, 0, currVal_0); }); }
function View_ProfileUpdateImageComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "profile-media-edit profile-background-edit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openFileSelection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [["class", "uk-text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "uk-icon uk-icon-picture-o"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Actualizar fondo de Perfil "])), (_l()(), i0.ɵeld(4, 0, null, null, 0, "input", [["style", "display:none;"], ["type", "file"]], [[1, "id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.uploadImage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.inputId + _co._castToString(_co.type)); _ck(_v, 4, 0, currVal_0); }); }
function View_ProfileUpdateImageComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Actualizando..."]))], null, null); }
function View_ProfileUpdateImageComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Actualizar"]))], null, null); }
export function View_ProfileUpdateImageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileUpdateImageComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileUpdateImageComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 10, "div", [["class", "uk-modal no-select"]], [[1, "id", 0]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 9, "div", [["class", "uk-modal-dialog uk-modal-dialog-medium"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "a", [["class", "uk-modal-close uk-close"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [["class", "crop-area"], ["style", "width:100%; height: 305px;"]], [[1, "id", 0]], null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 6, "div", [["class", "uk-clearfix"], ["style", "padding-top: 35px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 4, "button", [["class", "uk-button uk-button-large uk-float-right"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectImage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileUpdateImageComponent_3)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileUpdateImageComponent_4)), i0.ɵdid(14, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.ownProfile && (_co.type == 1)); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.ownProfile && (_co.type == 2)); _ck(_v, 3, 0, currVal_1); var currVal_5 = _co.loadingUpdateImage; _ck(_v, 12, 0, currVal_5); var currVal_6 = !_co.loadingUpdateImage; _ck(_v, 14, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.modalId + _co._castToString(_co.type)); _ck(_v, 4, 0, currVal_2); var currVal_3 = (_co.cropId + _co._castToString(_co.type)); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.loadingUpdateImage; _ck(_v, 10, 0, currVal_4); }); }
export function View_ProfileUpdateImageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-profile-update-image", [], null, null, null, View_ProfileUpdateImageComponent_0, RenderType_ProfileUpdateImageComponent)), i0.ɵdid(1, 4308992, null, 0, i2.ProfileUpdateImageComponent, [i0.ElementRef, i3.ProfileService, i4.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileUpdateImageComponentNgFactory = i0.ɵccf("app-profile-update-image", i2.ProfileUpdateImageComponent, View_ProfileUpdateImageComponent_Host_0, { type: "type", ownProfile: "ownProfile" }, {}, []);
export { ProfileUpdateImageComponentNgFactory as ProfileUpdateImageComponentNgFactory };
