import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent {
  @Input() userSession: AppUser | Partial<AppUser> = {};
  @Input() menuItems = [];
  @Output() closeSession: EventEmitter<any> = new EventEmitter<any>();
  @Output() navClicked: EventEmitter<any> = new EventEmitter<any>();
}
