<div class="club-profile-widget uk-clearfix" *ngIf="type == 1">
  <img *ngIf="userInfo.image" [src]="userInfo.image" class="profile-img" />
  <div class="club-profile-widget-content">
    <div class="club-profile-widget-header">
      <!-- profile link -->
      <span
        class="uk-display-inlineblock uk-text-bold"
        *ngIf="!userInfo.profile_public"
      >
        {{ userInfo.user }}
      </span>
      <a
        class="uk-display-inlineblock uk-text-bold"
        routerLink="/user/{{ userInfo.user }}"
        *ngIf="userInfo.profile_public"
      >
        {{ userInfo.user }}
      </a>
      <!-- end profile link -->

      <span *ngIf="mutedNext" class="uk-text-muted uk-text-small">
        {{ mutedNext | amLocale: 'es' | amTimeAgo }}
      </span>
    </div>
    <div class="" *ngIf="text">
      {{ text }}
    </div>
  </div>
</div>

<div
  *ngIf="type == 2"
  class="club-profile-widget club-profile-widget-horizontal  uk-clearfix"
  [routerLink]="profileLink"
  [ngClass]="{ link: link, 'club-socio-panel': panelStyle, hover: hover }"
>
  <img [src]="userInfo.image" class="profile-img" />
  <div class="club-profile-widget-content">
    <p class="m-0 uk-text-truncate">{{ userInfo.name }}</p>
    <b class="uk-text-muted">{{ userInfo.user }}</b>
  </div>
</div>
