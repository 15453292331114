import { Pipe, PipeTransform } from '@angular/core';

const data = [
  { code: 'SE', description: 'SENIOR' },
  { code: 'VI', description: 'VITALICIO' },
  { code: 'FA', description: 'FAMILIAR' },
  { code: 'JU', description: 'JUNIOR' },
  { code: 'TR', description: 'TRANSEUNTE' },
];

@Pipe({
  name: 'memberType',
})
export class MemberTypePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    for (let i = 0; i < data.length; i++)
      if (value == data[i].code) return data[i].description;
    return null;
  }
}
