<app-post-form [onSuccess]="onLastPost"></app-post-form>

<div>
    <ng-content select="[focusedContent]"></ng-content>
</div>

<!-- post list -->
<div
  infinite-scroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="300"
  [scrollWindow]="false"
  [infiniteScrollContainer]="'.club-socio-view'"
  [fromRoot]="true"
  [infiniteScrollDisabled]="loadingPosts"
  (scrolled)="getNewFeeds()"
  class="fadeIn animated"
></div>

<app-post-list [type]="[1, 2]"></app-post-list>
