import { Component, Input } from '@angular/core';
import stc from 'string-to-color';

@Component({
  selector: 'app-header-user-button',
  templateUrl: './header-user-button.component.html',
})
export class HeaderUserButtonComponent {
  @Input() user: AppUser | Partial<AppUser> = {};

  public stc = stc;
}
