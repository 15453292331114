<div
  class="profile-media-edit profile-image-edit"
  *ngIf="ownProfile && type == 1"
  (click)="openFileSelection()"
>
  <span class="uk-text-center">
    <i class="uk-icon uk-icon-picture-o"> </i><br />
    Actualizar <br />
    foto de Perfil</span
  >
  <input
    type="file"
    [attr.id]="inputId + _castToString(type)"
    style="display:none;"
    (change)="uploadImage($event)"
  />
</div>

<div
  class="profile-media-edit profile-background-edit"
  *ngIf="ownProfile && type == 2"
  (click)="openFileSelection()"
>
  <span class="uk-text-center">
    <i class="uk-icon uk-icon-picture-o"> </i> Actualizar fondo de Perfil
  </span>
  <input
    type="file"
    [attr.id]="inputId + _castToString(type)"
    style="display:none;"
    (change)="uploadImage($event)"
  />
</div>

<!-- modal -->
<div [attr.id]="modalId + _castToString(type)" class="uk-modal no-select">
  <div class="uk-modal-dialog uk-modal-dialog-medium">
    <a class="uk-modal-close uk-close"></a>
    <div
      [attr.id]="cropId + _castToString(type)"
      class="crop-area"
      style="width:100%; height: 305px;"
    ></div>
    <div style="padding-top: 35px;" class="uk-clearfix">
      <hr />
      <button
        class="uk-button uk-button-large uk-float-right"
        (click)="selectImage()"
        [disabled]="loadingUpdateImage"
      >
        <span *ngIf="loadingUpdateImage">Actualizando...</span>
        <span *ngIf="!loadingUpdateImage">Actualizar</span>
      </button>
    </div>
  </div>
</div>
