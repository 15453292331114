<app-flat-card>

  <div class="
  tw-flex tw-flex-wrap tw-flex-col-reverse
  md:tw-flex-row
">
    <div class="tw-w-full md:tw-w-2/6 tw-p-4 lg:tw-mb-0 tw-flex tw-items-center">
      <img src="assets/modules/recurring-payments/images/calendar-2.svg">
    </div>
    <div class="tw-w-full md:tw-w-4/6 tw-p-4 tw-flex tw-items-center">
      <div>
        <h3 class="!tw-mb-1">¿Qué son los Pagos Recurrentes?</h3>
        <p>Con el débito automático le permite estar al día en sus cuotas y deudas automáticamente, sin preocuparse por fechas límite o perder tiempo haciendo fila.</p>
        <button
          *ngIf="userRecurringPayments.isDisaffiliated || userRecurringPayments.isRejected"
          class="tw-block tw-mx-auto uk-button uk-button-large tw-uppercase"
          routerLink="/recurring-payments/affiliation"
        >
          ¡Afiliarme Ahora!
        </button>
      </div>
    </div>
  </div>
</app-flat-card>
