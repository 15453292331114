import { Injectable } from '@angular/core';

declare var UIkit: any;
const GLOBAL_POS = 'bottom-center';

@Injectable({
  providedIn: 'root'
})
export class RoNotificationService {

  constructor(
  ) { }

  warning(message: string) {
    UIkit.notify(
      message,
      { status: 'warning', pos: GLOBAL_POS }
    );
  }

  public error({ error }) {
    this.danger(`${error.error || error.message}`);
  }

  public danger(message: string, title?: string) {
    const titleHtml = title ? `<b>${title}</b>` : '';
    const messageHtml = `${titleHtml}<p class="m-0">${message}</p>`;
    UIkit.notify(
      messageHtml,
      { status: 'danger', pos: GLOBAL_POS }
    );
  }

  public success(message: string) {
    UIkit.notify(
      message,
      { status: 'success', pos: GLOBAL_POS }
    );
  }

  public modalAlert(content: string) {
    UIkit.modal.alert(
      content,
      { center: true }
    );
  }


  public modalConfirm(question: string, onSuccess: () => void) {
    UIkit.modal.confirm(
      question,
      onSuccess,
      { center: true }
    );
  }
}
