import { Directive, Input, ElementRef } from '@angular/core';

declare var jQuery: any;

@Directive({
  selector: '[appTooltip]',
  host: {
    '(mouseover)': 'onMouseEnter($event)',
    '(mouseout)': 'onMouseLeave($event)',
  },
})
export class TootipDirective {
  @Input('appTooltip') content;
  @Input('appTooltipLike') likesCount = 0;
  htmlElement: any = {};
  stringContent: string = '';

  constructor(private _element: ElementRef) {
    jQuery(_element.nativeElement).css({ cursor: 'pointer' });

    if (jQuery('#ro-tooltip').length < 1) {
      jQuery('body').append(
        jQuery('<div />', {
          id: 'ro-tooltip',
          class: 'club-tooltip',
          css: {
            display: 'none',
          },
        })
      );

      // hide on scroll
      // TODO: improve it
      jQuery('.club-socio-view').scroll(() => {
        this.htmlElement.css({ display: 'none' });
        this.htmlElement.html('');
      });
    }

    this.htmlElement = jQuery('#ro-tooltip');
  }

  onMouseEnter(eve) {
    // driawing tootltip
    var position = jQuery(this._element.nativeElement).offset();
    this._convertHtmlLikes(this.content);
    this.htmlElement.html(this.stringContent);
    let midWidthTooltip = this.htmlElement.outerWidth() / 2;
    let fullHeightTooltip = this.htmlElement.outerHeight();
    let midWidthElement = jQuery(this._element.nativeElement).outerWidth() / 2;

    let tootltipPosTop;
    let tootltipClass;

    if (position.top - fullHeightTooltip < 50) {
      tootltipPosTop =
        position.top + jQuery(this._element.nativeElement).outerHeight() + 10;
      tootltipClass = 'bottom';
    } else {
      tootltipPosTop = position.top - (fullHeightTooltip + 12);
      tootltipClass = 'top';
    }

    this.htmlElement.css({
      display: 'block',
      top: tootltipPosTop,
      left: position.left + midWidthElement - midWidthTooltip,
    });

    this.htmlElement.removeClass('top').removeClass('bottom');
    this.htmlElement.addClass(tootltipClass);
  }

  onMouseLeave(eve) {
    this.htmlElement.css({ display: 'none' });
    this.htmlElement.html('');
  }

  _convertHtmlLikes(data) {
    if (typeof data == typeof []) {
      let html = '';
      for (let i = 0; i < data.length; i++) html += data[i].user + '<br>';
      if (this.likesCount > data.length)
        html += 'y ' + (this.likesCount - data.length) + ' más ...';
      this.stringContent = html;
    }
  }
}
