<app-flat-card>
  <div class="tw-py-4 tw-bg-gray-200 tw-rounded-tl tw-rounded-tr">
    <h3 class="tw-text-center">{{ isUpdateAffiliation ? 'Editar Datos de Afiliación' : 'Formulario de Afiliación' }}</h3>
  </div>
  <hr class="!tw-m-0">
  <div class="tw-p-4 lg:tw-p-8">
    <form class="uk-form " [formGroup]="form" novalidate >
      <div class="tw-flex tw-flex-wrap tw--m-2 tw-items-baseline">
        <div class="tw-mb-4 tw-w-full md:tw-w-1/2 tw-px-2">
          <label class="uk-form-label tw-block tw-mb-2 tw-uppercase">Correo de Contacto</label>
          <input formControlName="email" type="text" class="tw-w-full">
        </div>
        <div class="tw-mb-4 tw-w-full md:tw-w-1/2 tw-px-2">
          <label class="uk-form-label tw-block tw-mb-2 tw-uppercase">Fecha de Solicitud</label>
          <p class="tw-m-0 tw-leading-none">{{ currentDateFriendly }}, Arequipa - Perú</p>
        </div>
      </div>
      <p class="tw-font-bold tw-py-4">Señores: CLUB INTERNACIONAL AREQUIPA: Por medio de la presente autorizo efectuar el cargo mensual de mi cuota social y otros conceptos que este adeudando, según las condiciones descritas a continuación:</p>
      <app-form-control-errors class="tw-block tw-mb-6 uk-form-controls" [control]="form.get('card_brand')" [submitted]="submitted">
        <label class="uk-form-label tw-block tw-mb-2">TIPO DE TARJETA</label>
        <label *ngFor="let cardType of cardTypes" class="ro-input-radio tw-inline-block tw-mr-6 tw-cursor-pointer">
          <input type="radio" [value]="cardType.value" formControlName="card_brand">
          <img [src]="cardType.icon" [alt]="cardType.name" class="tw-w-16">
          <span class="checkmark"></span>
        </label>
      </app-form-control-errors>
      <div class="tw-flex tw-flex-wrap tw--mx-2 tw-py-4">
        <app-form-control-errors
          class="tw-mb-4 uk-form-controls tw-w-full md:tw-w-3/12 sm:tw-w-1/2 tw-px-2"
          [control]="form.get('enc_card_number')" [submitted]="submitted"
        >
          <label class="uk-form-label tw-block tw-mb-2 tw-truncate">NÚMERO DE TARJETA</label>
          <ng-container *ngIf="!isUpdateAffiliation">
            <div class="tw-relative">
              <input id="cc-number" formControlName="enc_card_number" type="tel" autocomplete="cc-number" ccNumber class="tw-w-full">
            </div>
          </ng-container>
          <ng-container *ngIf="isUpdateAffiliation">
            <div *ngIf="editCardNumberActive" class="tw-relative">
              <input id="cc-number" formControlName="enc_card_number" type="tel" autocomplete="cc-number" ccNumber class="tw-w-full">
              <div class="tw-absolute tw-right-0 tw-p-1 tw-top-0">
                <a class="tw-p-inherit" (click)="changeUpdateCard()"><i class="uk-icon uk-icon-close"></i></a>
              </div>
            </div>
            <div
              *ngIf="!editCardNumberActive"
              class="tw-border tw-border-solid tw-border-gray-300 tw-rounded tw-px-3 tw-py-1 tw-flex tw-justify-between">
              {{ currentUserRecurringPayment.maskedCardNumber }}
              <a (click)="changeUpdateCard()"><i class="uk-icon uk-icon-edit"></i></a>
            </div>
          </ng-container>
        </app-form-control-errors >
        <app-form-control-errors
          class="tw-mb-4 uk-form-controls tw-w-full md:tw-w-2/12 sm:tw-w-1/2 tw-px-2"
          [control]="form.get('expiration_date')" [submitted]="submitted"
        >
          <label class="uk-form-label tw-block tw-mb-2 tw-truncate">FECHA DE EXPIRACIÓN</label>
          <input id="cc-exp-date" formControlName="expiration_date" type="tel" autocomplete="cc-exp" ccExp class="tw-w-full" placeholder="MM/YYYY">
        </app-form-control-errors >
        <app-form-control-errors
          class="tw-mb-4 uk-form-controls tw-w-full md:tw-w-4/12 sm:tw-w-1/2 tw-px-2"
          [control]="form.get('card_holder')" [submitted]="submitted"
        >
          <label class="uk-form-label tw-block tw-mb-2 tw-truncate">NOMBRE DEL TITULAR DE LA TARJETA</label>
          <input type="text" formControlName="card_holder" class="tw-w-full" uppercaseOnly>
        </app-form-control-errors >
        <app-form-control-errors
          class="tw-mb-4 uk-form-controls tw-w-full md:tw-w-3/12 sm:tw-w-1/2 tw-px-2"
          [control]="form.get('dni_card_holder')" [submitted]="submitted"
        >
          <label class="uk-form-label tw-block tw-mb-2 tw-truncate">DNI DEL TITULAR DE LA TARJETA</label>
          <input type="text" autocomplete="cc-number" formControlName="dni_card_holder" class="tw-w-full" numbersOnly maxlength="8">
        </app-form-control-errors >
      </div>
      <div class="tw-flex tw-flex-wrap tw--mx-2 tw-py-4">
        <div class="tw-w-full md:tw-w-2/3 tw-px-2">
          <app-form-control-errors
            class="tw-py-4 tw-block" [control]="form.get('linked_associate_numbers')" [submitted]="submitted"
            [customErrorMessage]="{linkedAssociateNumbers: 'Los números de socio deben ser válidos.'}"
          >
            <label class="uk-form-label tw-block tw-mb-2 tw-truncate">Nº DE CARNET DE SOCIOS A AFILIAR</label>
            <ngx-input-tag class="ro-member-number !tw-m-0" formControlName="linked_associate_numbers" [maxNumberOfTags]="10" maxTagLength="8"></ngx-input-tag>
          </app-form-control-errors>
        </div>
        <div class="tw-w-full md:tw-w-1/3 tw-px-2">
          <app-form-control-errors
            class="tw-mb-4 uk-form-controls tw-w-full md:tw-w-3/12 sm:tw-w-1/2 tw-px-2"
            [control]="form.get('dni_card_holder')" [submitted]="submitted"
          >
            <label class="uk-form-label tw-block tw-mb-2 tw-truncate">MÁXIMO MONTO A DEBITAR</label>
            <input currencyMask formControlName="max_debit_amount" [options]="inputCurrencyOptions" class="tw-w-full"/>
          </app-form-control-errors >
        </div>
      </div>
      <div class="tw-flex tw-flex-wrap tw--mx-2 tw-py-4">
        <app-form-control-errors class="tw-w-full md:tw-w-1/2 tw-px-2" [control]="form.get('ahead_fee')" [submitted]="submitted">
          <div class="tw-mb-2">
            <p class="tw-m-0 tw-leading-none">PAGO MES ADELANTADO</p>
            <small>(NO: Hasta mes actual, SI: Hasta mes posterior).</small>
          </div>
          <label class="ro-input-radio tw-inline-block tw-mr-4 tw-cursor-pointer">
            <input type="radio" [value]="true" formControlName="ahead_fee"> Sí
            <span class="checkmark"></span>
          </label>
          <label class="ro-input-radio tw-inline-block tw-mr-4 tw-cursor-pointer">
            <input type="radio" [value]="false" formControlName="ahead_fee"> No
            <span class="checkmark"></span>
          </label>
        </app-form-control-errors>
        <app-form-control-errors class="tw-w-full md:tw-w-1/2 tw-px-2" [control]="form.get('notify_by_email')" [submitted]="submitted">
          <label class="uk-form-label tw-block tw-mb-2 tw-truncate tw-uppercase">Envío de correo de cada socio</label>
          <label class="ro-input-radio tw-inline-block tw-mr-4 tw-cursor-pointer">
            <input type="radio" [value]="true" formControlName="notify_by_email"> Sí
            <span class="checkmark"></span>
          </label>
          <label class="ro-input-radio tw-inline-block tw-mr-4 tw-cursor-pointer">
            <input type="radio" [value]="false" formControlName="notify_by_email"> No
            <span class="checkmark"></span>
          </label>
        </app-form-control-errors>
      </div>
      <p class="tw-mb-4 tw-text-justify tw-p-6 tw-rounded tw-mb-6 tw-bg-blue-100">El monto sugerido máximo a debitar es de S/. 200 por socio, por lo que es recomendable tener el saldo mínimo consignado por socio afiliado. EL CLUB INTERNACIONAL NO SE HACE RESPONSABLE por problemas de saldo insuficiente. La comunicación de cualquier eventualidad se realizará por correo electrónico registrado en el sistema, el mismo que deberá estar actualizado y habilitado
        CORREO DE CONTACTO: <a href="mailto:debitoautomatico@clubinter.org.pe" target="_blank">debitoautomatico@clubinter.org.pe</a></p>
      <p class="tw-mb-4 tw-text-justify tw-p-6 tw-rounded tw-mb-6 tw-bg-blue-100">NOTA: Para proceder con la afiliación el asociado debe de estar al día con la cuota social del mes vigente o un mes anterior como mínimo. El descuento se realiza por su número de tarjeta y fecha de vencimiento; si los datos de la tarjeta son cambiados por cualquier motivo, es deber del socio informarlo y llenar una nueva ficha de afiliación, así mismo, deberá informar si ya no desea contar con el servicio al Débito Automático, CASO CONTRARIO EL CLUB NO SE RESPONSABILIZARÁ DE CUALQUIER EVENTUALIDAD FUTURA. El cargo de la tarjeta se efectuará en moneda nacional, queda excluida de toda responsabilidad por la continuidad y calidad de los bienes y/o servicios que el CLUB INTERNACIONAL AREQUIPA brinda a sus socios afiliados. Si por algún motivo no se le debita un mes, la próxima carga será el acumulado hasta el mes actual.</p>
      <div class="tw-mb-4">
        <p class="tw-text-justify tw-p-6 tw-rounded tw-mb-6 tw-bg-blue-100">De acuerdo a lo dispuesto por la Ley 29733 (Ley de Protección de Datos Personales) y su Reglamento, quien suscribe brinda su consentimiento de manera libre, previa, inequívoca, informada y expresa al CLUB INTERNACIONAL AREQUIPA a efectos de que realice el acopio y tratamiento de mis datos personales CON EL OBJETO DE ACTUALIZAR EL REGISTRO Y BASE DE DATOS DEL CLUB Y PODER SER NOTIFICADO VÁLIDAMENTE CON LAS COMUNICACIONES QUE SE EMITA.Asimismo, AUTORIZO al Club Internacional Arequipa para que me notifique a través del correo electrónico proporcionado, todos los actos administrativos de carácter particular, así como también los procedimientos, comunicados y trámites en general. *</p>
        <app-form-control-errors class="tw-block tw-mr-4"  [control]="form.get('acceptTerms')" [submitted]="submitted">
          <label class="ro-input-checkbox tw-inline-block  tw-cursor-pointer">
            <input type="checkbox" [value]="false" formControlName="acceptTerms">
            He leído y estoy de acuerdo con la información consignada en este documento
            <span class="checkmark"></span>
          </label>
        </app-form-control-errors>
      </div>
      <div>
        <button type="button" class="uk-button uk-button-default uk-button-large !tw-mr-2 !tw-mb-2" routerLink="/recurring-payments/">Cancelar</button>
        <button
          type="button" class="uk-button uk-button-large !tw-mr-2"
          (click)="validateForm()"
          [disabled]="!form.get('acceptTerms').value"
        >
          {{ isUpdateAffiliation ? 'Enviar Solicitud de Acutalización de Datos' : 'Enviar Solicitud de Afiliación' }}
        </button>
      </div>
    </form>
  </div>
</app-flat-card>
