<div class="club-login-layout">
  <div class="tw-h-screen tw-relative ro-mosaic-bg">
    <div
      class="tw-h-screen !tw-bg-cover !tw-bg-center"
      style="background: url('/assets/img/login/bg-1.jpg'); z-index: 1;"
    ></div>
    <div class="ro-mosaic-bg__logos tw-bg-white tw-p-2 tw-box-border">
      <div
        class="tw-w-full lg:tw-w-1/2 tw-mr-auto !tw-bg-contain !tw-bg-no-repeat tw-h-full !tw-bg-center"
        style="background: url('/assets/img/login/bg-logos.png'); z-index: 1;"
      ></div>
    </div>
    <div
      class="
      ro-mosaic-bg__images-blocks
      tw-hidden lg:tw-block fadeIn animated
      tw-h-screen tw-absolute !tw-bg-no-repeat"
      style="background: url('/assets/img/login/bg-2.png'); z-index: 1;"
    ></div>
  </div>

  <div class="
    club-login-footer tw-w-full lg:tw-w-1/2 !lg:tw-mr-auto tw-fixed tw-bottom-0 tw-z-40 tw-p-2 tw-box-border
    tw-text-white tw-flex tw-justify-between
">
    <span class="tw-hidden lg:tw-block">
      {{ appInfo.copyright }}
    </span>
    <span class="">
      {{ appInfo.name }}  v{{ appInfo.version }}
    </span>
  </div>
  <div class="club-login-wrap tw-pt-10">
    <div class="lg:tw-w-1/2 tw-w-full tw-mr-auto tw-px-8">
      <ng-content></ng-content>
    </div>
  </div>
</div>
