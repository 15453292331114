import {Component, Input, OnInit} from '@angular/core';
import { keys, pipe, propOr, head, mergeRight } from 'ramda';

const errorsMessagesMap = {
  required: 'El campo es requerido.',
  ccNumber: 'Número de tarjeta inválido.',
  expDate: 'Fecha de Expirtación inválida.',
  maxLength: 'Longitud de caracteres inválido.',
  email: 'Debe ser un correo electrónico válido.'
};

const defaultMessage = 'Existe un error';

@Component({
  selector: 'app-form-control-errors',
  templateUrl: './form-control-errors.component.html',
  styleUrls: ['./form-control-errors.component.scss']
})
export class FormControlErrorsComponent implements OnInit {

  @Input() control: any = {};
  @Input() submitted = false;
  @Input() customErrorMessage: any = {};

  constructor() { }

  ngOnInit() {
  }

  get hasErrors() {
    return this.submitted && this.control.errors;
  }

  get fieldError() {
    const getErrorMessage = key => propOr(defaultMessage, key, mergeRight(errorsMessagesMap, this.customErrorMessage));
    return pipe(propOr({}, 'errors'), keys, head, getErrorMessage)(this.control);
  }

}
