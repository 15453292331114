/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "angular2-moment/locale.pipe";
import * as i2 from "angular2-moment/time-ago.pipe";
import * as i3 from "./profile-gallery-image.component";
var styles_ProfileGalleryImageComponent = [];
var RenderType_ProfileGalleryImageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProfileGalleryImageComponent, data: {} });
export { RenderType_ProfileGalleryImageComponent as RenderType_ProfileGalleryImageComponent };
export function View_ProfileGalleryImageComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.LocalePipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "club-profile-image-gallery club-profile-image-gallery-overlay"], ["style", "margin-bottom: 10px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "image-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "image-wrapper-img"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 8, "div", [["class", "overlay-data"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickImage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 7, "div", [["class", "overlay-data-footer uk-clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "span", [["class", "uk-float-left"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵppd(9, 2), i0.ɵpid(131072, i2.TimeAgoPipe, [i0.ChangeDetectorRef, i0.NgZone]), (_l()(), i0.ɵeld(11, 0, null, null, 2, "span", [["class", "uk-float-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 0, "i", [["class", "uk-icon uk-icon-heart"]], null, null, null, null, null)), (_l()(), i0.ɵted(13, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.post.post_info.image; _ck(_v, 4, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 10).transform(i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v, 0), _co.post.created_at, "es")))); _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.post.likes_count; _ck(_v, 13, 0, currVal_2); }); }
export function View_ProfileGalleryImageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-profile-gallery-image", [], null, null, null, View_ProfileGalleryImageComponent_0, RenderType_ProfileGalleryImageComponent)), i0.ɵdid(1, 114688, null, 0, i3.ProfileGalleryImageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileGalleryImageComponentNgFactory = i0.ɵccf("app-profile-gallery-image", i3.ProfileGalleryImageComponent, View_ProfileGalleryImageComponent_Host_0, { image: "image", post: "post", onClickImage: "onClickImage" }, {}, []);
export { ProfileGalleryImageComponentNgFactory as ProfileGalleryImageComponentNgFactory };
