/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-visa-response-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/title-page/title-page.component.ngfactory";
import * as i3 from "../../../shared/components/title-page/title-page.component";
import * as i4 from "../../components/payment-visa/payment-visa-response-authorization/payment-visa-response-authorization.component.ngfactory";
import * as i5 from "../../../../services/payment/visa.service";
import * as i6 from "@angular/common/http";
import * as i7 from "../../components/payment-visa/payment-visa-response-authorization/payment-visa-response-authorization.component";
import * as i8 from "./payment-visa-response-page.component";
import * as i9 from "@angular/router";
import * as i10 from "../../../../data-services/analytics.service";
var styles_PaymentVisaResponsePageComponent = [i0.styles];
var RenderType_PaymentVisaResponsePageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentVisaResponsePageComponent, data: {} });
export { RenderType_PaymentVisaResponsePageComponent as RenderType_PaymentVisaResponsePageComponent };
export function View_PaymentVisaResponsePageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "club-socio-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-title-page", [["icon", "uk-icon-shopping-cart"]], null, null, null, i2.View_TitlePageComponent_0, i2.RenderType_TitlePageComponent)), i1.ɵdid(2, 114688, null, 0, i3.TitlePageComponent, [], { title: [0, "title"], icon: [1, "icon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "app-payment-visa-response-authorization", [], null, null, null, i4.View_PaymentVisaResponseAuthorizationComponent_0, i4.RenderType_PaymentVisaResponseAuthorizationComponent)), i1.ɵprd(512, null, i5.VisaService, i5.VisaService, [i6.HttpClient]), i1.ɵdid(5, 114688, null, 0, i7.PaymentVisaResponseAuthorizationComponent, [i5.VisaService], { transactionId: [0, "transactionId"], transactionToken: [1, "transactionToken"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = "uk-icon-shopping-cart"; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.transactionId; var currVal_3 = _co.transactionToken; _ck(_v, 5, 0, currVal_2, currVal_3); }, null); }
export function View_PaymentVisaResponsePageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-visa-response-page", [], null, null, null, View_PaymentVisaResponsePageComponent_0, RenderType_PaymentVisaResponsePageComponent)), i1.ɵdid(1, 114688, null, 0, i8.PaymentVisaResponsePageComponent, [i9.ActivatedRoute, i10.AnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentVisaResponsePageComponentNgFactory = i1.ɵccf("app-payment-visa-response-page", i8.PaymentVisaResponsePageComponent, View_PaymentVisaResponsePageComponent_Host_0, {}, {}, []);
export { PaymentVisaResponsePageComponentNgFactory as PaymentVisaResponsePageComponentNgFactory };
