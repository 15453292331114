<div class="uk-text-right mb-05">
  <div class="tw-font-bold profile-name uk-text-truncate">
    {{ userSession.clubMember.name }}
  </div>
  <div class="tw-font-bold profile-name uk-text-truncate">
    {{ userSession.clubMember.last_name }}
  </div>
  <p class="tw-m-0">{{ userSession.username }}</p>
</div>
<div>
  <a
    class="tw-flex tw-flex-row tw-items-center tw-h-12 tw-px-4 tw-rounded-lg tw-text-gray-600 hover:tw-bg-gray-100"
    *ngFor="let navItem of menuItems"
    [routerLink]="navItem.path"
    (click)="navClicked.emit()"
  >
    {{ navItem.name }}
  </a>
  <ng-content select="[menuItem]"></ng-content>
</div>
<hr />
<a
  class="tw-flex tw-flex-row tw-items-center tw-h-12 tw-px-4 tw-rounded-lg tw-text-gray-600 hover:tw-bg-gray-100"
  (click)="closeSession.emit()"
>
  <i class="uk-icon uk-icon-sign-out"></i>&nbsp;&nbsp; Cerrar Sesión
</a>
