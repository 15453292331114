/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./loading-btn.component";
var styles_LoadingBtnComponent = [];
var RenderType_LoadingBtnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoadingBtnComponent, data: {} });
export { RenderType_LoadingBtnComponent as RenderType_LoadingBtnComponent };
function View_LoadingBtnComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "ro-loading "]], null, null, null, null, null))], null, null); }
function View_LoadingBtnComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["style", "vertical-align: middle;"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 1, 0, currVal_0); }); }
function View_LoadingBtnComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["style", "vertical-align: middle;"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content; _ck(_v, 1, 0, currVal_0); }); }
export function View_LoadingBtnComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoadingBtnComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoadingBtnComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoadingBtnComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.show; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.show; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_LoadingBtnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loading-btn", [], null, null, null, View_LoadingBtnComponent_0, RenderType_LoadingBtnComponent)), i0.ɵdid(1, 49152, null, 0, i2.LoadingBtnComponent, [], null, null)], null, null); }
var LoadingBtnComponentNgFactory = i0.ɵccf("app-loading-btn", i2.LoadingBtnComponent, View_LoadingBtnComponent_Host_0, { show: "show", type: "type", content: "content", message: "message" }, {}, []);
export { LoadingBtnComponentNgFactory as LoadingBtnComponentNgFactory };
