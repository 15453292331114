import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-social-banners',
    templateUrl: './social-banners.component.html',
    styleUrls: ['./social-banners.component.scss'],
})
export class SocialBannersComponent implements OnInit {
    banners = [
        {
            imageUrl: '/assets/images/banners/state-message-2.jpeg',
            linkUrl: 'https://clubinter.org.pe/estados-financieros-2023',
            target: '_blank',
            isExternal: true,
        },
        {
            imageUrl: '/assets/images/banners/virtual-id-banner.jpeg',
            linkUrl: '/virtual-id',
            isExternal: false,
        },
    ];
    constructor() {}

    ngOnInit() {}
}
