<div
  class="club-socio-page tw-flex tw-justify-center tw-items-center tw-h-screen"
>
  <app-message
    class="tw-w-2/3"
    [message]="'Procesando Datos'"
    [subMessage]="
      'Estimado socio, ahora mismo estamos procesando información importante, lamentamos las molestías <br> pero InterSocio <b>NO SE ENCUENTRA DISPONIBLE desde las 00:00 hrs hasta las 04:00 hrs.</b> <br> Vuelva a Intentarlo más tarde.'
    "
    [icon]="'cogs'"
  ></app-message>
</div>
