/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-not-available.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/message/message.component.ngfactory";
import * as i3 from "../../../shared/components/message/message.component";
import * as i4 from "./app-not-available.component";
var styles_AppNotAvailableComponent = [i0.styles];
var RenderType_AppNotAvailableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppNotAvailableComponent, data: {} });
export { RenderType_AppNotAvailableComponent as RenderType_AppNotAvailableComponent };
export function View_AppNotAvailableComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "club-socio-page tw-flex tw-justify-center tw-items-center tw-h-screen"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-message", [["class", "tw-w-2/3"]], null, null, null, i2.View_MessageComponent_0, i2.RenderType_MessageComponent)), i1.ɵdid(2, 114688, null, 0, i3.MessageComponent, [], { message: [0, "message"], subMessage: [1, "subMessage"], icon: [2, "icon"] }, null)], function (_ck, _v) { var currVal_0 = "Procesando Datos"; var currVal_1 = "Estimado socio, ahora mismo estamos procesando informaci\u00F3n importante, lamentamos las molest\u00EDas <br> pero InterSocio <b>NO SE ENCUENTRA DISPONIBLE desde las 00:00 hrs hasta las 04:00 hrs.</b> <br> Vuelva a Intentarlo m\u00E1s tarde."; var currVal_2 = "cogs"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AppNotAvailableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-app-not-available", [], null, null, null, View_AppNotAvailableComponent_0, RenderType_AppNotAvailableComponent)), i1.ɵdid(1, 114688, null, 0, i4.AppNotAvailableComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppNotAvailableComponentNgFactory = i1.ɵccf("app-app-not-available", i4.AppNotAvailableComponent, View_AppNotAvailableComponent_Host_0, {}, {}, []);
export { AppNotAvailableComponentNgFactory as AppNotAvailableComponentNgFactory };
