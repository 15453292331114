/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-visa-response-authorization.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../shared/components/spinner/spinner.component";
import * as i4 from "../../../../shared/components/message/message.component.ngfactory";
import * as i5 from "../../../../shared/components/message/message.component";
import * as i6 from "@angular/router";
import * as i7 from "../payment-visa-response/payment-visa-response.component.ngfactory";
import * as i8 from "../../../../../services/payment/visa.service";
import * as i9 from "@angular/common/http";
import * as i10 from "../../../../../services/payment/transaction.service";
import * as i11 from "../payment-visa-response/payment-visa-response.component";
import * as i12 from "../../../../../data-services/payment.service";
import * as i13 from "../../payment-recomendations/payment-recomendations.component.ngfactory";
import * as i14 from "../../payment-recomendations/payment-recomendations.component";
import * as i15 from "@angular/common";
import * as i16 from "./payment-visa-response-authorization.component";
var styles_PaymentVisaResponseAuthorizationComponent = [i0.styles];
var RenderType_PaymentVisaResponseAuthorizationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentVisaResponseAuthorizationComponent, data: {} });
export { RenderType_PaymentVisaResponseAuthorizationComponent as RenderType_PaymentVisaResponseAuthorizationComponent };
function View_PaymentVisaResponseAuthorizationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spinner", [["class", "fadeIn animated"]], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 114688, null, 0, i3.SpinnerComponent, [], { message: [0, "message"] }, null)], function (_ck, _v) { var currVal_0 = "Recuperando respuesta..."; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PaymentVisaResponseAuthorizationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "app-message", [["class", "fadeIn animated"]], null, null, null, i4.View_MessageComponent_0, i4.RenderType_MessageComponent)), i1.ɵdid(1, 114688, null, 0, i5.MessageComponent, [], { message: [0, "message"], subMessage: [1, "subMessage"], icon: [2, "icon"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "div", [["class", "tw-text-center tw-mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["class", "uk-button"], ["routerLink", "/state"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i6.RouterLink, [i6.Router, i6.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" Regresa a mi estado de pagos "]))], function (_ck, _v) { var currVal_0 = "Hubo un error procesando la respuesta del pago"; var currVal_1 = "No se pudo procesar la verificaci\u00F3n del pago. Comun\u00EDquese con el equipo de TI para reportar el error."; var currVal_2 = "warning"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "/state"; _ck(_v, 4, 0, currVal_3); }, null); }
function View_PaymentVisaResponseAuthorizationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "tw-flex tw-flex-wrap tw--mx-2 fadeIn animated"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "tw-w-full md:tw-w-2/3 tw-px-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "app-payment-visa-response", [], null, null, null, i7.View_PaymentVisaResponseComponent_0, i7.RenderType_PaymentVisaResponseComponent)), i1.ɵprd(512, null, i8.VisaService, i8.VisaService, [i9.HttpClient]), i1.ɵprd(512, null, i10.TransactionService, i10.TransactionService, [i9.HttpClient]), i1.ɵdid(6, 114688, null, 0, i11.PaymentVisaResponseComponent, [i8.VisaService, i10.TransactionService, i12.PaymentService], { paymentResponse: [0, "paymentResponse"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "tw-w-full md:tw-w-1/3 tw-px-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-payment-recomendations", [], null, null, null, i13.View_PaymentRecomendationsComponent_0, i13.RenderType_PaymentRecomendationsComponent)), i1.ɵdid(9, 114688, null, 0, i14.PaymentRecomendationsComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.paymentResponse; _ck(_v, 6, 0, currVal_0); _ck(_v, 9, 0); }, null); }
export function View_PaymentVisaResponseAuthorizationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentVisaResponseAuthorizationComponent_1)), i1.ɵdid(1, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentVisaResponseAuthorizationComponent_2)), i1.ɵdid(3, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentVisaResponseAuthorizationComponent_3)), i1.ɵdid(5, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isProcessing; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.hasError; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.isSuccess; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_PaymentVisaResponseAuthorizationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-payment-visa-response-authorization", [], null, null, null, View_PaymentVisaResponseAuthorizationComponent_0, RenderType_PaymentVisaResponseAuthorizationComponent)), i1.ɵprd(512, null, i8.VisaService, i8.VisaService, [i9.HttpClient]), i1.ɵdid(2, 114688, null, 0, i16.PaymentVisaResponseAuthorizationComponent, [i8.VisaService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var PaymentVisaResponseAuthorizationComponentNgFactory = i1.ɵccf("app-payment-visa-response-authorization", i16.PaymentVisaResponseAuthorizationComponent, View_PaymentVisaResponseAuthorizationComponent_Host_0, { transactionId: "transactionId", transactionToken: "transactionToken" }, {}, []);
export { PaymentVisaResponseAuthorizationComponentNgFactory as PaymentVisaResponseAuthorizationComponentNgFactory };
