/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../post/post-form/post-form.component.ngfactory";
import * as i2 from "../../../../services/social/post.service";
import * as i3 from "@angular/common/http";
import * as i4 from "../post/post-form/post-form.component";
import * as i5 from "../../../../services/auth/auth.service";
import * as i6 from "ngx-infinite-scroll";
import * as i7 from "../post/post-list/post-list.component.ngfactory";
import * as i8 from "../post/post-list/post-list.component";
import * as i9 from "../../../shared/services/ro-notification.service";
import * as i10 from "./feeds.component";
var styles_FeedsComponent = [];
var RenderType_FeedsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FeedsComponent, data: {} });
export { RenderType_FeedsComponent as RenderType_FeedsComponent };
export function View_FeedsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { postList: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 2, "app-post-form", [], null, null, null, i1.View_PostFormComponent_0, i1.RenderType_PostFormComponent)), i0.ɵprd(512, null, i2.PostService, i2.PostService, [i3.HttpClient]), i0.ɵdid(3, 114688, null, 0, i4.PostFormComponent, [i2.PostService, i5.AuthService], { onSuccess: [0, "onSuccess"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "fadeIn animated"], ["infinite-scroll", ""]], null, [[null, "scrolled"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scrolled" === en)) {
        var pd_0 = (_co.getNewFeeds() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 4866048, null, 0, i6.InfiniteScrollDirective, [i0.ElementRef, i0.NgZone], { infiniteScrollDistance: [0, "infiniteScrollDistance"], infiniteScrollThrottle: [1, "infiniteScrollThrottle"], infiniteScrollDisabled: [2, "infiniteScrollDisabled"], infiniteScrollContainer: [3, "infiniteScrollContainer"], scrollWindow: [4, "scrollWindow"], fromRoot: [5, "fromRoot"] }, { scrolled: "scrolled" }), (_l()(), i0.ɵeld(8, 0, null, null, 3, "app-post-list", [], null, null, null, i7.View_PostListComponent_0, i7.RenderType_PostListComponent)), i0.ɵprd(512, null, i2.PostService, i2.PostService, [i3.HttpClient]), i0.ɵdid(10, 114688, [[1, 4]], 0, i8.PostListComponent, [i2.PostService, i5.AuthService, i9.RoNotificationService], { type: [0, "type"] }, null), i0.ɵpad(11, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.onLastPost; _ck(_v, 3, 0, currVal_0); var currVal_1 = 2; var currVal_2 = 300; var currVal_3 = _co.loadingPosts; var currVal_4 = ".club-socio-view"; var currVal_5 = false; var currVal_6 = true; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _ck(_v, 11, 0, 1, 2); _ck(_v, 10, 0, currVal_7); }, null); }
export function View_FeedsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-feeds", [], null, null, null, View_FeedsComponent_0, RenderType_FeedsComponent)), i0.ɵprd(512, null, i2.PostService, i2.PostService, [i3.HttpClient]), i0.ɵdid(2, 114688, null, 0, i10.FeedsComponent, [i2.PostService, i5.AuthService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var FeedsComponentNgFactory = i0.ɵccf("app-feeds", i10.FeedsComponent, View_FeedsComponent_Host_0, {}, {}, ["[focusedContent]"]);
export { FeedsComponentNgFactory as FeedsComponentNgFactory };
