import { Component, OnInit } from '@angular/core';
import {Session} from '@app/classes/user/session';
import {AccountVerificationService} from '@app/modules/application/services/account-verification.service';
import {LoadingMainService} from '@app/services/ui/loading-main.service';
import {RoNotificationService} from '@app/modules/shared/services/ro-notification.service';

@Component({
  selector: 'app-unverified-account',
  templateUrl: './unverified-account.component.html',
  styleUrls: ['./unverified-account.component.scss'],
  providers: [ AccountVerificationService ]
})
export class UnverifiedAccountComponent implements OnInit {
  userEmail: string;
  verificationSent: boolean;
  constructor(
    private _accountVerificationService: AccountVerificationService,
    private _mainLoading: LoadingMainService,
    private _roNotificationService: RoNotificationService
  ) { }

  ngOnInit() {
    this.userEmail = Session.getInstance().getUser().email;
  }

  sendEmailVerification() {
    this._mainLoading.show();
    this._accountVerificationService.sendEmailVerification()
      .subscribe(
        response => {
          this.verificationSent = true;
          this._mainLoading.hide();
        },
        error => {
          this._mainLoading.hide();
          console.error(error);
          this._roNotificationService.danger('No se pudo enviar la verificación.');
        }
      );
  }

}
