<div>
  <label><b>Total a Pagar</b></label
  ><br />
  <div class="uk-text-large">S/ {{ totalPayment | number: '1.2-2' }}</div>
</div>

<div class="mt-1">
  <p>
    Para poder seguir con el proceso pago debe aceptar nuestros términos y
    condiciones, <b>recuerde leerlos con detenimiento antes.</b>
  </p>
</div>

<div class="mt-1">
  <div class="club-checkbox">
    <input
      type="checkbox"
      id="tac"
      [(ngModel)]="accept"
      name="accept"
      (ngModelChange)="updateAccept()"
    />
    <label class="uk-width-1-1 no-select" for="tac" c="">
      <b style="font-size: 15px;"
        >Acepto los
        <a
          href="http://www.clubinter.org.pe/tyc/content/pagos-en-linea"
          target="_blank"
          >términos y condiciones</a
        >
        del proceso de pago que establece el Club Internacional Arequipa.</b
      >
    </label>
  </div>
</div>
