import { Injectable } from '@angular/core';
import Analytics, { AnalyticsInstance } from 'analytics';
import googleAnalytics from '@analytics/google-analytics';
import { roLogger } from '@app/helpers/roLogger';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  analytics: AnalyticsInstance;

  constructor() {
    const logger = roLogger({ context: 'GA' });
    logger('GA service initialized');
    this.analytics = Analytics({
      app: 'intersocio-app',
      plugins: [
        googleAnalytics({
          measurementIds: ['G-47GSHBTM6G'], // for jos.arambide.dev account
        }),
      ],
    });
  }
}
