import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/services/auth/auth.service';
import { PaymentService } from '@app/data-services/payment.service';
import { AnalyticsService } from '@app/data-services/analytics.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-payment-page',
  providers: [AuthService],
  templateUrl: './payment-page.component.html',
})
export class PaymentPageComponent implements OnInit {
  paymentCount$ = this.paymentService.paymentList$.pipe(
    map((item) => item.length)
  );

  constructor(
    private analyticsService: AnalyticsService,
    private paymentService: PaymentService
  ) {}

  title = 'Pagos en línea';
  transaction: string;

  ngOnInit() {
    this.analyticsService.analytics.page({
      path: '/payment',
      title: this.title,
    });
  }
}
