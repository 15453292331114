/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./rating.component";
var styles_RatingComponent = [];
var RenderType_RatingComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RatingComponent, data: {} });
export { RenderType_RatingComponent as RenderType_RatingComponent };
function View_RatingComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "li", [["class", "uk-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectRatingItem(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "club-rating-large": 0, "uk-icon-star": 1, "uk-icon-star-o": 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "uk-icon"; var currVal_1 = _ck(_v, 3, 0, (_co.size == "large"), (((_v.context.index <= _co.selectStar) && _co.selectable) || _v.context.$implicit.full), (((_v.context.index > _co.selectStar) && _co.selectable) || (!_v.context.$implicit.full && !_co.selectable))); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_RatingComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "ul", [["class", "club-rating"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "club-rating-selectable": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RatingComponent_1)), i0.ɵdid(5, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "club-rating"; var currVal_1 = _ck(_v, 3, 0, _co.selectable); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.starList; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_RatingComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-rating", [], null, null, null, View_RatingComponent_0, RenderType_RatingComponent)), i0.ɵdid(1, 114688, null, 0, i2.RatingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RatingComponentNgFactory = i0.ɵccf("app-rating", i2.RatingComponent, View_RatingComponent_Host_0, { max: "max", full: "full", size: "size", selectable: "selectable", onSelectedStar: "onSelectedStar" }, {}, []);
export { RatingComponentNgFactory as RatingComponentNgFactory };
