<ul class="tw-flex tw-flex-col tw-w-full">
  <li class="tw-my-px">
    <div
      *ngFor="let navItem of navigationItems"
      [routerLink]="[navItem.path]"
      class="
         tw-flex tw-items-center tw-justify-between tw-h-12 tw-px-4 tw-rounded-lg tw-text-gray-800 tw-mb-2
         tw-bg-intersocio-blue-100 tw-cursor-pointer
      "
      routerLinkActive="!tw-bg-intersocio-blue-400 !tw-text-white"
    >
        <span>{{ navItem.name }}</span>
        <nz-tag *ngIf="navItem.isNew" [nzColor]="'#fa5a55'">Nuevo</nz-tag>
    </div>
  </li>
</ul>
