import { Injectable } from '@angular/core';
import { Session } from '@app/classes/user/session';

export const AUTHORIZATION_KEY = 'IS:AUTHORIZATION';

export const LOG_OUT_EVENT = 'event:on:logout';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  authorization: string = null;

  constructor() {
    const auth = sessionStorage.getItem(AUTHORIZATION_KEY);
    if (auth) {
      this.authorization = auth;
    }
  }

  public create(session: any) {
    Session.getInstance().createSession(session);
    sessionStorage.setItem(AUTHORIZATION_KEY, session.authorization);
  }

  public clear() {
    this.authorization = null;
    sessionStorage.removeItem(AUTHORIZATION_KEY);
    Session.getInstance().clearSession();
    window.dispatchEvent(new CustomEvent(LOG_OUT_EVENT));
  }
}
