/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./recurring-payment-status.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../recurring-payment-status-card/recurring-payment-status-card.component.ngfactory";
import * as i3 from "../recurring-payment-status-card/recurring-payment-status-card.component";
import * as i4 from "@angular/common";
import * as i5 from "../recurring-payment-info/recurring-payment-info.component.ngfactory";
import * as i6 from "../recurring-payment-info/recurring-payment-info.component";
import * as i7 from "./recurring-payment-status.component";
import * as i8 from "../../data/recurring-payments-store.service";
var styles_RecurringPaymentStatusComponent = [i0.styles];
var RenderType_RecurringPaymentStatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RecurringPaymentStatusComponent, data: {} });
export { RenderType_RecurringPaymentStatusComponent as RenderType_RecurringPaymentStatusComponent };
function View_RecurringPaymentStatusComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-recurring-payment-status-card", [["class", "tw-block tw-mb-6"]], null, null, null, i2.View_RecurringPaymentStatusCardComponent_0, i2.RenderType_RecurringPaymentStatusCardComponent)), i1.ɵdid(1, 114688, null, 0, i3.RecurringPaymentStatusCardComponent, [], { userRecurringPayments: [0, "userRecurringPayments"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentUserRecurringPayment; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RecurringPaymentStatusComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecurringPaymentStatusComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-recurring-payment-info", [["class", "tw-block tw-mb-6"]], null, null, null, i5.View_RecurringPaymentInfoComponent_0, i5.RenderType_RecurringPaymentInfoComponent)), i1.ɵdid(3, 114688, null, 0, i6.RecurringPaymentInfoComponent, [], { userRecurringPayments: [0, "userRecurringPayments"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentUserRecurringPayment.isAffiliated || _co.currentUserRecurringPayment.isProcessingRequest); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.currentUserRecurringPayment; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_RecurringPaymentStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-recurring-payment-status", [], null, null, null, View_RecurringPaymentStatusComponent_0, RenderType_RecurringPaymentStatusComponent)), i1.ɵdid(1, 114688, null, 0, i7.RecurringPaymentStatusComponent, [i8.RecurringPaymentsStoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecurringPaymentStatusComponentNgFactory = i1.ɵccf("app-recurring-payment-status", i7.RecurringPaymentStatusComponent, View_RecurringPaymentStatusComponent_Host_0, {}, {}, []);
export { RecurringPaymentStatusComponentNgFactory as RecurringPaymentStatusComponentNgFactory };
