import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Service } from '../Service.js';
import { AppSettings } from '@app/helpers/app.settings';
import { Session } from '@app/classes/user/session';
import { User } from '@app/classes/user/user';
import { SessionService } from '@app/data-services/session.service';

@Injectable()
export class AuthService extends Service {
  constructor(
    private _http: HttpClient,
    private _routerService: Router,
    private sessionService: SessionService
  ) {
    super();
  }

  login2(user) {
    return this._http.post(
      this.API_ENDPOINT_API_INTERSOCIO + 'users/sign_in',
      {
        user,
      },
      { observe: 'response' }
    );
  }

  registration(user) {
    console.log(user);
    return this._http.post(this.API_ENDPOINT_API_INTERSOCIO + 'users', {
      user,
    });
  }

  closeSession() {
    this.sessionService.clear();
    this._routerService.navigate(['login']);
  }
}
