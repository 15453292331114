/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./module-layout-navigation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../flat-card/flat-card.component.ngfactory";
import * as i4 from "../../flat-card/flat-card.component";
import * as i5 from "../../../spinner/spinner.component.ngfactory";
import * as i6 from "../../../spinner/spinner.component";
import * as i7 from "./module-layout-navigation.component";
var styles_ModuleLayoutNavigationComponent = [i0.styles];
var RenderType_ModuleLayoutNavigationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModuleLayoutNavigationComponent, data: {} });
export { RenderType_ModuleLayoutNavigationComponent as RenderType_ModuleLayoutNavigationComponent };
function View_ModuleLayoutNavigationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "tw-flex tw-flex-wrap tw--mx-2 tw-mt-2 fadeIn animated"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "tw-w-full tw-px-2 tw-mb-4"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(5, 1), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "tw-w-full tw-px-2 fadeIn animated tw-mb-4"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(10, 1), i1.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tw-w-full tw-px-2 tw-mb-4"; var currVal_1 = _ck(_v, 5, 0, _co.currentRowSize.left); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = "tw-w-full tw-px-2 fadeIn animated tw-mb-4"; var currVal_3 = _ck(_v, 10, 0, _co.currentRowSize.right); _ck(_v, 9, 0, currVal_2, currVal_3); }, null); }
function View_ModuleLayoutNavigationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "app-flat-card", [], [[8, "className", 0]], null, null, i3.View_FlatCardComponent_0, i3.RenderType_FlatCardComponent)), i1.ɵdid(2, 49152, null, 0, i4.FlatCardComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "div", [["class", "tw-my-32 fadeIn animated"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-spinner", [["message", "Cargando m\u00F3dulo..."]], null, null, null, i5.View_SpinnerComponent_0, i5.RenderType_SpinnerComponent)), i1.ɵdid(5, 114688, null, 0, i6.SpinnerComponent, [], { message: [0, "message"] }, null)], function (_ck, _v) { var currVal_1 = "Cargando m\u00F3dulo..."; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).classes; _ck(_v, 1, 0, currVal_0); }); }
export function View_ModuleLayoutNavigationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleLayoutNavigationComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleLayoutNavigationComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ModuleLayoutNavigationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-module-layout-navigation", [], null, null, null, View_ModuleLayoutNavigationComponent_0, RenderType_ModuleLayoutNavigationComponent)), i1.ɵdid(1, 114688, null, 0, i7.ModuleLayoutNavigationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModuleLayoutNavigationComponentNgFactory = i1.ɵccf("app-module-layout-navigation", i7.ModuleLayoutNavigationComponent, View_ModuleLayoutNavigationComponent_Host_0, { rowSize: "rowSize", loading: "loading" }, {}, ["[leftSide]", "[rightSide]"]);
export { ModuleLayoutNavigationComponentNgFactory as ModuleLayoutNavigationComponentNgFactory };
