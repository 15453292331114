import {Component, Input, OnInit} from '@angular/core';
import {UserRecurringPayment} from '@app/modules/recurring-payments/classes/user-recurring-payment';
import RecurringPaymentConstants from '@app/modules/recurring-payments/recurring-payment-constants';
import { values, find, propEq } from 'ramda';

@Component({
  selector: 'app-recurring-payment-status-card',
  templateUrl: './recurring-payment-status-card.component.html',
  styleUrls: ['./recurring-payment-status-card.component.scss']
})
export class RecurringPaymentStatusCardComponent implements OnInit {
  @Input() userRecurringPayments: UserRecurringPayment;
  constructor() { }

  ngOnInit() {
  }

  get userCardBrand() {
    return find(propEq('value', this.userRecurringPayments.cardBrand), values(RecurringPaymentConstants.paymentTypes)) || {};
  }

}
