import Cookies from 'js-cookie';
import { isNil } from 'ramda';
import { PaymentConstants } from '@app/modules/payment/payment-constants';
import { roLogger } from '@app/helpers/roLogger';
import * as i0 from "@angular/core";
export class VisaResponseGuard {
    constructor() {
        this.logger = roLogger({ context: 'VisaResponseGuard' });
    }
    static hasRequiredCookies() {
        const { TRANSACTION_ID, TRANSACTION_TOKEN } = PaymentConstants.COOKIE_NAMES;
        return (!isNil(Cookies.get(TRANSACTION_ID)) &&
            !isNil(Cookies.get(TRANSACTION_TOKEN)));
    }
    canActivate(next, state) {
        this.logger('hasRequiredCookies' + VisaResponseGuard.hasRequiredCookies());
        return VisaResponseGuard.hasRequiredCookies();
    }
}
VisaResponseGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VisaResponseGuard_Factory() { return new VisaResponseGuard(); }, token: VisaResponseGuard, providedIn: "root" });
