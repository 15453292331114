<div class="club-socio-panel">
  <div class="uk-clearfix mb-1">
    <a class="uk-button !tw-mr-2 tw-inline-block" routerLink="/">
      <i class="uk-icon uk-icon-home"></i> Regresar al Inicio
    </a>
    <button
      class="uk-button"
      roNgxSimplePrint
      [printContentId]="'printed-section'"
    >
      <i class="uk-icon uk-icon-print"></i> Imprimir
    </button>
  </div>
  <app-payment-visa-response-success
    *ngIf="isSuccessResponse"
    [dataResponse]="paymentResponse"
  ></app-payment-visa-response-success>
  <app-payment-visa-response-error
    *ngIf="!isSuccessResponse"
    [dataResponse]="paymentResponse"
  ></app-payment-visa-response-error>
</div>
