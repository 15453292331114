/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inscription-info-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/inscription/inscription-feeds/inscription-feeds.component.ngfactory";
import * as i3 from "../../components/inscription/inscription-feeds/inscription-feeds.component";
import * as i4 from "./inscription-info-page.component";
var styles_InscriptionInfoPageComponent = [i0.styles];
var RenderType_InscriptionInfoPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InscriptionInfoPageComponent, data: {} });
export { RenderType_InscriptionInfoPageComponent as RenderType_InscriptionInfoPageComponent };
export function View_InscriptionInfoPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-inscription-feeds", [], null, null, null, i2.View_InscriptionFeedsComponent_0, i2.RenderType_InscriptionFeedsComponent)), i1.ɵdid(1, 114688, null, 0, i3.InscriptionFeedsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_InscriptionInfoPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-inscription-info-page", [], null, null, null, View_InscriptionInfoPageComponent_0, RenderType_InscriptionInfoPageComponent)), i1.ɵdid(1, 114688, null, 0, i4.InscriptionInfoPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InscriptionInfoPageComponentNgFactory = i1.ɵccf("app-inscription-info-page", i4.InscriptionInfoPageComponent, View_InscriptionInfoPageComponent_Host_0, {}, {}, []);
export { InscriptionInfoPageComponentNgFactory as InscriptionInfoPageComponentNgFactory };
