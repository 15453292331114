/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./message.component";
var styles_MessageComponent = [];
var RenderType_MessageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MessageComponent, data: {} });
export { RenderType_MessageComponent as RenderType_MessageComponent };
function View_MessageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "uk-icon uk-icon-", _co.iconClass, " club-message-main-icon"); _ck(_v, 0, 0, currVal_0); }); }
function View_MessageComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "p", [["class", "m-0"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subMessage; _ck(_v, 0, 0, currVal_0); }); }
export function View_MessageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "club-message"], ["style", "background: #fff;"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MessageComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h3", [["class", "m-0"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MessageComponent_2)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.icon; _ck(_v, 2, 0, currVal_0); var currVal_2 = (_co.subMessage != ""); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.message; _ck(_v, 4, 0, currVal_1); }); }
export function View_MessageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-message", [], null, null, null, View_MessageComponent_0, RenderType_MessageComponent)), i0.ɵdid(1, 114688, null, 0, i2.MessageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessageComponentNgFactory = i0.ɵccf("app-message", i2.MessageComponent, View_MessageComponent_Host_0, { message: "message", subMessage: "subMessage", icon: "icon" }, {}, ["*"]);
export { MessageComponentNgFactory as MessageComponentNgFactory };
