/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/ng-zorro-antd/avatar/ng-zorro-antd-avatar.ngfactory";
import * as i2 from "@angular/common";
import * as i3 from "ng-zorro-antd/core";
import * as i4 from "ng-zorro-antd/avatar";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "ng-zorro-antd/icon";
import * as i7 from "./header-user-button.component";
var styles_HeaderUserButtonComponent = [];
var RenderType_HeaderUserButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HeaderUserButtonComponent, data: {} });
export { RenderType_HeaderUserButtonComponent as RenderType_HeaderUserButtonComponent };
export function View_HeaderUserButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "nz-avatar", [["class", "tw-mr-2"]], null, null, null, i1.View_NzAvatarComponent_0, i1.RenderType_NzAvatarComponent)), i0.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(3, { backgroundColor: 0 }), i0.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i0.RendererFactory2]), i0.ɵdid(5, 573440, null, 0, i4.NzAvatarComponent, [i3.NzConfigService, i0.ElementRef, i0.ChangeDetectorRef, i3.NzUpdateHostClassService, i0.Renderer2, i5.Platform], { nzText: [0, "nzText"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 3, "span", [["class", "tw-text-white tw-text-sm"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", "\u00A0\u00A0 "])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "i", [["nz-icon", ""], ["nzTheme", "outline"], ["nzType", "caret-down"]], null, null, null, null, null)), i0.ɵdid(9, 2834432, null, 0, i6.NzIconDirective, [i6.NzIconService, i0.ElementRef, i0.Renderer2, i5.Platform], { nzType: [0, "nzType"], nzTheme: [1, "nzTheme"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.stc(_co.user.username)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.user.username.slice(0, 2).toUpperCase(); _ck(_v, 5, 0, currVal_1); var currVal_3 = "caret-down"; var currVal_4 = "outline"; _ck(_v, 9, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.user.clubMember.name; _ck(_v, 7, 0, currVal_2); }); }
export function View_HeaderUserButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-header-user-button", [], null, null, null, View_HeaderUserButtonComponent_0, RenderType_HeaderUserButtonComponent)), i0.ɵdid(1, 49152, null, 0, i7.HeaderUserButtonComponent, [], null, null)], null, null); }
var HeaderUserButtonComponentNgFactory = i0.ɵccf("app-header-user-button", i7.HeaderUserButtonComponent, View_HeaderUserButtonComponent_Host_0, { user: "user" }, {}, []);
export { HeaderUserButtonComponentNgFactory as HeaderUserButtonComponentNgFactory };
