import { Component, OnInit } from '@angular/core';
import {AnalyticsService} from '@app/data-services/analytics.service';

@Component({
  selector: 'app-payment-visa-timeout',
  templateUrl: './payment-visa-timeout.component.html',
  styleUrls: ['./payment-visa-timeout.component.scss']
})
export class PaymentVisaTimeoutComponent implements OnInit {

  constructor(private analyticsService: AnalyticsService) { }

  ngOnInit() {
    this.analyticsService.analytics.page({
      path: '/timeout-error',
      title: 'Timeout page',
    });
  }

}
