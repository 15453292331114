import {
  Directive,
  ElementRef,
  Renderer,
  Input,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { NgModel } from '@angular/forms';

declare var jQuery: any;
declare var UIkit: any;

@Directive({
  selector: '[ngModel][autoresize]',
  providers: [NgModel],
  host: {
    '(ngModelChange)': 'onInputChange($event)',
    '(keypress)': 'onKeyPressed($event)',
  },
})
export class AutoresizeDirective implements OnInit {
  element: any;
  offset: number;
  initialHeight: number;

  @Input() onEnter: Function = undefined;

  constructor(el: ElementRef, renderer: Renderer, private model: NgModel) {
    this.element = el.nativeElement;
    this.offset = this.element.offsetHeight - this.element.clientHeight;
  }

  ngOnInit() {
    this.initialHeight = this.element.offsetHeight;
  }

  onInputChange(event) {
    this.resizeTextarea();
    //this.model.valueAccessor.writeValue(event.toUpperCase());
  }

  onKeyPressed(event) {
    if (event.keyCode == 13) {
      if (this.onEnter !== undefined) {
        this.onEnter();
        jQuery(this.element).css('height', this.initialHeight);
        event.preventDefault();
      }
    }
  }

  resizeTextarea() {
    jQuery(this.element)
      .css('height', 'auto')
      .css('height', this.element.scrollHeight + this.offset);
  }
}
