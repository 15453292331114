import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "../classes/module/module";
import * as i2 from "../services/system/module.service";
import * as i3 from "@angular/router";
export class AppNotAvailableGuard {
    constructor(_moduleClass, _moduleService, _router) {
        this._moduleClass = _moduleClass;
        this._moduleService = _moduleService;
        this._router = _router;
    }
    canActivate(next, state) {
        return this._moduleService.getModuleStatus('')
            .pipe(map(moduleAvailability => {
            if (!environment.production) {
                this._router.navigate(['/']);
                return false;
            }
            if (this._moduleClass.moduleIsAvailable(moduleAvailability)) {
                this._router.navigate(['/']);
                return false;
            }
            else {
                return true;
            }
        }), catchError(() => {
            return of(true);
        }));
    }
}
AppNotAvailableGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppNotAvailableGuard_Factory() { return new AppNotAvailableGuard(i0.ɵɵinject(i1.Module), i0.ɵɵinject(i2.ModuleService), i0.ɵɵinject(i3.Router)); }, token: AppNotAvailableGuard, providedIn: "root" });
