import {
  Directive,
  HostListener,
  ElementRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';

declare var jQuery: any;

@Directive({
  selector: '[uppercaseOnly]',
})
export class UppercaseDirective {
  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event.target']) onEvent(input: HTMLInputElement) {
    const caretPos = input.selectionStart;
    this.control.control.setValue(input.value.toUpperCase());
    input.setSelectionRange(caretPos, caretPos)
  }
}

@Directive({
  selector: '[lowercaseOnly]',
})
export class LowercaseDirective {
  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event']) onEvent($event) {
    const lower = this.el.nativeElement.value.toLowerCase();
    this.control.control.setValue(lower);
  }
}

@Directive({
  selector: '[numbersOnly]',
})
export class NumbersOnlyDirective {
  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event']) onEvent($event) {
    const numbers = this.el.nativeElement.value.replace(/[^0-9]/g, '');
    this.control.control.setValue(numbers);
  }
}
