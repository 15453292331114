import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Service } from '../Service.js';
import { AppSettings } from '@app/helpers/app.settings';

@Injectable()
export class FollowService extends Service {
  constructor(private _http: HttpClient) {
    super();
  }

  makeFollow(data) {
    return this._http
      .post(
        this.API_ENDPOINT + 'profile/follow',
        data,
        this.APP_REQUEST_OPTIONS
      )
      .toPromise()
      .catch((error) => {
        console.log(error);
        console.log(error._body);
      });
  }
}
