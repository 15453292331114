
export default  {
  code: 'fae813785b45e4fd1ab76e2c25f30e2e',
  icon: 'credit-card',
  localEnabled: true,
  name: 'Débito Automático',
  url: 'recurring-payments',
  includeInNavigation: true,
  publishEnabled: true,
};
