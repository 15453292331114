/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../services/social/like.service";
import * as i3 from "@angular/common/http";
import * as i4 from "./like-preview.component";
var styles_LikePreviewComponent = [];
var RenderType_LikePreviewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LikePreviewComponent, data: {} });
export { RenderType_LikePreviewComponent as RenderType_LikePreviewComponent };
function View_LikePreviewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["class", "profile"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.user_info.image; _ck(_v, 1, 0, currVal_0); }); }
function View_LikePreviewComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "club-post-like-extra"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" y ", " m\u00E1s "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.likesCount - _co.likes.length); _ck(_v, 1, 0, currVal_0); }); }
export function View_LikePreviewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "club-post-like-preview"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LikePreviewComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LikePreviewComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.likes; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.likes && (_co.likesCount > _co.likes.length)); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_LikePreviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-like-preview", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).showDetail($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_LikePreviewComponent_0, RenderType_LikePreviewComponent)), i0.ɵprd(512, null, i2.LikeService, i2.LikeService, [i3.HttpClient]), i0.ɵdid(2, 114688, null, 0, i4.LikePreviewComponent, [i2.LikeService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var LikePreviewComponentNgFactory = i0.ɵccf("app-like-preview", i4.LikePreviewComponent, View_LikePreviewComponent_Host_0, { likes: "likes", likesCount: "likesCount", idPost: "idPost" }, {}, []);
export { LikePreviewComponentNgFactory as LikePreviewComponentNgFactory };
