<div class="fadeIn animated" *ngIf="user">
  <app-application-header
    [user]="user"
    (closeSession)="closeSession()"
    (showResponsiveMenu)="toggleMenuVisibility()"
    [isResponsiveMenuShown]="isResponsiveMenuShown"
  ></app-application-header>
  <div class="club-socio-wrap folded" [ngStyle]="contentWrapperStyles">
    <div
      class="club-socio-content"
      [ngClass]="{ activeNav: isResponsiveMenuShown }"
      style="width: 100%"
    >
      <app-application-lateral-side
        [user]="user"
        (closeSession)="closeSession()"
        (showResponsiveMenu)="toggleMenuVisibility()"
      ></app-application-lateral-side>
      <div class="club-socio-view" [ngStyle]="contentViewStyles">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <div id="main-loading" class="load-proccess-wrap">
    <div class="load-loading ro-loading">
      <span style="display: inline-block; margin-left: 5px">Procesando...</span>
    </div>
  </div>
</div>

<app-main-modal></app-main-modal>
<!-- modal -->
<div id="feedback-user-modal" class="uk-modal no-select">
  <div class="uk-modal-dialog uk-modal-dialog-small">
    <a class="uk-modal-close uk-close"></a>
    <app-feedback></app-feedback>
  </div>
</div>
