import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  RecurringPaymentsPageComponent
} from '@app/modules/recurring-payments/layout/recurring-payments-page/recurring-payments-page.component';
import {RecurringPaymentStatusComponent} from '@app/modules/recurring-payments/components/recurring-payment-status/recurring-payment-status.component';
import {RecurringPaymentAffiliationFormComponent} from '@app/modules/recurring-payments/components/recurring-payment-affiliation-form/recurring-payment-affiliation-form.component';
import {RecurringPaymentDisaffiliationFormComponent} from '@app/modules/recurring-payments/components/recurring-payment-disaffiliation-form/recurring-payment-disaffiliation-form.component';
import {RecurringPaymentResolverService} from '@app/modules/recurring-payments/resolvers/recurring-payment-resolver.service';
import {DisaffiliationFormGuard} from '@app/modules/recurring-payments/guards/disaffiliation-form.guard';


const routes: Routes = [
  {
    path: '',
    component: RecurringPaymentsPageComponent,
    resolve: {
      userAffiliation: RecurringPaymentResolverService
    },
    children: [
      {
        path: '',
        component: RecurringPaymentStatusComponent,
      },
      {
        path: 'affiliation',
        component: RecurringPaymentAffiliationFormComponent,
      },
      {
        path: 'disaffiliation',
        component: RecurringPaymentDisaffiliationFormComponent,
        canActivate: [ DisaffiliationFormGuard ],
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecurringPaymentsRoutingModule { }
