<ng-container *ngIf="!loading">
  <div class="tw-flex tw-flex-wrap tw--mx-2 tw-mt-2 fadeIn animated">
    <div class="tw-w-full tw-px-2 tw-mb-4" [ngClass]="[currentRowSize.left]">
      <ng-content select="[leftSide]"></ng-content>
    </div>
    <div class="tw-w-full tw-px-2 fadeIn animated tw-mb-4" [ngClass]="[currentRowSize.right]">
      <ng-content select="[rightSide]"></ng-content>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="loading">
  <app-flat-card >
    <div class="tw-my-32 fadeIn animated">
      <app-spinner message="Cargando módulo..."></app-spinner>
    </div>
  </app-flat-card>
</ng-container>
