/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inscription-note.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../services/academy/group.service";
import * as i4 from "@angular/common/http";
import * as i5 from "./inscription-note.component";
var styles_InscriptionNoteComponent = [i0.styles];
var RenderType_InscriptionNoteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InscriptionNoteComponent, data: {} });
export { RenderType_InscriptionNoteComponent as RenderType_InscriptionNoteComponent };
function View_InscriptionNoteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "inscription-note tw-p-4 !tw-text-sm tw-bg-gray-200 tw-mt-4 tw-rounded-md"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noteContent; _ck(_v, 1, 0, currVal_0); }); }
export function View_InscriptionNoteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InscriptionNoteComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noteContent; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_InscriptionNoteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-inscription-note", [], null, null, null, View_InscriptionNoteComponent_0, RenderType_InscriptionNoteComponent)), i1.ɵprd(512, null, i3.GroupService, i3.GroupService, [i4.HttpClient]), i1.ɵdid(2, 114688, null, 0, i5.InscriptionNoteComponent, [i3.GroupService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var InscriptionNoteComponentNgFactory = i1.ɵccf("app-inscription-note", i5.InscriptionNoteComponent, View_InscriptionNoteComponent_Host_0, { inscription: "inscription" }, {}, []);
export { InscriptionNoteComponentNgFactory as InscriptionNoteComponentNgFactory };
