/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../comment/comment.component.ngfactory";
import * as i2 from "../comment/comment.component";
import * as i3 from "@angular/common";
import * as i4 from "./comment-list.component";
var styles_CommentListComponent = [];
var RenderType_CommentListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CommentListComponent, data: {} });
export { RenderType_CommentListComponent as RenderType_CommentListComponent };
function View_CommentListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "club-post-more-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showMoreComments() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["Ver (", ") comentarios anteriores"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sliceStart; _ck(_v, 1, 0, currVal_0); }); }
function View_CommentListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-comment", [], null, null, null, i1.View_CommentComponent_0, i1.RenderType_CommentComponent)), i0.ɵdid(1, 114688, null, 0, i2.CommentComponent, [], { comment: [0, "comment"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CommentListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommentListComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CommentListComponent_2)), i0.ɵdid(3, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(0, i3.SlicePipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.sliceStart > 1); _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.commentList, _co.sliceStart, _co.sliceEnd)); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_CommentListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-comment-list", [], null, null, null, View_CommentListComponent_0, RenderType_CommentListComponent)), i0.ɵdid(1, 114688, null, 0, i4.CommentListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CommentListComponentNgFactory = i0.ɵccf("app-comment-list", i4.CommentListComponent, View_CommentListComponent_Host_0, { comments: "comments", count: "count" }, {}, []);
export { CommentListComponentNgFactory as CommentListComponentNgFactory };
