import { Component, OnInit } from '@angular/core';
import { Commission, PaymentService } from '@app/data-services/payment.service';
import { PEN } from '@app/helpers/currency-functions';
import { map, takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { OnDestroyService } from '@modules/shared/services/on-destroy.service';
import { Debt } from '@app/classes/payment/debt';

declare var jQuery: any;

@Component({
  selector: 'app-payment-cart',
  templateUrl: './payment-cart.component.html',
})
export class PaymentCartComponent implements OnInit {
  totalAmount: number;
  paymentList: Debt[] = [];
  commission: Commission;

  constructor(
    private paymentService: PaymentService,
    private destroy: OnDestroyService
  ) {}

  ngOnInit() {
    combineLatest([
      this.paymentService.totalWithCommission$,
      this.paymentService.paymentList$,
      this.paymentService.commission$,
    ])
      .pipe(takeUntil(this.destroy.onDestroy$))
      .subscribe(([totalAmount, paymentList, commission]) => {
        this.paymentList = paymentList;
        this.totalAmount = totalAmount;
        this.commission = commission;
      });
  }
}
