export class Member {
  public name: string = '';
  public lastName: string = '';
  public fullName: string = '';
  public number: string = '';
  public document: string = '';
  public state: string = '';
  public type: string = '';
  public sex: string = '';
  public relationship: string = '';
  public birthday: string = '';
  public age: number;
  public paymentPeriod: string = '';

  constructor(member?) {
    if (member !== undefined) {
      this.name =
        (member.NombrePrimero ? member.NombrePrimero : '') +
        ' ' +
        (member.NombreSegundo ? member.NombreSegundo : '');

      this.lastName =
        (member.ApellidoPaterno ? member.ApellidoPaterno : '') +
        ' ' +
        (member.ApellidoMaterno ? member.ApellidoMaterno : '');

      this.fullName = this.name + ' ' + this.lastName;

      this.number = member.IdSocio ? member.IdSocio : '';
      this.document = member.DNI ? member.DNI : '';

      this.state = member.Estado ? member.Estado : '';
      this.type = member.Tipo ? member.Tipo : '';
      this.sex = member.Sexo ? member.Sexo : '';
      this.relationship = member.Familiar ? member.Familiar : '';
      this.birthday = member.FechaNacimiento ? member.FechaNacimiento : '';
      this.paymentPeriod = member.PeriodoCuota ? member.PeriodoCuota : '';
      this.age = member.age !== undefined ? member.age : 0;
    }
  }
}
