import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SystemService } from '@app/services/system/system.service';
import { User } from '@app/classes/user/user';
import { Session } from '@app/classes/user/session';

declare var jQuery: any;

@Component({
  selector: 'app-search-page',
  providers: [SystemService],
  templateUrl: './search-page.component.html',
})
export class SearchPageComponent implements OnInit {
  constructor(
    private _routeService: ActivatedRoute,
    private _systemService: SystemService
  ) {}

  keywords = '';
  userResultList: any = [];
  dataSearch: any = [];
  loadingSearch = false;
  sessionUser: User = Session.getInstance().getUser();

  ngOnInit() {
    this._routeService.queryParams.forEach(params => {
      this.keywords = params.keywords;
      this.dataSearch.keywords = this.keywords;
      this._searchContent();
    });
  }

  _searchContent() {
    this.loadingSearch = true;

    this.dataSearch.key = this.sessionUser.key;
    this.dataSearch.id = this.sessionUser.id;

    this.dataSearch.limit = 20;
    this._systemService.searchContent(this.dataSearch).then(
      (response: any) => {
        this.userResultList = response.data.users;
        this.loadingSearch = false;
      },
      error => {
        console.error('No se pudo buscar', error);
        this.loadingSearch = false;
      }
    );
  }
}
