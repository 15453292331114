<div class="club-page-banner theme-1 mb-1">
  <div>
    <h1>Busca persona y Contenido</h1>
    <p>
      Busca a socio registrados en InterSocio, recuerda que deben tener su
      perfil público para poder verlos
    </p>
  </div>
</div>

<div class="club-socio-panel mb-1">
  <h3 *ngIf="!loadingSearch">
    <span class="uk-text-bold">Resultados para:</span> {{ keywords }}
  </h3>
  <h3 class="m-0" *ngIf="loadingSearch">Buscando...</h3>
</div>

<div
  class="club-socio-panel mb-1"
  style="padding: .5rem 1rem;margin-bottom: .4rem !important;"
>
  <h4 class="uk-text-bold">Socios</h4>
</div>

<div
  class="uk-grid uk-grid-small uk-grid-width-large-1-5 uk-grid-width-madium-1-4 mb-1"
>
  <div *ngFor="let user of userResultList" class="fadeIn animated">
    <app-profile-widget
      [userInfo]="user.user_info"
      [type]="2"
      [link]="true"
      [panelStyle]="true"
      [hover]="true"
    ></app-profile-widget>
  </div>
</div>

<div
  class="club-socio-panel mb-1"
  style="padding: .5rem 1rem;margin-bottom: .4rem !important;"
>
  <h4 class="uk-text-bold">Contenido</h4>
</div>
