import { Injectable } from '@angular/core';
import { isNil } from 'ramda';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DeepLinkService {

  constructor(private _router: Router) { }

  deepLinking() {
    const urlParams = new URLSearchParams(window.location.search);
    const gotoParam = urlParams.get('goto');
    if (!isNil(gotoParam)) {
      sessionStorage.setItem('gotoDeepLink', gotoParam);
    }
  }
  deepLinkingGoto() {
    const goto = sessionStorage.getItem('gotoDeepLink');
    if (!isNil(goto)) {
      sessionStorage.removeItem('gotoDeepLink');
      this._router.navigate(['/' + goto]);
    }
  }
}
