import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WebProfileRoutingModule } from './web-profile-routing.module';
import { ProfileSettingPageComponent } from '@app/modules/web-profile/components/profile-setting-page/profile-setting-page.component';
import { ProfileWebSettingComponent } from '@app/modules/web-profile/components/profile-web-setting/profile-web-setting.component';
import {FormsModule} from '@angular/forms';
import {SocialModule} from '@app/modules/social/social.module';
import {SharedModule} from '@app/modules/shared/shared.module';

@NgModule({
  declarations: [
    ProfileWebSettingComponent,
    ProfileSettingPageComponent,
  ],
  imports: [
    CommonModule,
    WebProfileRoutingModule,
    FormsModule,
    SocialModule,
    SharedModule,
  ]
})
export class WebProfileModule { }
