import { find, isNil, pipe, not, reject, propEq } from 'ramda';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AppSettings } from '@app/helpers/app.settings';
import { LOG_OUT_EVENT } from '@app/data-services/session.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const DEBT_KEYS = 'IS:SELECTED_DEBTS';
export class PaymentService {
    constructor(http) {
        this.http = http;
        this._paymentList$ = new BehaviorSubject(sessionStorage.getItem(DEBT_KEYS)
            ? JSON.parse(sessionStorage.getItem(DEBT_KEYS))
            : []);
        this.paymentList$ = this._paymentList$.asObservable().pipe(tap((payDebts) => {
            sessionStorage.setItem(DEBT_KEYS, JSON.stringify(payDebts));
        }));
        this.commission$ = combineLatest([
            this.paymentList$,
            this.getCommission(),
        ]).pipe(map(([payDebts, commissionResponse]) => {
            if (!commissionResponse)
                return null;
            const total = payDebts.reduce((prev, debt) => prev + debt.amount, 0);
            const commissionAmount = (commissionResponse.value / 100) * total;
            return {
                percentage: commissionResponse.value,
                description: commissionResponse.description,
                debt: {
                    serviceId: commissionResponse.service_id,
                    description: commissionResponse.name,
                    transactionId: commissionResponse.service_id,
                    amount: commissionAmount,
                },
            };
        }));
        this.totalWithCommission$ = combineLatest([
            this.paymentList$,
            this.commission$,
        ]).pipe(map(([payDebts, commission]) => ({
            total: payDebts.reduce((prev, debt) => prev + debt.amount, 0),
            commission,
        })), map(({ total, commission }) => {
            if (!commission)
                return total;
            return total + commission.debt.amount;
        }));
        window.addEventListener(LOG_OUT_EVENT, () => {
            this._paymentList$.next([]);
            sessionStorage.removeItem(DEBT_KEYS);
        });
    }
    static isEqualDebt(debt1, debt2) {
        return (debt1.id === debt2.id &&
            debt1.amount === debt2.amount &&
            debt1.description === debt2.description &&
            debt1.type === debt2.type);
    }
    addItem(item) {
        if (this.existInList(item)) {
            return;
        }
        this._paymentList$.next([...this._paymentList$.value, item]);
    }
    removeItem(item) {
        this._paymentList$.next(reject(propEq('id', item.id), this._paymentList$.value));
    }
    existInList(debt) {
        return pipe(find((listDebt) => PaymentService.isEqualDebt(listDebt, debt)), isNil, not)(this._paymentList$.value);
    }
    removeAllItems() {
        this._paymentList$.next([]);
    }
    getCommission() {
        return this.http
            .get(`${AppSettings.API_ENDPOINT_API_INTERSOCIO}payment_comissions`)
            .pipe(catchError(() => {
            console.error('An error occurred getting the commissions');
            return of(null);
        }));
    }
}
PaymentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentService_Factory() { return new PaymentService(i0.ɵɵinject(i1.HttpClient)); }, token: PaymentService, providedIn: "root" });
