import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-profile-widget',
  templateUrl: './profile-widget.component.html',
})
export class ProfileWidgetComponent implements OnInit {
  constructor() {}

  @Input() userInfo: any = {};
  @Input() mutedNext: any = undefined;
  @Input() text: any = undefined;
  @Input() type = 1;
  @Input() link = false;
  @Input() panelStyle = false;
  @Input() hover = false;

  profileLink: string = null;

  static getCustomComponentRendered(user_info) {
    let html = '';
    const userText = `<span class="uk-display-inlineblock uk-text-bold"> ${user_info.user} </span>`;
    const userLink = `<a class="uk-display-inlineblock uk-text-bold" href="/user/${user_info.user}"> ${user_info.user}</a>`;
    html += `<div class="club-profile-widget uk-clearfix">
                  <img src="${user_info.image}" class="profile-img">
                  <div class="club-profile-widget-content">
                     <div class="club-profile-widget-header">
                        ${!user_info.profile_public ? userText : userLink}
                     </div>
                  </div>
               </div>`;
    return html;
  }

  ngOnInit() {
    this.profileLink = this.link ? '/user/' + this.userInfo.user : null;
  }
}
