/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../profile-widget/profile-widget.component.ngfactory";
import * as i2 from "../profile-widget/profile-widget.component";
import * as i3 from "../../../../shared/components/ui-pack/flat-card/flat-card.component.ngfactory";
import * as i4 from "../../../../shared/components/ui-pack/flat-card/flat-card.component";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../services/auth/auth.service";
import * as i7 from "@angular/common/http";
import * as i8 from "@angular/router";
import * as i9 from "../../../../../data-services/session.service";
import * as i10 from "../../../../../services/social/profile.service";
import * as i11 from "./profile-popular.component";
var styles_ProfilePopularComponent = [];
var RenderType_ProfilePopularComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProfilePopularComponent, data: {} });
export { RenderType_ProfilePopularComponent as RenderType_ProfilePopularComponent };
function View_ProfilePopularComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "uk-width-large-1-2"], ["style", "margin-bottom: 10px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-profile-widget", [], null, null, null, i1.View_ProfileWidgetComponent_0, i1.RenderType_ProfileWidgetComponent)), i0.ɵdid(2, 114688, null, 0, i2.ProfileWidgetComponent, [], { userInfo: [0, "userInfo"], text: [1, "text"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.user_info; var currVal_1 = (_v.context.$implicit.total_follower + " seguidores"); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ProfilePopularComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "app-flat-card", [], [[8, "className", 0]], null, null, i3.View_FlatCardComponent_0, i3.RenderType_FlatCardComponent)), i0.ɵdid(1, 49152, null, 0, i4.FlatCardComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 2, "div", [["class", "uk-grid uk-grid-small"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfilePopularComponent_1)), i0.ɵdid(4, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.profilePopularList; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
export function View_ProfilePopularComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-profile-popular", [], null, null, null, View_ProfilePopularComponent_0, RenderType_ProfilePopularComponent)), i0.ɵprd(512, null, i6.AuthService, i6.AuthService, [i7.HttpClient, i8.Router, i9.SessionService]), i0.ɵprd(512, null, i10.ProfileService, i10.ProfileService, [i7.HttpClient]), i0.ɵdid(3, 114688, null, 0, i11.ProfilePopularComponent, [i6.AuthService, i10.ProfileService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var ProfilePopularComponentNgFactory = i0.ɵccf("app-profile-popular", i11.ProfilePopularComponent, View_ProfilePopularComponent_Host_0, { limit: "limit" }, {}, []);
export { ProfilePopularComponentNgFactory as ProfilePopularComponentNgFactory };
