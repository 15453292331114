import { Pipe, PipeTransform } from '@angular/core';
import { PEN, USD } from '@app/helpers/currency-functions';

@Pipe({
  name: 'money',
})
export class MoneyPipe implements PipeTransform {
  transform(value: any, currency: string = 'PEN'): any {
    const currencyMap = {
      PEN: PEN(value).format(),
      USD: USD(value).format(),
    };
    return currencyMap[currency] || value;
  }
}
