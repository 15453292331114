<div class="uk-grid uk-grid-small mb-1" *ngIf="appUser$ | async as appUser">
  <div class="uk-width-large-1-2 mb-05">
    <b>Nombres y Apellidos</b><br />
    <span
      >{{ appUser.clubMember.name }} {{ appUser.clubMember.last_name }}</span
    >
  </div>
  <div class="uk-width-large-1-2 mb-05">
    <b>Número de Socio</b><br />
    <span>{{ appUser.memberNumber }}</span>
  </div>
  <div class="uk-width-large-1-2 mb-05">
    <b>Usuario</b><br />
    <span>{{ appUser.username }}</span>
  </div>
  <div class="uk-width-large-1-2 mb-05">
    <b>Total a Pagar</b><br />
    <span>{{ totalPayment | money }}</span>
  </div>
</div>

<div class="uk-overflow-container" style="max-height: 60vh; overflow-y: auto">
  <div class="uk-clearfix">
    <div class="uk-float-left">
      <b>Resumen de Pago</b>
    </div>
    <div class="uk-float-right">
      <span
        >Se visualizan <b>{{ paymentList.length }}</b> elementos</span
      >
    </div>
  </div>
  <table
    class="uk-table uk-table-hover uk-table-striped uk-table-condensed club-socio-table m-0"
  >
    <thead>
      <tr>
        <th class="tw-w-5"></th>
        <th></th>
        <th>Descripción</th>
        <th>Monto</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of paymentList; let idx = index">
        <td class="tw-w-5">
          <button
            nz-button
            nzType="primary"
            (click)="removeItem(item)"
            *ngIf="validationRemove(idx)"
          >
            <i nz-icon nzType="delete" nzTheme="outline"></i>
          </button>
        </td>
        <td>
          <div
            *ngIf="item.siblingsIds?.length > 0"
            [ngStyle]="{ backgroundColor: item.groupColor }"
            class="tw-w-5 tw-h-5 tw-rounded-full"
          ></div>
        </td>
        <td class="tw-uppercase">
          {{ item.description }}
        </td>
        <td class="uk-text-right" style="width: 120px">
          {{ item.amount | money }}
        </td>
      </tr>
      <tr
        *ngFor="let commission of commissions"
        [style.background]="'antiquewhite'"
      >
        <td class="tw-w-5"></td>
        <td></td>
        <td class="tw-uppercase">
          <b>{{ commission.debt.description }}</b>
        </td>
        <td class="uk-text-right" style="width: 120px">
          {{ commission.debt.amount | money }}
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td></td>
        <td></td>
        <td class="uk-text-right uk-text-medium uk-text-normal">Total</td>
        <td class="uk-text-right uk-text-large uk-text-normal">
          <b>{{ totalPayment | money }}</b>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
