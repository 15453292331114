import { Component, Input, OnInit } from '@angular/core';
import { VisaService } from '@app/services/payment/visa.service';
import {PaymentResponse} from '@app/modules/payment/classes/payment-response';
import {NgRoDebugger} from '@modules/shared/classes/NgRoDebugger';

const HANDLED_ERROR_CODE = 400;

@Component({
  selector: 'app-payment-visa-response-authorization',
  providers: [VisaService],
  templateUrl: './payment-visa-response-authorization.component.html',
  styleUrls: ['./payment-visa-response-authorization.component.scss'],
})
export class PaymentVisaResponseAuthorizationComponent extends NgRoDebugger implements OnInit {
  isProcessing = false;
  hasError = false;
  isSuccess = false;
  @Input() transactionId: string;
  @Input() transactionToken: string;
  visaTransactionResponse: any = {};
  paymentResponse: any = {};
  constructor(private _visaService: VisaService) {
    super('PaymentVisaResponseAuthorizationComponent');
  }

  ngOnInit() {
    this.getAuthorization();
  }

  getAuthorization() {
    this.logger('Getting the authorization state');
    this.isProcessing = true;
    this._visaService
      .validateTransaction({
        transaction_id: this.transactionId,
        transactionToken: this.transactionToken,
      })
      .subscribe(
        (response: any) => {
          this.logger('Success authorization response');
          this.paymentResponse = new PaymentResponse(response);
          this.isSuccess = true;
          this.isProcessing = false;
        },
        responseError => {
          this.logger('Failed authorization response');
          const { status, error } = responseError;
          this.isProcessing = false;
          if (status === HANDLED_ERROR_CODE) {
            this.logger('Failed authorization response: Handled error');
            this.paymentResponse = new PaymentResponse(error);
            this.isSuccess = true;
          } else {
            this.hasError = true;
          }
        }
      );
  }
}
