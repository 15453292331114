<div id="intersocio-left-navigation" class="club-socio-nav" [ngStyle]="{ height: navHeight}">
    <div class="club-socio-profile-nav">
        <ul class="!tw-px-2 !tw-py-4 application-lateral-nav">
            <li class="tw-mb-1" *ngFor="let menu of menusList">
                <a
                    routerLink="/{{ menu.url }}"
                    [routerLinkActive]="['active']"
                    (click)="showResponsiveMenu.emit()"
                    class="tw-flex tw-justify-between tw-items-center tw-h-12 tw-px-4 tw-rounded-lg tw-text-white hover:tw-bg-gray-100"
                >
                    <div class="tw-flex tw-items-center tw-pr-1">
                        <i
                            nz-icon
                            [nzType]="menu.icon ? menu.icon : 'home'"
                            nzTheme="outline"
                            class="tw-inline-block tw-mr-2"
                        ></i>
                        <div class="tw-leading-none">
                            {{ menu.name }}
                        </div>
                    </div>
                    <nz-tag *ngIf="menu.new" [nzColor]="'#fa5a55'" class="tw-text-xs !tw-m-0 !tw-px-1"
                    >Nuevo</nz-tag
                    >
                </a>
            </li>
        </ul>
        <app-lateral-app-info></app-lateral-app-info>
    </div>
</div>
