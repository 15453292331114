import { Component, OnInit, Input } from '@angular/core';
import { FollowService } from '@app/services/social/follow.service';
import { AuthService } from '@app/services/auth/auth.service';
import { User } from '@app/classes/user/user';
import { Session } from '@app/classes/user/session';

@Component({
  selector: 'app-follow-btn',
  providers: [FollowService],
  templateUrl: './follow-btn.component.html',
})
export class FollowBtnComponent implements OnInit {
  constructor(
    private _followService: FollowService,
    private _authService: AuthService
  ) {}

  session: User;
  @Input() ownFollow: boolean;
  @Input() ownProfile: boolean;
  @Input() user: any;

  ngOnInit() {
    this.session = Session.getInstance().getUser();
  }

  makeFollow() {
    const data: any = {};
    data.key = this.session.key;
    data.id = this.session.id;
    data.user_session = this.session.user;
    data.user = this.user;

    if (this.ownFollow) { data.unfollow = true; }

    this._followService.makeFollow(data).then(
      (response: any) => {
        if (response.success) {
          if (data.unfollow) {
            this.ownFollow = false;
          } else {
            this.ownFollow = true;
          }
        }
      },
      error => {
        console.error(error);
      }
    );
  }
}
