import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-title-page',
  templateUrl: './title-page.component.html',
})
export class TitlePageComponent implements OnInit {
  constructor() {}

  @Input() title = '';
  @Input() description = '';
  @Input() icon = '';

  ngOnInit() {}
}
