import { Service } from '@app/services/Service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AccountVerificationService extends Service {
    constructor(_http) {
        super();
        this._http = _http;
    }
    sendEmailVerification() {
        return this._http.get(`${this.API_ENDPOINT_API_INTERSOCIO}user_email`);
    }
}
AccountVerificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountVerificationService_Factory() { return new AccountVerificationService(i0.ɵɵinject(i1.HttpClient)); }, token: AccountVerificationService, providedIn: "root" });
