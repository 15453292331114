export class User {
  public username: string = '';
  public user: string = '';
  private createdAt: string = '';
  public email: string = '';
  public number: string = '';
  public profileImg: string = '';
  public backgroundImg: string = '';
  public name: string = '';
  public lastName: string = '';
  public id: string = '';
  public key: string = '';
  public updatedAt: string = '';

  constructor(
    username?,
    email?,
    number?,
    profileImg?,
    backgroundImg?,
    name?,
    lastName?,
    id?,
    key?,
    updatedAt?,
    createdAt?
  ) {
    this.username = username;
    this.createdAt = createdAt;
    this.email = email;
    this.number = number;
    this.profileImg = profileImg;
    this.backgroundImg = backgroundImg;
    this.name = name;
    this.lastName = lastName;
    this.id = id;
    this.key = key;
    this.updatedAt = updatedAt;

    //tmp
    this.user = this.username;
  }
}
