import { Observable, of } from 'rxjs';
import { UserRecurringPayment } from '@app/modules/recurring-payments/classes/user-recurring-payment';
import { map, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../data/recurring-payments-store.service";
import * as i2 from "../services/recurring-payment.service";
export class RecurringPaymentResolverService {
    constructor(_recurringPaymentStore, _userRecurringPaymentService) {
        this._recurringPaymentStore = _recurringPaymentStore;
        this._userRecurringPaymentService = _userRecurringPaymentService;
    }
    resolve(route, state) {
        return this._userRecurringPaymentService.getUserRecurringPaymentInfo()
            .pipe(map(response => {
            this._recurringPaymentStore.setCurrentUserRecurringPayment(new UserRecurringPayment(response));
            return new UserRecurringPayment();
        }), catchError(error => {
            return of(new UserRecurringPayment());
        }));
    }
}
RecurringPaymentResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RecurringPaymentResolverService_Factory() { return new RecurringPaymentResolverService(i0.ɵɵinject(i1.RecurringPaymentsStoreService), i0.ɵɵinject(i2.RecurringPaymentService)); }, token: RecurringPaymentResolverService, providedIn: "root" });
