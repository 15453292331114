<app-module-wrapper>
  <div class="club-socio-page"  *ngIf="moduleIsActive">
    <app-module-layout-navigation>
      <ng-container leftSide>
        <app-flat-card>
          <h3 class="!tw-mb-4">{{ title + ' ' + currentPeriod.name }}</h3>
          <app-left-navigation [navigationItems]="navigation"></app-left-navigation>
        </app-flat-card>
      </ng-container>
      <ng-container rightSide>
        <router-outlet></router-outlet>
      </ng-container>
    </app-module-layout-navigation>
  </div>

  <div class="club-socio-page" *ngIf="moduleIsActive === false" >
    <app-message
      [message]="'El módulo de academias se encuentra en mantenimiento'"
      [subMessage]="'Estamos preprando las academias para un nuevo mes de Inscripciones, <br>  ' "
      [icon]="'cogs'"
    ></app-message>
  </div>
</app-module-wrapper>
