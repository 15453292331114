<p>
  Al usar el sistema de inscripciones web usted esta de acuerdo con los
  <a
    target="_blank"
    href="http://www.clubinter.org.pe/tyc/content/pre-inscripcion-academias"
  >Términos Y Condiciones
  </a>
  . Se le recomienda leer dicha información.
  <b>Recuerde NO HAY CAMBIOS NI DEVOLUCIONES NI RECUPERACIÓN DE CLASES.</b>
</p>
<nz-alert
  nzType="warning"
  nzDescription="Recuerda que la
      academia debe ser pagada antes de las 11:00 pm del mismo día o el cupo
      será liberado."
  nzShowIcon
>
</nz-alert>
