import { Component } from '@angular/core';
import { Commission, PaymentService } from '@app/data-services/payment.service';
import { Debt } from '@app/classes/payment/debt';
import { AuthService } from '@app/services/auth/auth.service';
import { filter, includes, forEach } from 'ramda';
import { takeUntil } from 'rxjs/operators';
import { OnDestroyService } from '@modules/shared/services/on-destroy.service';
import { combineLatest } from 'rxjs';
import { MemberService } from '@app/data-services/member.service';

declare var UIkit: any;

@Component({
  selector: 'app-payment-list',
  providers: [AuthService, OnDestroyService],
  templateUrl: './payment-list.component.html',
})
export class PaymentListComponent {
  totalPayment = 0;
  paymentList: Debt[] = [];
  commissions: Commission[] = null;

  appUser$ = this.memberService.appUser$;

  constructor(
    private paymentService: PaymentService,
    private destroyService: OnDestroyService,
    private memberService: MemberService
  ) {
    combineLatest([
      this.paymentService.paymentList$,
      this.paymentService.commission$,
      this.paymentService.totalWithCommission$,
    ])
      .pipe(takeUntil(this.destroyService.onDestroy$))
      .subscribe(([payDebts, commission, total]) => {
        this.totalPayment = total;
        this.paymentList = payDebts;
        this.commissions = [commission];
      });
  }

  removeItem(debt: Debt) {
    UIkit.modal.confirm(
      '<b>¿ Esta seguro que desea eliminar el elemento seleccionado ?</b>',
      () => {
        this.paymentService.removeItem(debt);
        const siblings = filter(
          (dbt: Debt) => includes(dbt.id, debt.siblingsIds),
          this.paymentList
        );
        forEach((dbt: Debt) => this.paymentService.removeItem(dbt), siblings);
      },
      { center: true }
    );
  }

  validationRemove(index: number) {
    return this.paymentList.length - 1 === index;
  }
}
