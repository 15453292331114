import { Component, OnInit } from '@angular/core';

import { Session } from '../../../../classes/user/session';
import { User } from '@app/classes/user/user';

import * as _ from 'lodash';
import { ProfileService } from '@app/services/social/profile.service';
import { AppSettings } from '@app/helpers/app.settings';

declare var UIkit: any;

@Component({
  selector: 'app-profile-web-setting',
  providers: [ProfileService],
  templateUrl: './profile-web-setting.component.html',
  styles: [
    `
      .club-hover-link {
        position: relative;
        display: block;
      }
      .club-hover-link:hover > div {
        opacity: 1;
      }
      .club-hover-link > div {
        display: flex;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        color: #fff;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        transition: all ease 0.5s;
      }
      .remove-social {
        position: absolute;
        top: 0;
        right: 0;
        background: #fff;
        padding: 2px 5px;
        margin: 4px;
        border-radius: 50%;
      }
    `,
  ],
})
export class ProfileWebSettingComponent implements OnInit {
  constructor(private _profileService: ProfileService) {}

  systemSession: Session;
  currentUser: User;

  profileWebData: any = {};
  socialLink: any = {};
  maxSocialLinks = 4;

  loadingProfileWebData = false;

  ngOnInit() {
    this.systemSession = Session.getInstance();
    this.currentUser = this.systemSession.getUser();

    this._getSocialProfileSetting();

    this.socialLink.url = '';
    this.profileWebData.socialLinks = [];
    this.profileWebData.social = [];
  }

  addSocialLink() {
    if (this.profileWebData.social.length > this.maxSocialLinks) { return; }
    if (this.socialLink.url == '' || this.socialLink.url == undefined) { return; }

    for (let i = 0; i < this.profileWebData.social.length; i++) {
      if (_.isEqual(this.profileWebData.social[i], this.socialLink)) { return;
    } }

    const link = _.clone(this.socialLink);
    this.profileWebData.social.push(link);
    this.socialLink.url = '';
  }

  removeSocialLink(link) {
    for (let i = 0; i < this.profileWebData.social.length; i++) {
      if (_.isEqual(this.profileWebData.social[i], link)) {
        this.profileWebData.social.splice(i, 1);
        return;
      }
    }
  }

  _getSocialProfileSetting() {
    const filter: any = {};
    filter.id = this.currentUser.id;
    filter.key = this.currentUser.key;
    filter.user = this.currentUser.user;

    this._profileService.getProfileSettings(filter).then(
      (response: any) => {
        if (response.data) {
          this.profileWebData = response.data;
          this.profileWebData.visible =
            response.data.visible == 1 ? true : false;
          this.profileWebData.social = JSON.parse(this.profileWebData.social);
        }
      },
      error => {
        console.error('No se pudo obtener la información.');
      }
    );
  }

  disabledProfile() {
    if (this.profileWebData.id_profile != undefined) {
      const dataProfile: any = {};
      dataProfile.visible = this.profileWebData.visible;
      dataProfile.id_profile = this.profileWebData.id_profile;

      this._updateProfileWeb(dataProfile);
    }
  }

  saveProfileWebData() {
    const dataProfile: any = _.clone(this.profileWebData);
    this._updateProfileWeb(
      dataProfile,
      () => {
        UIkit.notify(
          'La información fue guardada con éxito.',
          AppSettings.APP_NOTIFY_SUCCESS
        );
      },
      () => {
        UIkit.notify(
          'No se pudo guardar la información.',
          AppSettings.APP_NOTIFY_DANGER
        );
      }
    );
  }

  _updateProfileWeb(dataProfile, onSuccess?, onFailed?) {
    // adding extra info
    dataProfile.id = this.currentUser.id;
    dataProfile.key = this.currentUser.key;
    dataProfile.user = this.currentUser.user;

    this.loadingProfileWebData = true;
    this._profileService.updateProfile(dataProfile).then(
      (response: any) => {
        if (response.success) { onSuccess != undefined ? onSuccess() : null; }
        else { onFailed != undefined ? onFailed() : null; }

        this.loadingProfileWebData = false;
      },
      error => {
        console.error('No pudimos guardar la información', error);
        this.loadingProfileWebData = false;
      }
    );
  }
}
