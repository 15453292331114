import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Cookies from 'js-cookie';
import { PaymentConstants } from '@app/modules/payment/payment-constants';
import { NgRoDebugger } from '@modules/shared/classes/NgRoDebugger';
import { AnalyticsService } from '@app/data-services/analytics.service';

@Component({
  selector: 'app-payment-visa-response-page',
  templateUrl: './payment-visa-response-page.component.html',
  styleUrls: ['./payment-visa-response-page.component.scss'],
})
export class PaymentVisaResponsePageComponent
  extends NgRoDebugger
  implements OnInit
{
  title = 'Pagos en línea';
  transactionId: string;
  transactionToken: string;
  constructor(
    private _routeActive: ActivatedRoute,
    private analyticsService: AnalyticsService
  ) {
    super('PaymentVisaResponsePageComponent');
  }

  ngOnInit() {
    this.analyticsService.analytics.page({
      path: '/3961223659c486190',
      title: 'Visa response page',
    });
    const { TRANSACTION_ID, TRANSACTION_TOKEN } = PaymentConstants.COOKIE_NAMES;
    this.transactionId = Cookies.get(TRANSACTION_ID);
    this.transactionToken = Cookies.get(TRANSACTION_TOKEN);
    this.logger(
      `Loading payment visa response page [transactionId: ${this.transactionId}] [transactionToken: ${this.transactionToken}]`
    );
  }
}
