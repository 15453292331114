import { Service } from '@app/services/Service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TokenService extends Service {
    constructor(_http) {
        super();
        this._http = _http;
    }
    generateKey() {
        return this._http.get(`${this.API_ENDPOINT_API_INTERSOCIO}security_key`, this.APP_REQUEST_OPTIONS_AUTH);
    }
}
TokenService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TokenService_Factory() { return new TokenService(i0.ɵɵinject(i1.HttpClient)); }, token: TokenService, providedIn: "root" });
