/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../rating/rating.component.ngfactory";
import * as i2 from "../../rating/rating.component";
import * as i3 from "./recommendation-widget.component";
var styles_RecommendationWidgetComponent = [];
var RenderType_RecommendationWidgetComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RecommendationWidgetComponent, data: {} });
export { RenderType_RecommendationWidgetComponent as RenderType_RecommendationWidgetComponent };
export function View_RecommendationWidgetComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "club-recommendation-widget"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["src", "assets/img/logo_p_red.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "app-rating", [], null, null, null, i1.View_RatingComponent_0, i1.RenderType_RatingComponent)), i0.ɵdid(7, 114688, null, 0, i2.RatingComponent, [], { max: [0, "max"], full: [1, "full"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""]))], function (_ck, _v) { var currVal_1 = 5; var currVal_2 = true; _ck(_v, 7, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 4, 0, currVal_0); var currVal_3 = _co.content; _ck(_v, 9, 0, currVal_3); }); }
export function View_RecommendationWidgetComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-recommendation-widget", [], null, null, null, View_RecommendationWidgetComponent_0, RenderType_RecommendationWidgetComponent)), i0.ɵdid(1, 114688, null, 0, i3.RecommendationWidgetComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecommendationWidgetComponentNgFactory = i0.ɵccf("app-recommendation-widget", i3.RecommendationWidgetComponent, View_RecommendationWidgetComponent_Host_0, { title: "title", content: "content" }, {}, []);
export { RecommendationWidgetComponentNgFactory as RecommendationWidgetComponentNgFactory };
