<app-module-layout-navigation>
  <ng-container leftSide>
    <app-flat-card>
      <h2 class="!tw-mb-4">Débito Automático</h2>
      <app-recurring-payment-nav></app-recurring-payment-nav>
    </app-flat-card>
  </ng-container>
  <ng-container rightSide>
    <router-outlet></router-outlet>
  </ng-container>
</app-module-layout-navigation>

