import { Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OnDestroyService implements OnDestroy {
  private destroy$ = new Subject<void>();

  get onDestroy$() {
    return this.destroy$.asObservable();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
