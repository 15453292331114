/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lateral-app-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./lateral-app-info.component";
var styles_LateralAppInfoComponent = [i0.styles];
var RenderType_LateralAppInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LateralAppInfoComponent, data: {} });
export { RenderType_LateralAppInfoComponent as RenderType_LateralAppInfoComponent };
export function View_LateralAppInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "tw-absolute tw-bottom-0 tw-text-center tw-p-1 tw-right-0 tw-left-0 tw-text-white"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " v", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.app.name; var currVal_1 = _co.app.version; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_LateralAppInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lateral-app-info", [], null, null, null, View_LateralAppInfoComponent_0, RenderType_LateralAppInfoComponent)), i1.ɵdid(1, 114688, null, 0, i2.LateralAppInfoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LateralAppInfoComponentNgFactory = i1.ɵccf("app-lateral-app-info", i2.LateralAppInfoComponent, View_LateralAppInfoComponent_Host_0, {}, {}, []);
export { LateralAppInfoComponentNgFactory as LateralAppInfoComponentNgFactory };
