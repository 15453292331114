import { propOr, always, tryCatch, includes, find, propEq, values, equals, isEmpty } from 'ramda';
import RecurringPaymentConstants from '@app/modules/recurring-payments/recurring-payment-constants';

export class UserRecurringPayment {

  id: string;
  cardBrand: string;
  email: string;
  cardNumber: string;
  maskedCardNumber: string;
  expirationDate: string;
  cardHolder: string;
  dniCardHolder: string;
  linkedAssociateNumbers: Array<any>;
  status: string;
  createdAt: string;
  updatedAt: string;
  updateCardNumber: boolean;
  aheadFee: boolean;
  notifyByEmail: boolean;
  maxDebitAmount: any;

  constructor(recurringPayment?) {
    const getFromRecord = (defaultVal, field) => {
      const fieldValue = propOr(defaultVal, field, recurringPayment);
      return tryCatch(val => JSON.parse(val), always(fieldValue))(fieldValue) || defaultVal;
    };
    this.id = getFromRecord('', 'id');
    this.status = getFromRecord(RecurringPaymentConstants.recurringPaymentStates.DISAFFILIATED.code, 'status');
    this.cardBrand = getFromRecord('', 'card_brand');
    this.email = getFromRecord('', 'email');
    this.cardNumber = getFromRecord('', 'card_number');
    this.maskedCardNumber = getFromRecord('', 'masked_card_number');
    this.expirationDate = getFromRecord('', 'expiration_date');
    this.cardHolder = getFromRecord('', 'card_holder');
    this.dniCardHolder = getFromRecord('', 'dni_card_holder');
    this.linkedAssociateNumbers = getFromRecord([], 'linked_associate_numbers');
    this.updateCardNumber = getFromRecord(false, 'update_card_number');
    this.aheadFee = getFromRecord(false, 'ahead_fee');
    this.notifyByEmail = getFromRecord(false, 'notify_by_email');
    this.maxDebitAmount = getFromRecord(200.00, 'max_debit_amount');
    this.createdAt = getFromRecord('', 'created_at');
    this.updatedAt = getFromRecord('', 'updated_at');
  }

  get isAffiliated() {
    return equals(this.status, RecurringPaymentConstants.recurringPaymentStates.AFFILIATED.code);
  }

  get isDisaffiliated() {
    return equals(this.status, RecurringPaymentConstants.recurringPaymentStates.DISAFFILIATED.code) || isEmpty(this.status);
  }

  get isProcessingRequest() {
    const {
      REQUEST_AFFILIATION, REQUEST_UPDATE, REQUEST_DISAFFILIATION, EXPORTED, UPDATING_EXPORTED, DIS_EXPORTED, REQUEST_NEW_UPDATE
    } = RecurringPaymentConstants.recurringPaymentStates;
    return includes(this.status, [
      REQUEST_AFFILIATION.code,
      REQUEST_UPDATE.code, REQUEST_NEW_UPDATE.code,
      REQUEST_DISAFFILIATION.code, EXPORTED.code, UPDATING_EXPORTED.code, DIS_EXPORTED.code,
    ]);
  }

  get isRejected() {
    return equals(this.status, RecurringPaymentConstants.recurringPaymentStates.REJECTED.code);
  }

  get localState() {
    return find(propEq('code', this.status), values(RecurringPaymentConstants.recurringPaymentStates));
  }
}
