import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Service } from '../Service.js';
import { AppSettings } from '@app/helpers/app.settings';

@Injectable()
export class GroupService extends Service {
  constructor(private _http: HttpClient) {
    super();
  }

  getAvailables(academy) {
    return this._http.get(
      this.API_ENDPOINT_API_INTERSOCIO + 'academies/' + academy,
      this.APP_REQUEST_OPTIONS_AUTH
    );
  }

  getAcademy(academyId) {
    return this._http.get( `${this.API_ENDPOINT_API_INTERSOCIO}academies/${academyId}`);
  }
}
