import { propOr } from 'ramda';
import * as moment from 'moment';

export class NiubizResponse {
  currencyCode: string;
  transactionDate: string;
  transactionDateFormatted: string;
  actionCode: string;
  actionDescription: string;
  eciDescription: string;
  brand: string;
  cardNumber: string;
  status: string;
  id: string;
  amount: string;
  transactionId: string;
  processCode: string;
  authorizationCode: string;

  constructor( response? ) {
    const getFrom = (defaultValue, field) => propOr(defaultValue, field, response);
    this.currencyCode = getFrom('', 'CURRENCY');
    this.transactionDate = getFrom('', 'TRANSACTION_DATE');
    this.transactionDateFormatted = moment(this.transactionDate, 'x').format('DD MMM YYYY hh:mm a');
    this.actionCode = getFrom('', 'ACTION_CODE');
    this.actionDescription = getFrom('', 'ACTION_DESCRIPTION');
    this.eciDescription = getFrom('', 'ECI_DESCRIPTION');
    this.brand = getFrom('', 'CARD');
    this.cardNumber = getFrom('', 'CARD');
    this.status = getFrom('', 'STATUS');
    this.id = getFrom('', 'ID_UNICO');
    this.amount = getFrom('', 'AMOUNT');
    this.transactionId = getFrom('', 'TRANSACTION_ID');
    this.processCode = getFrom('', 'PROCESS_CODE');
    this.authorizationCode = getFrom('', 'AUTHORIZATION_CODE');
  }
}
