<div class="club-socio-panel">
  <h4 class="mb-1"><b>Recomendaciones</b></h4>

  <div class="club-recommendation-list">
    <app-recommendation-widget
      [title]="'Consulta de Transacciones'"
      [content]="
              'Recuerda que puedes consultas la respuestas de tus transacciones en InterSocio en la pestaña Pagos Web'
            "
    >
    </app-recommendation-widget>
    <app-recommendation-widget
      [title]="'Transacciones Fallidas'"
      [content]="
              'Recuerda revisar el campo Respuesta de VISA en caso tener una respuesta fallida, en esta se mostrará el motivo. '
            "
    >
    </app-recommendation-widget>
  </div>
</div>
