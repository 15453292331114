<div
  *ngIf="wrap == 'normal'"
  [ngClass]="{ 'uk-grid uk-grid-small uk-grid-width-large-1-5': grid == '5' }"
>
  <app-post
    *ngFor="let post of postList"
    [post]="post"
    [onClickImage]="showImageClick"
  ></app-post>
</div>

<div
  *ngIf="wrap == 'gallery'"
  [ngClass]="{ 'uk-grid uk-grid-small uk-grid-width-large-1-5': grid == '5' }"
>
  <app-profile-gallery-image
    *ngFor="let post of postList"
    [post]="post"
    [onClickImage]="showImageClick"
  ></app-profile-gallery-image>
</div>

<div class="uk-text-center club-socio-panel club-post" *ngIf="loadingPosts">
  Cargando más publicaciones...
</div>

<!-- modal -->
<div id="post-image-modal" class="uk-modal no-select club-post-modal">
  <div
    class="uk-modal-dialog uk-modal-dialog-medium"
    style="padding: 0px !important;"
  >
    <a class="uk-modal-close uk-close" style="background: #fff;"></a>
    <div class="uk-grid uk-grid-collapse">
      <div class="uk-width-large-3-5 uk-width-medium-3-5">
        <div class="image-wrapper">
          <div class="image-wrapper-img">
            <img
              [src]="currentPost.post_info.image"
              *ngIf="currentPost.post_info"
              class="uk-width-1-1"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="uk-width-large-2-5 uk-width-medium-2-5">
        <app-post [post]="currentPost" [modalContent]="true"></app-post>
      </div>
    </div>
  </div>
</div>
