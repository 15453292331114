import { Component, OnInit } from '@angular/core';
import {AppSettings} from '@app/helpers/app.settings';

@Component({
  selector: 'app-lateral-app-info',
  templateUrl: './lateral-app-info.component.html',
  styleUrls: ['./lateral-app-info.component.scss']
})
export class LateralAppInfoComponent implements OnInit {
  app: any = {};
  constructor() { }

  ngOnInit() {
    this.app = AppSettings.APP_INFO;
  }

}
