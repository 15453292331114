/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../services/social/like.service";
import * as i3 from "@angular/common/http";
import * as i4 from "./like-btn.component";
import * as i5 from "../../../../../services/auth/auth.service";
var styles_LikeBtnComponent = [];
var RenderType_LikeBtnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LikeBtnComponent, data: {} });
export { RenderType_LikeBtnComponent as RenderType_LikeBtnComponent };
function View_LikeBtnComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "club-post-own-like"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.likesCount; _ck(_v, 1, 0, currVal_0); }); }
export function View_LikeBtnComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "club-post-like-wrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "club-post-like-icon in-post"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.makeLike($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(4, { liked: 0, is_animating: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LikeBtnComponent_1)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "club-post-like-icon in-post"; var currVal_1 = _ck(_v, 4, 0, _co.ownLike, _co.ownLike); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = (_co.likesCount > 0); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_LikeBtnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-like-btn", [], null, null, null, View_LikeBtnComponent_0, RenderType_LikeBtnComponent)), i0.ɵprd(512, null, i2.LikeService, i2.LikeService, [i3.HttpClient]), i0.ɵdid(2, 114688, null, 0, i4.LikeBtnComponent, [i2.LikeService, i5.AuthService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var LikeBtnComponentNgFactory = i0.ɵccf("app-like-btn", i4.LikeBtnComponent, View_LikeBtnComponent_Host_0, { ownLike: "ownLike", likesCount: "likesCount", postId: "postId" }, { onLiked: "onLiked" }, []);
export { LikeBtnComponentNgFactory as LikeBtnComponentNgFactory };
