

<ng-container *ngFor="let banner of banners" >
    <div *ngIf="!banner.isExternal"  class="tw-cursor-pointer tw-rounded tw-overflow-hidden tw-mb-4 tw-shadow tw-block" [routerLink]="banner.linkUrl">
        <img [src]="banner.imageUrl" class="tw-w-full" alt="intersocio">
    </div>
    <a *ngIf="banner.isExternal" [href]="banner.linkUrl" [target]="banner.target"  class="tw-cursor-pointer tw-rounded tw-overflow-hidden tw-mb-4 tw-shadow tw-block" >
        <img [src]="banner.imageUrl" class="tw-w-full" alt="intersocio">
    </a>
</ng-container>
