/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-terms-and-conditions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./payment-terms-and-conditions.component";
var styles_PaymentTermsAndConditionsComponent = [i0.styles];
var RenderType_PaymentTermsAndConditionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentTermsAndConditionsComponent, data: {} });
export { RenderType_PaymentTermsAndConditionsComponent as RenderType_PaymentTermsAndConditionsComponent };
export function View_PaymentTermsAndConditionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "mt-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["href", "http://www.clubinter.org.pe/tyc/content/pagos-en-linea"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["T\u00E9rminos y condiciones"])), (_l()(), i1.ɵted(-1, null, [" de uso del sistema de pagos del Club Internacional Arequipa\n"]))], null, null); }
export function View_PaymentTermsAndConditionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-terms-and-conditions", [], null, null, null, View_PaymentTermsAndConditionsComponent_0, RenderType_PaymentTermsAndConditionsComponent)), i1.ɵdid(1, 114688, null, 0, i2.PaymentTermsAndConditionsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentTermsAndConditionsComponentNgFactory = i1.ɵccf("app-payment-terms-and-conditions", i2.PaymentTermsAndConditionsComponent, View_PaymentTermsAndConditionsComponent_Host_0, {}, {}, []);
export { PaymentTermsAndConditionsComponentNgFactory as PaymentTermsAndConditionsComponentNgFactory };
