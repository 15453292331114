import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentRoutingModule } from './payment-routing.module';
import { PaymentPageComponent } from './layout/payment-page/payment-page.component';
import { PaymentVISAComponent } from '@app/modules/payment/components/payment-visa/payment-visa.component';
import { PaymentVisaResponseComponent } from '@app/modules/payment/components/payment-visa/payment-visa-response/payment-visa-response.component';
import { PaymentMethodsComponent } from '@app/modules/payment/components/payment-methods/payment-methods.component';
import { PaymentListComponent } from '@app/modules/payment/components/payment-list/payment-list.component';
import { PaymentCartComponent } from '@app/modules/payment/components/payment-cart/payment-cart.component';
import { SharedModule } from '@app/modules/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { PaymentStepperComponent } from './components/payment-stepper/payment-stepper.component';
import { PaymentRecomendationsComponent } from './components/payment-recomendations/payment-recomendations.component';
import { PaymentVisaTimeoutComponent } from './layout/payment-visa-timeout/payment-visa-timeout.component';
import { PaymentVisaResponseAuthorizationComponent } from './components/payment-visa/payment-visa-response-authorization/payment-visa-response-authorization.component';
import { PaymentVisaResponsePageComponent } from './layout/payment-visa-response-page/payment-visa-response-page.component';
import { PaymentVisaResponseSuccessComponent } from './components/payment-visa/payment-visa-response-success/payment-visa-response-success.component';
import { PaymentVisaResponseErrorComponent } from './components/payment-visa/payment-visa-response-error/payment-visa-response-error.component';
import { PaymentTermsAndConditionsComponent } from './components/payment-visa/terms-and-conditions/payment-terms-and-conditions.component';
import {
  NzBadgeModule,
  NzButtonModule,
  NzEmptyModule,
  NzIconModule,
} from 'ng-zorro-antd';

@NgModule({
  declarations: [
    PaymentPageComponent,
    PaymentPageComponent,
    PaymentVISAComponent,
    PaymentVisaResponseComponent,
    PaymentMethodsComponent,
    PaymentListComponent,
    PaymentCartComponent,
    PaymentStepperComponent,
    PaymentRecomendationsComponent,
    PaymentVisaTimeoutComponent,
    PaymentVisaResponseAuthorizationComponent,
    PaymentVisaResponsePageComponent,
    PaymentVisaResponseSuccessComponent,
    PaymentVisaResponseErrorComponent,
    PaymentTermsAndConditionsComponent,
  ],
  imports: [
    CommonModule,
    PaymentRoutingModule,
    SharedModule,
    FormsModule,
    NzIconModule,
    NzBadgeModule,
    NzButtonModule,
    NzEmptyModule,
  ],
  exports: [PaymentCartComponent],
})
export class PaymentModule {}
