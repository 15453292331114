import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
})
export class MessageComponent implements OnInit {
  constructor() {}

  iconClass = '';

  @Input() message = '';
  @Input() subMessage = '';
  @Input() icon: string = undefined;

  ngOnInit() {
    if (this.icon) {
      switch (this.icon) {
        case 'cart':
          this.iconClass = 'shopping-cart';
          break;
        case 'smile':
          this.iconClass = 'smile-o';
          break;
        case 'cogs':
          this.iconClass = 'cogs';
          break;
        case 'lock':
          this.iconClass = 'lock';
          break;
        case 'meh':
          this.iconClass = 'meh-o';
          break;
        default:
          this.iconClass = this.icon;
      }
    }
  }
}
