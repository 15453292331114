import {Session} from '@app/classes/user/session';
import {HttpHeaders} from '@angular/common/http';
import {AppSettings} from '@app/helpers/app.settings';
import { environment } from '@env/environment';

export class Service {
  protected get API_ENDPOINT_SOCIAL() {
    return environment.baseUrlSlim;
  }
  protected get API_ENDPOINT() {
    return AppSettings.API_ENDPOINT;
  }
  protected get APP_REQUEST_OPTIONS() {
    return AppSettings.APP_REQUEST_OPTIONS;
  }
  protected get API_ENDPOINT_API_INTERSOCIO() {
    return AppSettings.API_ENDPOINT_API_INTERSOCIO;
  }
  protected get API_ENDPOINT_API_INTER() {
    return AppSettings.API_ENDPOINT_API_INTER;
  }
  protected get APP_REQUEST_OPTIONS_AUTH() {
    const systemSession = Session.getInstance();
    const headers: HttpHeaders = new HttpHeaders({
      authorization: systemSession.getSessionAuthorization() || '',
    });
    return {
      headers
    };
  }
}
