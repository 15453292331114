import { User } from './user';
import { AppSettings } from '@app/helpers/app.settings';
import { Notifier } from '@app/classes/notifier/notifier';

/**
 * @deprecated Not use it for new implementation, use Session service instead
 */
export class Session {
  private static instance: Session;

  private notifier: Notifier = new Notifier();

  private sessionUser: User;
  private authorization: string;

  private constructor() {}

  static getInstance() {
    if (!Session.instance) {
      Session.instance = new Session();
    }
    return Session.instance;
  }

  // getting
  public getUser() {
    return this.sessionUser;
  }

  // creating session
  public createSession(sessionStored, local?: boolean) {
    if (!local) {
      this.sessionUser = new User(
        sessionStored.user.username,
        sessionStored.user.email,
        sessionStored.user.member_number,
        sessionStored.user.profile_img,
        sessionStored.user.background_img,
        sessionStored.user.name,
        sessionStored.user.last_name,
        sessionStored.user.id_hash,
        sessionStored.user.key,
        sessionStored.user.updated_at,
        sessionStored.user.created_at
      );
    }
    if (local) {
      this.sessionUser = new User(
        sessionStored.user.username,
        sessionStored.user.email,
        sessionStored.user.number,
        sessionStored.user.profileImg,
        sessionStored.user.backgroundImg,
        sessionStored.user.name,
        sessionStored.user.lastName,
        sessionStored.user.id,
        sessionStored.user.key,
        sessionStored.user.updatedAt,
        sessionStored.user.createdAt
      );
    }

    if (sessionStored.authorization !== undefined) {
      this.authorization = sessionStored.authorization;
    }

    sessionStorage.setItem(
      AppSettings.APP_SESSION_NAME,
      JSON.stringify(this._prepareSessionToSave())
    );

    return this.sessionUser;
  }

  public isLogged() {
    if (sessionStorage.getItem(AppSettings.APP_SESSION_NAME) === null) {
      return false;
    } else {
      const sessionStored = JSON.parse(
        sessionStorage.getItem(AppSettings.APP_SESSION_NAME)
      );
      this.createSession(sessionStored, true);
      return true;
    }
  }

  public clearSession() {
    sessionStorage.removeItem(AppSettings.APP_SESSION_NAME);
    this.authorization = '';
    this.sessionUser = new User();
    this.notifier = new Notifier();
  }

  public getSessionAuthorization() {
    return this.authorization;
  }

  private _prepareSessionToSave() {
    return {
      user: this.sessionUser,
      authorization: this.authorization,
    };
  }
}
