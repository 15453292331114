import { Injectable } from '@angular/core';
import {Service} from '@app/services/Service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TokenService extends Service{

  constructor(
    private _http: HttpClient
  ) {
    super();
  }

  generateKey() {
    return this._http.get(`${this.API_ENDPOINT_API_INTERSOCIO}security_key`, this.APP_REQUEST_OPTIONS_AUTH);
  }
}
