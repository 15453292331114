<div class="club-post-like-wrap">
  <div
    class="club-post-like-icon in-post"
    [ngClass]="{ liked: ownLike, is_animating: ownLike }"
    (click)="makeLike($event)"
  ></div>
  <span class="club-post-own-like" *ngIf="likesCount > 0">
    {{ likesCount }}
  </span>
</div>
