import { Component, Input, OnInit } from '@angular/core';
import { PaymentService } from '@app/data-services/payment.service';
import { PaymentConstants } from '@app/modules/payment/payment-constants';
import { has } from 'ramda';

@Component({
  selector: 'app-payment-stepper',
  templateUrl: './payment-stepper.component.html',
  styleUrls: ['./payment-stepper.component.scss'],
})
export class PaymentStepperComponent implements OnInit {
  currentStep: any = {};
  currentStepIndex = 0;
  steps: any = PaymentConstants.STEPS;

  total$ = this.paymentService.totalWithCommission$;

  constructor(private paymentService: PaymentService) {}

  ngOnInit() {
    this.currentStep = this.steps[this.currentStepIndex];
  }

  goNextStep() {
    if (!has(this.currentStepIndex + 1)(this.steps)) {
      return;
    }

    this.steps[this.currentStepIndex].solved = true;
    this.currentStepIndex++;
    this.currentStep = this.steps[this.currentStepIndex];
  }

  goBackStep() {
    if (!has(this.currentStepIndex - 1)(this.steps)) {
      return;
    }

    this.currentStepIndex--;
    this.steps[this.currentStepIndex].solved = false;
    this.currentStep = this.steps[this.currentStepIndex];

    this.steps[1].disabled = true;
  }

  enableStep = (value) => {
    this.steps[1].disabled = value;
  };
}
