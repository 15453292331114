/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./modules/shared/components/update-app-version/update-app-version.component.ngfactory";
import * as i2 from "./modules/shared/components/update-app-version/update-app-version.component";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./modules/application/services/deep-link.service";
import * as i6 from "./app.component";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-update-app-version", [], null, [[null, "updateButtonClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("updateButtonClicked" === en)) {
        var pd_0 = (_co.onUpdateAppClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_UpdateAppVersionComponent_0, i1.RenderType_UpdateAppVersionComponent)), i0.ɵdid(1, 114688, null, 0, i2.UpdateAppVersionComponent, [], null, { updateButtonClicked: "updateButtonClicked" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.showUpdateVersionSnack; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵprd(512, null, i5.DeepLinkService, i5.DeepLinkService, [i3.Router]), i0.ɵdid(2, 49152, null, 0, i6.AppComponent, [i3.Router, i5.DeepLinkService], null, null)], null, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
