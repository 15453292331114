import { Service } from '../services/Service';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const resolveUserInfo = (raw) => (Object.assign({}, raw, { backgroundImg: raw.background_img, createdAt: raw.created_at, updatedAt: raw.updated_at, memberNumber: raw.member_number, visaUserToken_id: raw.visa_user_token_id, emailConfirmed: raw.email_confirmed, profileImg: raw.profile_img, clubMember: raw.club_member }));
const ɵ0 = resolveUserInfo;
export class MemberService extends Service {
    constructor(_http) {
        super();
        this._http = _http;
        this._appUser$ = new BehaviorSubject(null);
        this.appUser$ = this._appUser$.asObservable();
    }
    getMembershipInfo() {
        return this._http.get(this.API_ENDPOINT_API_INTERSOCIO + 'membership/index', this.APP_REQUEST_OPTIONS_AUTH);
    }
    getUserInfo() {
        return this._http
            .get(this.API_ENDPOINT_API_INTERSOCIO + 'user', this.APP_REQUEST_OPTIONS_AUTH)
            .pipe(tap((appUser) => {
            this._appUser$.next(resolveUserInfo(appUser));
        }));
    }
    updateUserInfo(data) {
        return this._http.put(this.API_ENDPOINT_API_INTERSOCIO + 'users', { user: data }, this.APP_REQUEST_OPTIONS_AUTH);
    }
    updateUserPassword(data) {
        return this._http.put(this.API_ENDPOINT_API_INTERSOCIO + 'user/password_verified', { user: data }, this.APP_REQUEST_OPTIONS_AUTH);
    }
}
MemberService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MemberService_Factory() { return new MemberService(i0.ɵɵinject(i1.HttpClient)); }, token: MemberService, providedIn: "root" });
export { ɵ0 };
