<ul class="club-rating" [ngClass]="{ 'club-rating-selectable': selectable }">
  <li
    class="uk-icon"
    *ngFor="let star of starList; let idx = index"
    [ngClass]="{
      'club-rating-large': size == 'large',
      'uk-icon-star': (idx <= selectStar && selectable) || star.full,
      'uk-icon-star-o':
        (idx > selectStar && selectable) || (!star.full && !selectable)
    }"
    (click)="selectRatingItem(idx)"
  ></li>
</ul>
