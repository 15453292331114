/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "./payment-methods.component";
var styles_PaymentMethodsComponent = [];
var RenderType_PaymentMethodsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PaymentMethodsComponent, data: {} });
export { RenderType_PaymentMethodsComponent as RenderType_PaymentMethodsComponent };
export function View_PaymentMethodsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.DecimalPipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Total a Pagar"])), (_l()(), i0.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "uk-text-large"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["S/ ", ""])), i0.ɵppd(8, 2), (_l()(), i0.ɵeld(9, 0, null, null, 4, "div", [["class", "mt-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Para poder seguir con el proceso pago debe aceptar nuestros t\u00E9rminos y condiciones, "])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["recuerde leerlos con detenimiento antes."])), (_l()(), i0.ɵeld(14, 0, null, null, 13, "div", [["class", "mt-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 12, "div", [["class", "club-checkbox"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 5, "input", [["id", "tac"], ["name", "accept"], ["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i0.ɵnov(_v, 17).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 17).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.accept = $event) !== false);
        ad = (pd_2 && ad);
    } if (("ngModelChange" === en)) {
        var pd_3 = (_co.updateAccept() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(17, 16384, null, 0, i2.CheckboxControlValueAccessor, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i0.ɵdid(19, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(21, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i0.ɵeld(22, 0, null, null, 5, "label", [["c", ""], ["class", "uk-width-1-1 no-select"], ["for", "tac"]], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 4, "b", [["style", "font-size: 15px;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Acepto los "])), (_l()(), i0.ɵeld(25, 0, null, null, 1, "a", [["href", "http://www.clubinter.org.pe/tyc/content/pagos-en-linea"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["t\u00E9rminos y condiciones"])), (_l()(), i0.ɵted(-1, null, [" del proceso de pago que establece el Club Internacional Arequipa."]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = "accept"; var currVal_9 = _co.accept; _ck(_v, 19, 0, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i0.ɵnov(_v, 0), _co.totalPayment, "1.2-2")); _ck(_v, 7, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 21).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 21).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 21).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 21).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 21).ngClassValid; var currVal_6 = i0.ɵnov(_v, 21).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 21).ngClassPending; _ck(_v, 16, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_PaymentMethodsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-payment-methods", [], null, null, null, View_PaymentMethodsComponent_0, RenderType_PaymentMethodsComponent)), i0.ɵdid(1, 114688, null, 0, i3.PaymentMethodsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentMethodsComponentNgFactory = i0.ɵccf("app-payment-methods", i3.PaymentMethodsComponent, View_PaymentMethodsComponent_Host_0, { onAcceptTerms: "onAcceptTerms", totalPayment: "totalPayment" }, {}, []);
export { PaymentMethodsComponentNgFactory as PaymentMethodsComponentNgFactory };
