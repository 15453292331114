<app-flat-card class="tw-block tw-mb-1">
  <app-inscription-information></app-inscription-information>
</app-flat-card>

<app-flat-card class="tw-block tw-mb-1">
  <app-inscription-step-title
    step="1"
    title="Elige los datos de la inscripción"
  >
  </app-inscription-step-title>
  <div>
    <form
      class="uk-form uk-form-stacked"
      ng-submit="insertDataPreInscription()"
    >
      <div class="uk-form-row margin">
        <div class="uk-grid">
          <div class="uk-width-large-1-1">
            <label class="uk-form-label">Escoja al socio a inscribir</label>
            <div class="uk-form-controls">
              <nz-select
                [(ngModel)]="selectedMember"
                name="selectedMember"
                class="uk-width-1-1"
                [disabled]="members.length < 1"
              >
                <nz-option
                  *ngFor="let member of members"
                  [nzValue]="member"
                  class="uk-text-uppercase"
                  [nzLabel]="
                    member.name +
                    ' ' +
                    member.lastName +
                    ' | EDAD: ' +
                    member.age
                  "
                >
                </nz-option>
              </nz-select>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-form-row margin">
        <div class="uk-grid">
          <div class="uk-width-large-1-2 mb-1">
            <label class="uk-form-label">Escoja el tipo de Académia</label>
            <div class="uk-form-controls">
              <nz-select
                [(ngModel)]="selectedAcademyType"
                name="selectedAcademyType"
                class="uk-width-1-1"
                [disabled]="academiesTypes.length < 1"
                (ngModelChange)="getAvailableAcademies()"
                nzPlaceHolder="Seleccione un tipo de academia"
              >
                <nz-option
                  [nzValue]="academyType"
                  *ngFor="let academyType of academiesTypes"
                  [nzLabel]="academyType.name"
                >
                </nz-option>
              </nz-select>
            </div>
          </div>
          <div class="uk-width-large-1-2">
            <label class="uk-form-label">Academia</label>
            <div class="uk-form-controls">
              <nz-select
                [(ngModel)]="selectedAcademy"
                name="selectedAcademy"
                class="uk-width-1-1"
                [disabled]="academies.length < 1"
                (ngModelChange)="getAvailableGroups()"
                nzPlaceHolder="Seleccione una academia"
              >
                <nz-option
                  *ngFor="let academy of academies"
                  [nzValue]="academy"
                  [nzLabel]="academy.name"
                >
                </nz-option>
              </nz-select>
            </div>
          </div>
        </div>
      </div>

      <div
        class="uk-form-row margin"
        *ngIf="selectedAcademy !== undefined && selectedAcademy.note"
      >
        <div class="uk-grid">
          <div class="uk-width-large-1-1 mb-1">
            <label class="uk-form-label">Información Extra</label>
            <div class="uk-form-controls tw-p-4 tw-bg-gray-200 tw-rounded-md">
              <div
                [innerHTML]="selectedAcademy.note"
                style="display: inline-block"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-form-row margin">
        <div class="uk-grid">
          <div class="uk-width-large-1-2 mb-1">
            <label class="uk-form-label"
              >Grupos de la Academia Seleccionada</label
            >
            <div class="uk-form-controls">
              <nz-select
                [(ngModel)]="selectedGroup"
                name="selectedGroup"
                class="uk-width-1-1"
                [disabled]="groups.length < 1"
                (ngModelChange)="getAvailableSchedules()"
                nzPlaceHolder="Seleccione un Grupo"
              >
                <nz-option
                  *ngFor="let group of groups"
                  [nzValue]="group"
                  [nzLabel]="group.description"
                >
                </nz-option>
              </nz-select>
            </div>
          </div>
          <div class="uk-width-large-1-2">
            <label class="uk-form-label">Días y Horarios del Grupo</label>
            <div class="uk-form-controls">
              <nz-select
                [(ngModel)]="selectedSchedule"
                name="selectedSchedule"
                class="uk-width-1-1"
                (ngModelChange)="getAvailableSchedulesQuota()"
                [disabled]="schedules.length < 1"
                nzPlaceHolder="Seleccione un Horario"
              >
                <nz-option
                  *ngFor="let schedule of schedules"
                  [nzValue]="schedule"
                  [nzLabel]="schedule.day + ' ' + schedule.time"
                >
                </nz-option>
              </nz-select>
            </div>
          </div>
        </div>
        <div
          class="uk-grid uk-grid-width-large-1-4"
          *ngIf="selectedGroup && selectedGroup.eKey"
        >
          <div class="mb-1">
            <label class="uk-form-label">Requiere Código</label>
            <form
              (submit)="validateGroupCode()"
              *ngIf="!selectedGroup.validEKey"
            >
              <input
                type="text"
                placeholder="Codigo de Acceso"
                [(ngModel)]="verificationGroupCode"
                name="verificationGroupCode"
                class="uk-width-1-1"
                style="margin-bottom: 4px"
                autocomplete="off"
              />
              <button
                type="sunmit"
                class="uk-button uk-button-small uk-width-1-1"
                [disabled]="verificationGroupCodeLoading"
              >
                <span *ngIf="verificationGroupCodeLoading">Validando...</span>
                <span *ngIf="!verificationGroupCodeLoading">Validar</span>
              </button>
            </form>
            <div
              id="verificationGroupCodeMsg"
              class="uk-text-center animated"
              style="padding: 5px 0"
            >
              <span
                *ngIf="selectedGroup.validEKey == false"
                class="uk-text-bold red"
                >Código Inválido</span
              >
              <span
                *ngIf="selectedGroup.validEKey"
                class="uk-text-bold green fadeIn animated"
                >¡Código Aceptado!</span
              >
            </div>
            <!-- validation msg -->
          </div>
        </div>
        <nz-descriptions
          nzBordered
          class="tw-mb-4 tw-mt-4"
          *ngIf="selectedGroup"
          nzSize="small"
          nzLayout="vertical"
        >
          <nz-descriptions-item nzTitle="Requisito de edad">{{
            selectedGroup.reSex
          }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Requisito de Sexo">
            Estricto de {{ selectedGroup.reAge[0] }} a
            {{ selectedGroup.reAge[1] }} años.
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Cupos">
            <div
              class="tw-text-red-500"
              *ngIf="selectedSchedule && quotaSchedule.availableQuota < 1"
            >
              <i class="uk-icon uk-icon-frown-o uk-text-large"></i><br />
              Sin Cupos disponibles
            </div>
            <div *ngIf="selectedSchedule && quotaSchedule.availableQuota > 0">
              <span class="tw-text-2xl">{{
                quotaSchedule.availableQuota
              }}</span>
              cupos restantes
            </div>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Costo">
            <div
              class="tw-bg-green-500 tw-px-2 tw-py-1 tw-rounded-md tw-text-white tw-inline-block"
              *ngIf="selectedGroup.price < 1"
            >
              GRATIS
            </div>
            <div *ngIf="selectedGroup.price && selectedGroup.price > 1">
              &nbsp;<span class="tw-text-2xl"
                >S./ {{ selectedGroup.price | number: '1.2-2' }}</span
              >
            </div>
          </nz-descriptions-item>
        </nz-descriptions>
        <div class="uk-grid">
          <div class="uk-width-large-1-1 uk-text-right">
            <button
              nz-button
              nzType="primary"
              (click)="addInscriptionRecord()"
              [disabled]="isDisabledAddAcademy"
            >
              &nbsp; Agregar a la tabla de inscripción
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</app-flat-card>

<app-flat-card class="tw-block tw-mb-1">
  <app-inscription-step-title
    step="2"
    title="Verifica la información seleccionada"
  >
  </app-inscription-step-title>
  <div>
    <div *ngIf="currentInscription.getCountRecords() > 0">
      <br />
      <p>
        Si existen errores con los datos ingresados, podrá hacer click el ícono
        ( <i class="uk-icon uk-icon-warning pointer red"></i> ) para que se
        muestren más detalles, para guardar la inscripción todo los datos deben
        ser correctos. ( <i class="uk-icon uk-icon-check-circle green"></i> )
      </p>
      <div class="uk-overflow-container">
        <table
          class="uk-table uk-table-hover uk-table-striped uk-table-condensed club-socio-table"
        >
          <thead>
            <tr>
              <th style="width: 40px"></th>
              <th style="width: 35px"></th>
              <th>Persona</th>
              <th>Descripción</th>
              <th style="width: 80px">Monto (S/)</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th
                colspan="4"
                class="uk-text-right uk-text-large uk-text-normal"
              >
                Total a Pagar
              </th>
              <th class="uk-text-right uk-text-large uk-text-normal">
                {{ getTotalInscription() | number: '1.2-2' }}
              </th>
            </tr>
          </tfoot>
          <tbody>
            <tr
              *ngFor="let inscriptionRecord of currentInscription.getRecords()"
              [ngClass]="{
                'club-socio-error-row': inscriptionRecord.state == 0
              }"
            >
              <td class="uk-text-center">
                <a
                  class="club-icon-button red"
                  (click)="removeInscriptionRecord(inscriptionRecord)"
                >
                  <i class="uk-icon uk-icon-trash"></i>
                </a>
              </td>
              <td>
                <span
                  *ngIf="inscriptionRecord.state === 3"
                  class="club-icon-button"
                  title="Pendiente de verificación."
                >
                  <i class="uk-icon uk-icon-clock-o"></i>
                </span>
                <span
                  *ngIf="inscriptionRecord.state === 0"
                  class="red club-icon-button"
                  title="Existen errores."
                >
                  <i class="uk-icon uk-icon-warning pointer"></i>
                </span>
                <span
                  *ngIf="inscriptionRecord.state === 1"
                  class="green club-icon-button"
                  title="Registro existente."
                >
                  <i class="uk-icon uk-icon-check-circle"></i>
                </span>
              </td>
              <td>
                {{ inscriptionRecord.getMember().name }}
                {{ inscriptionRecord.getMember().lastName }}
              </td>
              <td>{{ inscriptionRecord.description }}</td>
              <td class="uk-text-right">
                {{ inscriptionRecord.getPrice() | number: '1.2-2' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <app-message
      [message]="'Su tabla de Inscripciones se encuentra vacía'"
      [subMessage]="'Agregue elementos a la tabla'"
      *ngIf="currentInscription.getCountRecords() < 1"
    >
    </app-message>
  </div>
</app-flat-card>

<app-flat-card class="tw-block tw-mb-1">
  <app-inscription-step-title
    step="3"
    title="Ingresa un número de contacto y guarda tu inscripción"
  >
  </app-inscription-step-title>

  <form nz-form [nzLayout]="'vertical'" class="tw-mb-4">
    <nz-form-item class="tw-block tw-w-full md:tw-w-1/2">
      <nz-form-label nzFor="contact">Número de contacto</nz-form-label>
      <nz-form-control
        [nzValidateStatus]="contactNumberValidationStatus"
        nzErrorTip="Debes ingresar un número de contacto."
      >
        <input
          nz-input
          name="contact"
          type="text"
          id="contact"
          [(ngModel)]="contactNumber"
          (ngModelChange)="contactNumberValidationStatus = ''"
          numbersOnly
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="tw-block tw-w-full">
      <label nz-checkbox [(ngModel)]="monthlyInsurance" name="monthlyInsurance">
        OPCIONAL: Autorizo el cobro de S/ 1.00, que corresponde al derecho a un
        seguro mensual al inscrito por el mes de vigencia de la academia
      </label>
    </nz-form-item>
  </form>

  <button
    nz-button
    nzType="primary"
    nzSize="large"
    (click)="saveInscription()"
    [disabled]="currentInscription.getCountRecords() < 1"
  >
    Guardar mis inscripciones
  </button>
</app-flat-card>
