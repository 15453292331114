import { Component, OnInit } from '@angular/core';
import { VisaService } from '@app/services/payment/visa.service';
import { PaymentService } from '@app/data-services/payment.service';
import { AuthService } from '@app/services/auth/auth.service';
import { AppSettings } from '@app/helpers/app.settings';
import { environment } from '@env/environment';
import { RoNotificationService } from '@app/modules/shared/services/ro-notification.service';
import { isNil } from 'ramda';
import { NgRoDebugger } from '@modules/shared/classes/NgRoDebugger';
import { combineLatest, EMPTY } from 'rxjs';
import { DebtService } from '@app/services/payment/debt.service';
import { catchError, switchMap, take, takeUntil } from 'rxjs/operators';
import { OnDestroyService } from '@modules/shared/services/on-destroy.service';
import { Debt } from '@app/classes/payment/debt';
import { AnalyticsService } from '@app/data-services/analytics.service';
import { MemberService } from '@app/data-services/member.service';

declare var window: any;

const VISA_MERCHANT_ID = environment.visa.merchantId;
const VISA_FORM_SRC = environment.visa.paymentButtonLib;
const INTERSOCIO_TIMEOUT_URL = AppSettings.API_ENDPOINT_VISA.timeoutUrl;
const VISA_FORM_ACTION = AppSettings.API_ENDPOINT_VISA.redirectionUrl;
const INTERSOCIO_CONFIRN_URL = AppSettings.API_ENDPOINT_VISA.confirmUrl;
const INTERSOCIO_LOGO_URL = `${environment.baseUrlApp}assets/img/public/logo_for_visa.png`;

@Component({
  selector: 'app-payment-visa',
  providers: [VisaService, AuthService, DebtService],
  templateUrl: './payment-visa.component.html',
})
export class PaymentVISAComponent extends NgRoDebugger implements OnInit {
  totalPayment: string;
  paymentList: Debt[] = [];
  appUser: AppUser;

  constructor(
    private _visaService: VisaService,
    private _roNotificationService: RoNotificationService,
    private paymentService: PaymentService,
    private onDestroy$: OnDestroyService,
    private analyticsService: AnalyticsService,
    private memberService: MemberService
  ) {
    super('PaymentVISAComponent');
  }

  loadingVisa = false;

  static _generateUrlActionVisa(sessionToken: any, transactionId: any) {
    const redirectionUrl = encodeURI(INTERSOCIO_CONFIRN_URL);
    return `${VISA_FORM_ACTION}?redirect_to=${redirectionUrl}&session_token=${sessionToken}&transaction_id=${transactionId}`;
  }

  ngOnInit() {
    delete window.VisanetCheckout;

    combineLatest([
      this.paymentService.paymentList$,
      this.paymentService.totalWithCommission$,
    ])
      .pipe(takeUntil(this.onDestroy$.onDestroy$), take(1))
      .subscribe(([payDebts, total]) => {
        this.totalPayment = total.toFixed(2);
        this.paymentList = payDebts;
        this.getTransactionSessionToken();
      });
  }

  private getTransactionSessionToken() {
    this.loadingVisa = true;
    this.logger('Getting transaction ID');

    this.memberService.appUser$
      .pipe(
        switchMap((appUser) => {
          this.appUser = appUser;
          return this._visaService.getSessionToken({
            amount: this.totalPayment,
            debt_ids: this.paymentList,
          });
        }),
        catchError(() => {
          this._roNotificationService.danger(
            'No se pudo obtener una respuesta de parte de VISA'
          );
          this.loadingVisa = false;
          this.analyticsService.analytics.track('Error', {
            user: this.appUser.username,
            version: AppSettings.APP_INFO.version,
            message: 'Error trying to getTransactionSessionToken',
          });

          return EMPTY;
        })
      )
      .subscribe((visaSession: any) => {
        if (visaSession.sessionKey) {
          this.logger('Success session key');
          this.logger('Creating payment form');
          this._createVisaForm(
            visaSession.sessionKey,
            visaSession.transaction_id,
            visaSession.visa_user_token_id
          );
        } else {
          this.logger('Error getting the session key');
          this._roNotificationService.danger(
            'Hubo un error estableciendo la conexión con VISA.'
          );
        }
        this.loadingVisa = false;
      });
  }

  private _createVisaForm(sessionToken, transactionId, userToken) {
    const script = document.createElement('script');
    script.setAttribute('src', VISA_FORM_SRC);
    script.setAttribute('data-sessiontoken', sessionToken);
    script.setAttribute('data-channel', 'web');
    script.setAttribute('data-merchantid', VISA_MERCHANT_ID);
    script.setAttribute('data-buttonsize', 'LARGE');
    script.setAttribute('data-buttoncolor', 'NAVY');
    script.setAttribute('data-merchantlogo', INTERSOCIO_LOGO_URL);
    script.setAttribute('data-merchantname', 'Club Internacional Arequipa');
    script.setAttribute('data-formbuttoncolor', '#0A0A2A');
    script.setAttribute('data-showamount', 'TRUE');
    script.setAttribute('data-purchasenumber', transactionId);
    script.setAttribute('data-amount', String(this.totalPayment));
    script.setAttribute('dataexpirationminutes', '5');
    script.setAttribute('data-timeouturl', INTERSOCIO_TIMEOUT_URL);

    const dataUserToken = !isNil(userToken) ? userToken : '';
    script.setAttribute('data-usertoken', dataUserToken);
    script.setAttribute('data-beneficiaryid', this.appUser.username);

    const form = document.getElementById('visa-form');
    form.setAttribute(
      'action',
      PaymentVISAComponent._generateUrlActionVisa(sessionToken, transactionId)
    );
    form.appendChild(script);
  }
}
