import { Session } from '@app/classes/user/session';
import * as i0 from "@angular/core";
export const AUTHORIZATION_KEY = 'IS:AUTHORIZATION';
export const LOG_OUT_EVENT = 'event:on:logout';
export class SessionService {
    constructor() {
        this.authorization = null;
        const auth = sessionStorage.getItem(AUTHORIZATION_KEY);
        if (auth) {
            this.authorization = auth;
        }
    }
    create(session) {
        Session.getInstance().createSession(session);
        sessionStorage.setItem(AUTHORIZATION_KEY, session.authorization);
    }
    clear() {
        this.authorization = null;
        sessionStorage.removeItem(AUTHORIZATION_KEY);
        Session.getInstance().clearSession();
        window.dispatchEvent(new CustomEvent(LOG_OUT_EVENT));
    }
}
SessionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionService_Factory() { return new SessionService(); }, token: SessionService, providedIn: "root" });
