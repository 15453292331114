import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import Cookies from 'js-cookie';
import { isNil } from 'ramda';
import { PaymentConstants } from '@app/modules/payment/payment-constants';
import { roLogger } from '@app/helpers/roLogger';

@Injectable({
  providedIn: 'root',
})
export class VisaResponseGuard implements CanActivate {
  private logger = roLogger({ context: 'VisaResponseGuard' });

  static hasRequiredCookies() {
    const { TRANSACTION_ID, TRANSACTION_TOKEN } = PaymentConstants.COOKIE_NAMES;
    return (
      !isNil(Cookies.get(TRANSACTION_ID)) &&
      !isNil(Cookies.get(TRANSACTION_TOKEN))
    );
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.logger('hasRequiredCookies' + VisaResponseGuard.hasRequiredCookies());
    return VisaResponseGuard.hasRequiredCookies();
  }
}
